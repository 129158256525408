import SearchIcon from '../icons/SearchIcon/SearchIcon';
import { Container, Input } from './SearchInput.style';

interface SearchInputProps {
  placeHolder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput = (props: SearchInputProps) => {
  const { placeHolder = 'pesquisar', onChange } = props;
  return (
    <Container>
      <Input placeholder={placeHolder} type="text" onChange={onChange} />
      <SearchIcon />
    </Container>
  );
};

export default SearchInput;
