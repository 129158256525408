import styled from 'styled-components';
import theme from '../../theme';

interface ActualTooltipProps {
  fontSize: string;
}

export const HoverPlace = styled.div`
  cursor: pointer;
`;

export const ActualTooltip = styled.div<ActualTooltipProps>`
  color: transparent;
  position: absolute;
  top: 70%;
  left: -40px;
  width: fit-content;
  padding: 5px 5px;
  border-radius: 4px;
  visibility: hidden;
  margin-top: 16px;
  background-color: #fafbfc;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 0px 40px rgba(140, 152, 164, 0.176);
  z-index: 20;
  /* &:before {
        content: "";
        width: 0;
        height: 0;
        left: 40px;
        top: -10px;
        position: absolute;
        border: 10px solid transparent;
        border-top: black;
        transform: rotate(135deg);
    } */
  &p {
    color: ${theme.colors.darkBlue};
    font-size: ${(props) => props.fontSize};
  }
`;

export const Container = styled.div`
  position: relative;
  & ${HoverPlace}:hover + ${ActualTooltip} {
    visibility: visible;
    color: ${theme.colors.black};
    background-color: #fafbfc;
    border: 1px solid #e4e4e4;
    box-shadow: 0px 0px 40px rgba(140, 152, 164, 0.176);
    padding: 8px 8px;
    border-radius: 4px;
    margin-top: 16px;
    &:before {
      border-color: transparent transparent #fafbfc #fafbfc;
    }
  }
`;
