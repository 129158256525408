import React from 'react';
import { useTransition } from 'react-spring';
import { ToastMessage } from '../../hooks/useToast';
import { Container } from './Toast.style';
import Message from './ToastMessage/ToastMessage';

interface ToastProps {
  messages: ToastMessage[];
}

const Toast: React.FC<ToastProps> = ({ messages }) => {
  const messageWithTransitions = useTransition(messages, {
    from: { right: '-120%', opacity: 0 },
    enter: { right: '0%', opacity: 1 },
    leave: { right: '-120%', opacity: 0 },
  });

  return (
    <Container>
      {messageWithTransitions((style, message) => (
        <Message key={message.id} message={message} />
      ))}
    </Container>
  );
};

export default Toast;
