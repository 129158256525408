import localforage from 'localforage';
import jwtDecode from 'jwt-decode';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import {
  useLoginWithCredentialsMutation,
  useRequestPasswordRecoveryMutation,
  useResetPasswordMutation,
} from '../../generated/graphqlV2';
import apolloClient from '../../graphql/apollo-client';
import {
  ACCESS_TOKEN_KEY,
  AUTH_TYPE,
  CURRENT_USER_KEY,
  REFRESH_TOKEN_KEY,
  STORAGE_KEY,
} from '../constants';
import { useToast } from '../../hooks/useToast';

export enum AuthType {
  Credentials = 'credentials',
  Token = 'token',
}

interface jwtDecoded {
  exp: number;
  iat: number;
}

export const store = localforage.createInstance({
  name: STORAGE_KEY,
});

export const session = {
  persitAuthorization: async function (
    access_token: string,
    refresh_token: string,
  ) {
    return await Promise.all([
      store.setItem(ACCESS_TOKEN_KEY, access_token),
      store.setItem(REFRESH_TOKEN_KEY, refresh_token),
    ]);
  },

  cleanPersistedData: async function () {
    await Promise.all([
      store.removeItem(ACCESS_TOKEN_KEY),
      store.removeItem(REFRESH_TOKEN_KEY),
      store.removeItem(CURRENT_USER_KEY),
      store.removeItem(AUTH_TYPE),
    ]);
  },

  getAccessToken: async function (): Promise<string | null> {
    return await store.getItem(ACCESS_TOKEN_KEY);
  },

  setAuthType: async function (authType: AuthType) {
    return await Promise.all([store.setItem(AUTH_TYPE, authType)]);
  },

  getAuthType: async function (): Promise<string | null> {
    return await store.getItem(AUTH_TYPE);
  },

  validateToken: async function (token: string): Promise<boolean> {
    const expirationDate: jwtDecoded = jwtDecode(token);

    const now = new Date().getTime();

    return now < expirationDate.exp * 1000;
  },
};

interface AuthContextProviderProps {
  children: React.ReactNode;
}

interface AuthContextValue {
  isAuthenticated: boolean;
  logout: () => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  loading: boolean;
  loginType: string | null;
  accessToken: string | null;
  resetPassword: (password: string, token: string) => Promise<void>;
  resetLoading: boolean;
  recoveryPassword: (username: string) => Promise<void>;
  recoveryPasswordLoading: boolean;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const AuthContext = createContext({} as AuthContextValue);

function AuthContextProvider(props: AuthContextProviderProps) {
  const { addToast } = useToast();
  const { children } = props;
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !(accessToken == null),
  );
  const [loginType, setLoginType] = useState<string | null>(null);

  const navigate = useNavigate();

  const [loginMutation, { loading }] = useLoginWithCredentialsMutation();
  const [reset, { loading: resetLoading }] = useResetPasswordMutation();
  const [recovery, { loading: recoveryPasswordLoading }] =
    useRequestPasswordRecoveryMutation();

  const logout = useCallback(async () => {
    await session.cleanPersistedData();
    setIsAuthenticated(false);
    apolloClient.cache.reset();
    navigate('/', { replace: true });
  }, [navigate]);

  const login = useCallback(
    async (email: string, password: string) => {
      try {
        const result = await loginMutation({
          variables: {
            email,
            password,
          },
        });

        const accessToken = result.data?.login.accessToken;
        const refreshToken = result.data?.login.refreshToken;
        if (!accessToken || !refreshToken) {
          addToast({ title: 'Acesso negado', type: 'error' });

          return;
        }

        if (!isAuthenticated && accessToken && refreshToken) {
          await session.persitAuthorization(accessToken, refreshToken);
          await session.setAuthType(AuthType.Credentials);
          addToast({
            title: 'Login efetuado com sucesso, seja bem vindo(a).',
            type: 'success',
          });

          setAccessToken(accessToken);
          setIsAuthenticated(true);
          setLoginType(AuthType.Credentials);
          navigate('/');
        }
      } catch (err) {
        addToast({
          title: 'Erro ao realizar o login.',
          type: 'error',
          description: 'Confira as credendiais e tente novamente',
        });

        console.error('error', err);
      }
    },
    [isAuthenticated, loginMutation, navigate],
  );

  const resetPassword = useCallback(
    async (password: string, token: string) => {
      await reset({
        variables: {
          password,
          token,
        },
      });
    },
    [reset],
  );

  const recoveryPassword = useCallback(
    async (username: string) => {
      try {
        await recovery({
          variables: {
            username,
          },
        });
        addToast({
          title: 'E-mail enviado com sucesso.',
          type: 'success',
          description: 'Confira o passo a passo para redifinição de senha.',
        });

        navigate('/');
      } catch (error) {
        addToast({
          title: 'Erro ao realizar a recuperação de senha.',
          type: 'error',
          description: 'Confira o usuário e tente novamente.',
        });

        console.error('RECOVERY PASSWORD ERROR', error);
      }
    },
    [navigate, recovery],
  );

  const validateDefaultLogin = useCallback(async () => {
    const token = await session.getAccessToken();

    if (token) {
      const isValid = await session.validateToken(token);

      if (!isValid) {
        addToast({
          title: 'Realize o login para continuar.',
          type: 'info',
        });

        void logout();
      }

      setIsAuthenticated(true);
      const authType = await session.getAuthType();

      setLoginType(authType);
      setAccessToken(token);

      const url = new URL(window.location.href);
      navigate(url || '/');
    }
  }, [logout, navigate]);

  const validateOpsLogin = useCallback(
    async (accessToken: string, refreshToken: string, redirectUrl?: string) => {
      const isValid = await session.validateToken(accessToken);

      if (!isValid) {
        addToast({
          title: 'Realize o login para continuar.',
          type: 'info',
        });

        void logout();

        return;
      }

      void session.setAuthType(AuthType.Token).then(() => {
        setAccessToken(accessToken);
        setLoginType(AuthType.Token);
      });

      void session.persitAuthorization(accessToken, refreshToken).then(() => {
        setIsAuthenticated(true);
        navigate(redirectUrl || '/');
      });

      addToast({
        title: 'Login efetuado com sucesso, seja bem vindo(a).',
        type: 'success',
      });
    },
    [logout, navigate],
  );

  useEffectOnce(() => {
    const url = new URL(window.location.href);

    const refreshToken = url.searchParams.get('refresh_token');
    const accessToken = url.searchParams.get('access_token');
    const redirectUrl = url.searchParams.get('continue');

    if (refreshToken && accessToken) {
      void validateOpsLogin(accessToken, refreshToken, redirectUrl ?? '');

      return;
    }

    void validateDefaultLogin();
  });

  const values = {
    isAuthenticated,
    logout,
    login,
    loading,
    loginType,
    accessToken,
    resetPassword,
    resetLoading,
    recoveryPassword,
    recoveryPasswordLoading,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}

export default AuthContextProvider;
