import { ReactNode, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { Container, Row, RowDiv } from '../Selector/Selector.style';

interface AsyncSelectorProps {
  icon: ReactNode;
  position: 'right' | 'left';
  width?: string;
  rowsArray: Array<{
    text: string;
    icon?: ReactNode;
    onClick?: () => Promise<void>;
  }>;
}

const AsyncSelector = (props: AsyncSelectorProps) => {
  const { position, width, rowsArray, icon } = props;
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  useClickAway(ref, () => {
    setTimeout(() => setOpen(false), 100);
  });

  return (
    <div style={{ position: 'relative', cursor: 'pointer' }}>
      <div onClick={() => setOpen(!open)}>{icon}</div>
      {open && (
        <Container
          className={position === 'right' ? 'right' : 'left'}
          width={width}
          ref={ref}
        >
          {rowsArray.map((item) => (
            <RowDiv onClick={() => setOpen(false)} key={item.text}>
              <Row onClick={item.onClick}>{item.text}</Row>
              {item.icon}
            </RowDiv>
          ))}
        </Container>
      )}
    </div>
  );
};

export default AsyncSelector;
