import { Container } from './MainLayout.styles';
import Page from './Page/Page';
import Sidebar from './Sidebar/Sidebar';

const MainLayout = () => {
  return (
    <Container data-testid="MainLayout">
      <Sidebar />
      <Page />
    </Container>
  );
};

export default MainLayout;
