import { useState } from 'react';
import { FuelTypeEnum } from '../../generated/graphqlV2';
import SelectSearch, { SelectDataType } from '../SelectSearch/SelectSearch';

interface SelectFuelTypeProps {
  onChange: (value: SelectDataType) => void;
  name?: string;
  loading?: boolean;
  value?: SelectDataType | null;
}

const fuelTypes = [
  {
    id: FuelTypeEnum.Arla_32,
    text: 'Arla 32',
  },
  {
    id: FuelTypeEnum.Diesel,
    text: 'Diesel',
  },
  {
    id: FuelTypeEnum.Ethanol,
    text: 'Etanol',
  },
  {
    id: FuelTypeEnum.Gasoline,
    text: 'Gasolina',
  },
  {
    id: FuelTypeEnum.Gnv,
    text: 'GNV',
  },
  {
    id: FuelTypeEnum.Kerosene,
    text: 'Querosene',
  },
  {
    id: FuelTypeEnum.Other,
    text: 'Outro',
  },
];

const SelectFuelType = (props: SelectFuelTypeProps) => {
  const { onChange, loading, name, value } = props;

  const [filteredFuelOptions, setFilteredFuelOptions] = useState(fuelTypes);

  function searchFuelOptions(search: string) {
    const filteredData = fuelTypes.filter((item) => {
      return item.text.toLowerCase().search(search.toLowerCase()) !== -1;
    });
    setFilteredFuelOptions(filteredData);
  }

  return (
    <SelectSearch
      data={filteredFuelOptions}
      onChange={onChange}
      name={name}
      onSearch={searchFuelOptions}
      loading={loading}
      label="Tipo de combustível"
      value={value}
    />
  );
};

export default SelectFuelType;
