/* eslint-disable react/prop-types */
import { useOrgsManagementDrawerContext } from '../../contexts/OrgsManagementDrawerContext';
import {
  Form,
  Footer,
  Inputs,
  PurpleButton,
  Row,
} from './OrgsManagementDrawerContent.style';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import { SelectDataType } from '../../../../components/MultipleSelectSearch/MultipleSelectSearch';
import { useAppsQuery } from '../../../../generated/graphqlV2';
import { useOrganizationCRUDContext } from '../../contexts/OrganizationCRUDContext';
import { useCurrentUserContext } from '../../../../contexts/CurrentUserContextProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '../../../../hooks/useToast';
import { cnpjMask } from '../../../../helpers/cnpj-mask';

interface FormValues {
  name: string;
  phone: string;
  address: string;
  cnpj: string;
  users: SelectDataType[];
}

const schema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
});

const appsEnabled = [
  'Monitoramento & Telemetria',
  'Manutenção',
  'Abastecimento',
];

const OrgsManagementDrawerContentOnAdd = () => {
  const { setIsOpen } = useOrgsManagementDrawerContext();
  const { createNewOrganization, createOrganizationIsLoading } =
    useOrganizationCRUDContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const { data: { apps = [] } = {} } = useAppsQuery();
  const { user } = useCurrentUserContext();
  const { addToast } = useToast();

  const onSubmit = async (data: FormValues) => {
    try {
      const organizationPayload = {
        cnpj: data.cnpj?.replace(/[^0-9]/g, ''),
        name: data.name,
        address: data.address,
        phone: data.phone,
        apps: apps
          .filter(({ name }) => appsEnabled.includes(name))
          .map(({ id }) => id),
        primaryOrganization: user?.organizations.find(
          (organization) => organization.primaryOrganization === null,
        )?.id,
      };
      await createNewOrganization(organizationPayload);
      addToast({ title: 'Unidade criada com sucesso!', type: 'success' });
    } catch (error) {
      addToast({ title: 'Erro na criação da unidade.', type: 'error' });
      console.log('Erro: ', error);
    } finally {
      setIsOpen(false);
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Inputs>
        <Row>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                label={'Nome da unidade'}
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                type="text"
                width="550px"
                disabled={createOrganizationIsLoading}
                error={errors.name?.message && 'O nome é obrigatório'}
                loading={createOrganizationIsLoading}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="cnpj"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                label="CNPJ"
                onChange={props.field.onChange}
                name={props.field.name}
                value={cnpjMask(props.field.value)}
                type="text"
                width="550px"
                disabled={createOrganizationIsLoading}
                loading={createOrganizationIsLoading}
                error={
                  errors.cnpj?.message &&
                  (props.field.value
                    ? 'Insira todos os 14 caracteres'
                    : 'O CNPJ é obrigatório')
                }
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="phone"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <Input
                label="Telefone"
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                type="text"
                width="550px"
                disabled={createOrganizationIsLoading}
                loading={createOrganizationIsLoading}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="address"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <Input
                label="Endereço"
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                type="text"
                width="550px"
                disabled={createOrganizationIsLoading}
                loading={createOrganizationIsLoading}
              />
            )}
          />
        </Row>
      </Inputs>
      <Footer>
        <PurpleButton disabled={createOrganizationIsLoading}>
          Adicionar unidade
        </PurpleButton>
      </Footer>
    </Form>
  );
};

export default OrgsManagementDrawerContentOnAdd;
