import styled from 'styled-components';
import AddButton from '../../../../components/AddButton/AddButton';
import { UserFragment } from '../../../../generated/graphqlV2';
import { useToast } from '../../../../hooks/useToast';
import theme from '../../../../theme';
import { useUsersCRUDContext } from '../../contexts/UsersCRUDContext';

interface DeleteModalContentProps {
  user: UserFragment;
  closeModal: () => void;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  width: 100%;
`;

const BlankSpace = styled.div`
  width: 200px;
`;

const DeleteModalContent = (props: DeleteModalContentProps) => {
  const { user, closeModal } = props;
  const { deleteUser } = useUsersCRUDContext();
  const { addToast } = useToast();
  const handleDelete = async () => {
    try {
      await deleteUser(user);
      addToast({ title: 'Usuário deletado com sucesso', type: 'success' });
    } catch (error) {
      console.log(error);
      addToast({ title: 'Erro ao deletar o usuário', type: 'error' });
    } finally {
      closeModal();
    }
  };

  return (
    <Container>
      <BlankSpace></BlankSpace>
      <AddButton
        onClick={closeModal}
        backgroundColor="transparent"
        textColor={theme.colors.darkGray}
        width="fit-content"
        borderColor={theme.colors.lightGray}
        hoverEffect={{
          hoverBackgroundColor: theme.colors.lightGray,
          hoverTextColor: 'white',
        }}
      >
        Cancelar
      </AddButton>
      <AddButton
        backgroundColor="transparent"
        textColor={theme.colors.red}
        onClick={handleDelete}
        width="fit-content"
        borderColor={theme.colors.red}
        hoverEffect={{
          hoverBackgroundColor: theme.colors.red,
          hoverTextColor: 'white',
        }}
      >
        Sim
      </AddButton>
    </Container>
  );
};

export default DeleteModalContent;
