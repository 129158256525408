import { createContext, useContext, useMemo, useState } from 'react';
import Modal from '../components/Modal/Modal';

interface ModalProps {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  modalTitle: string;
  setModalTitle: (openModal: string) => void;
  modalDescription: string[];
  setModalDescription: (openModal: string[]) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  content: JSX.Element;
  setContent: (content: JSX.Element) => void;
}

interface ModalContextProps {
  children: React.ReactNode;
}

const emptyObject = {};

const ModalContext = createContext<ModalProps>(emptyObject as ModalProps);

function ModalContextProvider(props: ModalContextProps) {
  const { children } = props;
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Título do modal');
  const [modalDescription, setModalDescription] = useState(['']);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(<></>);

  const value = useMemo(
    () => ({
      openModal,
      setOpenModal,
      modalTitle,
      setModalTitle,
      modalDescription,
      setModalDescription,
      loading,
      setLoading,
      setContent,
      content,
    }),
    [
      openModal,
      setOpenModal,
      modalTitle,
      setModalTitle,
      modalDescription,
      setModalDescription,
      loading,
      setLoading,
      setContent,
      content,
    ],
  );
  return (
    <ModalContext.Provider value={value}>
      {openModal && (
        <Modal
          title={modalTitle}
          description={modalDescription}
          loading={loading}
        >
          {content}
        </Modal>
      )}
      {children}
    </ModalContext.Provider>
  );
}

export function useModalContext() {
  const context = useContext(ModalContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useModalContext must be used within a ModalContextProvider',
    );
  }
  return context;
}

export default ModalContextProvider;
