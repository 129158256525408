import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../theme';
import Tooltip from '../Tooltip/Tooltip';

interface ManagerIconProps {
  number: number;
}

interface TooltipProps {
  number: number;
}

interface ContainerProps {
  usersQuantity: number | undefined;
}

export const Container = styled.div<ContainerProps>`
  border: 1px solid #d7dfe9;
  border-radius: 4px;
  width: 300px;
  height: 250px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: white;
  transition: all 0.2s ease;

  cursor: ${(props) => props.usersQuantity && 'pointer'};
  &:hover {
    transform: ${(props) => props.usersQuantity && 'scale(1.05)'};
    z-index: 3;
  }
`;
export const CardHeader = styled.div`
  display: grid;
  grid-template-areas:
    'logo name actions'
    'logo address actions';
  grid-template-columns: min-content auto min-content;
  margin-bottom: 20px;
  position: relative;
`;

export const OrganizationLogo = styled.div`
  border: 1px solid #d7dfe9;
  border-radius: 3px;
  width: 44px;
  height: 44px;
  grid-area: logo;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  .span {
    font-weight: 500;
    font-size: 14px;
    color: ${theme.colors.black};
  }
`;
export const OrganizationName = styled.span`
  grid-area: name;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.black};
`;
export const OrganizationAddress = styled.span`
  grid-area: address;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const ActionButton = styled.button`
  grid-area: actions;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const OrganizationInfo = styled.div`
  margin-bottom: 30px;
`;

export const ManagersDisplay = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
`;
export const ManagersText = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const VehiclesInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  column-gap: 9px;
`;

export const DriversInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  column-gap: 9px;
`;
export const OrganizationManagers = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const NoManagersText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.colors.textGray};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.darkPurple};
`;

export const StyledTooltip = styled(Tooltip)<TooltipProps>`
  z-index: ${(props) => 5 - props.number};
  background-color: red;
`;

export const ManagerIcon = styled.div<ManagerIconProps>`
  border-radius: 50%;
  border: 1px solid white;
  padding: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.number % 2 === 0
      ? theme.colors.lightYellow
      : theme.colors.lightPurple};
  z-index: ${(props) => 5 - props.number};
  color: ${(props) =>
    props.number % 2 === 0 ? theme.colors.darkYellow : theme.colors.darkPurple};
  transform: translateX(${(props) => props.number * -5}px);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    z-index: 6;
    transform: scale(1.1) translateX(${(props) => props.number * -5}px);
  }

  &.extra {
    background-color: ${theme.colors.darkBlue};
    color: ${theme.colors.white};
    z-index: 0;
  }
`;
export const SelectorSpan = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.colors.black};
  cursor: pointer;
  &.delete {
    color: ${theme.colors.red};
  }
`;
export const SelectorDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  cursor: pointer;
  &.divider {
    cursor: default;
    width: 100%;
    height: 0;
    border: 1px solid #f3f4f7;
  }
`;
