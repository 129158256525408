import styled from 'styled-components';

interface ContainerProps {
  backgroundColor: string;
  textColor: string;
  width?: string;
}

export const Container = styled.div<ContainerProps>`
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  padding: 4px 8px;
  border-radius: 6px;
  color: ${(props) => props.textColor};
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  margin-right: 6px;
  margin-top: 3px;
`;
