import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px 24px;
  height: calc(100vh - 106px);
`;

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid #f2f4f7;
  margin-bottom: 24px;
`;

export const SelectorWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 108px);
`;

export const Title = styled.span`
  font-size: 14px;
  margin-bottom: 8px;
  max-height: 50px;
  margin-bottom: 8px;
`;
