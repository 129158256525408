import styled from 'styled-components';
import theme from '../../theme';

interface ContainerProps {
  $size?: number;
}

interface CircularProgressBarProps {
  $size?: number;
  $progress: number;
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
`;

export const CircularProgressContainer = styled.div<CircularProgressBarProps>`
  position: relative;
  height: ${(props) => props.$size ?? 50}px;
  width: ${(props) => props.$size ?? 50}px;
  border-radius: 50%;
  display: grid;
  place-items: center;

  &::before {
    content: '';
    position: absolute;
    height: 90%;
    width: 90%;
    background-color: #fff;
    border-radius: 50%;
  }

  background: conic-gradient(
    ${theme.colors.darkBlue} ${(props) => props.$progress * 3.6}deg,
    rgba(14, 45, 102, 0.1) ${(props) => props.$progress * 3.6}deg
  );
`;

export const Progress = styled.div`
  position: relative;
  font-weight: 500;
  color: ${theme.colors.darkBlue};
  font-size: 80%;
  line-height: normal;
`;
