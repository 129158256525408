import Header from '../../components/Header/Header';
import { Container } from './OrgsManagementContent.style';
import AddButton from '../../components/AddButton/AddButton';
import {
  OrgsDrawerTypes,
  useOrgsManagementDrawerContext,
} from './contexts/OrgsManagementDrawerContext';
import OrganizationCards from './components/OrganizationsCards/OrganizationsCards';
import { useOrganizationDataContext } from './contexts/OrganizationDataContext';
import DrawwerHeader from '../../components/CustomDrawer/DrawerHeader/DrawerHeader';
import { useUsersCRUDContext } from '../users/contexts/UsersCRUDContext';
import CircularProgress from '../../components/CircularProgress/CircularProgress';
import { LoadingContainer } from '../../components/ManagersList/ManagersList.styles';

const OrgsManagementContent = () => {
  const {
    isOpen,
    setIsOpen,
    setdrawerHeader,
    setdrawerType,
    setSelectedOrganization,
  } = useOrgsManagementDrawerContext();
  const { loading } = useOrganizationDataContext();
  const { editUserIsLoading } = useUsersCRUDContext();

  const isLoading = loading || editUserIsLoading;
  const handleClick = () => {
    setIsOpen(!isOpen);
    setdrawerHeader(<DrawwerHeader title="Nova unidade" />);
    setdrawerType(OrgsDrawerTypes.Add);
    setSelectedOrganization(undefined);
  };
  return (
    <Container data-testid="OrgsManagement">
      <Header pageTitle="Matriz e Filiais">
        <AddButton onClick={handleClick}>Adicionar Novo</AddButton>
      </Header>
      {isLoading ? (
        <LoadingContainer>
          <CircularProgress infinite={true} size={100} />
        </LoadingContainer>
      ) : (
        <OrganizationCards />
      )}
    </Container>
  );
};

export default OrgsManagementContent;
