import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import Icon from '../Icon/Icon';
import AlertCircleIcon from '../icons/AlertCircleIcon/AlertCircleIcon';

const Container = styled.div`
  color: ${theme.colors.red};
  margin-top: 2px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;

  ${Icon} {
    margin-right: 6px;
  }
`;

interface ErrorMessageProps {
  message?: string;
}

function ErrorMessage(props: ErrorMessageProps) {
  const { message } = props;

  return (
    <Container role="alert">
      <Icon>
        <AlertCircleIcon />
      </Icon>
      {message}
    </Container>
  );
}

export default ErrorMessage;
