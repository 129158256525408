import styled from 'styled-components';
import theme from '../../theme';

interface ContainerProps {
  zIndex?: number;
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.zIndex || 10};
  background-color: ${theme.colors.darkBlueTransparent};
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  &.active {
    visibility: visible;
    opacity: 1;
  }
`;

export const Drawer = styled.div`
  height: 100vh;
  width: 600px;
  background-color: ${theme.colors.white};
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  z-index: 11;
  border: 1px solid #d7dfe9;
  transition: all 0.5s ease;
  &.active {
    transform: translateX(0);
  }
`;

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 30px;
  justify-content: flex-start;
  margin-bottom: 30px;
  margin-left: 25px;
  margin-top: 32px;
  .h2 {
    font-size: 18px;
    font-weight: 600;
    color: ${theme.colors.black};
  }
`;
