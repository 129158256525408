import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
`;

export const Icon = styled.div`
  width: 44px;
  height: 44px;
  color: #1d2939;
  background-color: #f2f4f7;
  border-radius: 3px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface TitleProps {
  hasIcon: boolean;
}

export const Title = styled.span<TitleProps>`
  font-size: ${(props) => (props.hasIcon ? '16' : '18')}px;
  font-weight: bold;
  color: #1d2939;
`;
