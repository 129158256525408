import { createContext, useContext, useMemo } from 'react';
import {
  CreateUserInput,
  UpdateUserInput,
  useCreateUserMutation,
  UserFragmentDoc,
  useUpdateUserMutation,
  useDeleteUserMutation,
  UserFragment,
} from '../../../generated/graphqlV2';

interface UsersCRUD {
  createNewUser: (payload: CreateUserInput) => Promise<void>;
  editUser: (payload: UpdateUserInput) => Promise<void>;
  editUserIsLoading: boolean;
  createUserIsLoading: boolean;
  deleteUserIsLoading: boolean;
  deleteUser: (userId: UserFragment) => Promise<void>;
}

interface UsersCRUDContextProps {
  children: React.ReactNode;
}

const emptyObject = {};

const UsersCRUDContext = createContext<UsersCRUD>(emptyObject as UsersCRUD);

function UsersCRUDContextProvider(props: UsersCRUDContextProps) {
  const { children } = props;
  const [deleteUserMutation, deleteUserResult] = useDeleteUserMutation();
  const [createUser, createUserResult] = useCreateUserMutation({
    update(cache, { data }) {
      cache.modify({
        fields: {
          users(prev = []) {
            const newTodoRef = cache.writeFragment({
              data: data?.createUser,
              fragment: UserFragmentDoc,
              fragmentName: data?.createUser.__typename,
            });
            return [newTodoRef, ...prev];
          },
        },
      });
    },
  });
  const deleteUserIsLoading = deleteUserResult.loading;

  const [updateUser, updateUserResult] = useUpdateUserMutation();

  const deleteUser = async (payload: UserFragment) => {
    await deleteUserMutation({
      variables: { id: payload.id },
      update(cache) {
        cache.evict({ id: cache.identify(payload) });
        cache.gc();
      },
    });
  };

  const editUser = async (payload: UpdateUserInput) => {
    await updateUser({
      variables: { payload },
      refetchQueries: ['users', 'organizationWithUsers'],
    });
  };

  const createNewUser = async (payload: CreateUserInput) => {
    await createUser({
      variables: { payload },
    });
  };

  const editUserIsLoading = updateUserResult.loading;
  const createUserIsLoading = createUserResult.loading;

  const value = useMemo(
    () => ({
      editUser,
      createNewUser,
      editUserIsLoading,
      createUserIsLoading,
      deleteUserIsLoading,
      deleteUser,
    }),
    [editUser, createNewUser, editUserIsLoading, createUserIsLoading],
  );
  return (
    <UsersCRUDContext.Provider value={value}>
      {children}
    </UsersCRUDContext.Provider>
  );
}

export function useUsersCRUDContext() {
  const context = useContext(UsersCRUDContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useUsersCRUDContext must be used within a UsersCRUDContextProvider',
    );
  }
  return context;
}

export default UsersCRUDContextProvider;
