import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './graphql/apollo-client';
import AuthContextProvider from './auth/contexts/AuthContextProvider';
import AuthApp from './AuthApp';
import { ToastProvider } from './hooks/useToast';
import ModalContextProvider from './contexts/ModalContext';
import UsersCRUDContextProvider from './pages/users/contexts/UsersCRUDContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ApolloProvider client={apolloClient}>
    <UsersCRUDContextProvider>
      <ToastProvider>
        <ModalContextProvider>
          <BrowserRouter>
            <AuthContextProvider>
              <AuthApp>
                <App />
              </AuthApp>
            </AuthContextProvider>
          </BrowserRouter>
        </ModalContextProvider>
      </ToastProvider>
    </UsersCRUDContextProvider>
  </ApolloProvider>,
);
