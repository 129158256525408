import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../../../pages/NotFoundPage/NotFoundPage';
import OrgsManagementPage from '../../../pages/orgsManagement/OrgsManagementPage';
/* import ProvidersPage from '../../../pages/providers/ProvidersPage'; */
import UsersPage from '../../../pages/users/UsersPage';
import VehiclesPage from '../../../pages/vehicles/VehiclesPage';
import { Container } from './Page.style';

const Page = () => {
  return (
    <Container data-testid="Page">
      <Routes>
        <Route path="/" element={<OrgsManagementPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/vehicles" element={<VehiclesPage />} />
        {/* <Route path="/providers" element={<ProvidersPage />} /> */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Container>
  );
};

export default Page;
