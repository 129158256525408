import UsersDrawerContextProvider from '../users/contexts/UsersDrawerContext';
import OrganizationCRUDContextProvider from './contexts/OrganizationCRUDContext';
import OrganizationDataContextProvider from './contexts/OrganizationDataContext';
import OrgsManagementDrawerContextProvider from './contexts/OrgsManagementDrawerContext';
import OrgsManagementContent from './OrgsManagementContent';

const OrgsManagementPage = () => {
  return (
    <UsersDrawerContextProvider>
      <OrganizationDataContextProvider>
        <OrganizationCRUDContextProvider>
          <OrgsManagementDrawerContextProvider>
            <OrgsManagementContent />
          </OrgsManagementDrawerContextProvider>
        </OrganizationCRUDContextProvider>
      </OrganizationDataContextProvider>
    </UsersDrawerContextProvider>
  );
};

export default OrgsManagementPage;
