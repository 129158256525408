import ManagersList from '../../../../components/ManagersList/ManagersList';
import {
  Container,
  Divider,
  SelectorWrapper,
  Title,
} from './OrgsManagementDrawerContentOnView.styles';

const OrgsManagementDrawerContentOnView = () => {
  return (
    <Container data-testid="container props">
      <Divider />
      <SelectorWrapper data-testid="selector wrapper">
        <Title data-testid="title">Gestores</Title>
        <ManagersList />
      </SelectorWrapper>
    </Container>
  );
};

export default OrgsManagementDrawerContentOnView;
