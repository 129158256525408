import styled from 'styled-components';
import theme from '../../../theme';

interface PageListProps {
  isOpen: boolean;
}

export const Container = styled.div`
  grid-area: sidebar;
  background-color: ${theme.colors.darkBlue};
  height: auto;
  width: 17vw;
  max-width: ${theme.sizes.openSidebarWidth};
  padding: 0 0 32px 0;
  color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  transition: max-width 0.5s ease;
  &.closed {
    max-width: ${theme.sizes.closedSidebarWidth};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2rem;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  height: 88px;
`;

export const TestDiv = styled.div`
  position: absolute;
  top: 32px;
  left: 0;
  display: flex;
  align-items: center;
  column-gap: 25px;
  &.small {
    left: -45px;
  }
`;

export const ExpandIconContainer = styled.div`
  display: inline;
  position: absolute;
  top: 25px;
  left: 110%;
  z-index: 1;
  cursor: pointer;
  &.hidden {
    display: none;
  }
`;

export const Options = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-left: 16px;
  margin-right: 16px;
`;

export const PageList = styled.li<PageListProps>`
  display: flex;
  align-items: center;
  padding: ${(props) =>
    props.isOpen ? '8px 0px 8px 12px' : '8px 4px 8px 8px'};
  column-gap: 1rem;
  justify-content: flex-start;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 3px;
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
  &:hover {
    background-color: ${theme.colors.sidebarHover};
  }
  &.selected {
    background-color: ${theme.colors.sidebarHover};
    cursor: default;
  }
`;

export const PageListSpan = styled.span`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 9px;
  left: 45px;
  transition: all 0.25s ease;
  white-space: nowrap;
  &.active {
    opacity: 1;
    visibility: visible;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  position: relative;
`;

export const Settings = styled.div`
  display: flex;
  align-items: center;
  margin-left: 28px;
  margin-bottom: 32px;
  column-gap: 1rem;
  color: ${theme.colors.lightGray};
  cursor: pointer;
`;

export const Divider = styled.hr`
  height: 1px;
  background-color: ${theme.colors.sidebarDivider};
  border: none;
  margin-top: auto;
`;

export const UserBox = styled.div`
  display: flex;
  margin: 32px 16px 16px 16px;
  align-items: center;
  justify-content: space-between;
`;
export const UserInfo = styled.div`
  display: grid;
  grid-template-areas:
    'photo name'
    'photo role';
  column-gap: 8px;
  position: relative;
`;
export const UserPhoto = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  grid-area: photo;
  &.closed {
    cursor: pointer;
  }
`;
export const UserName = styled.span`
  grid-area: name;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  &.closed {
    display: none;
  }
`;
export const UserRole = styled.span`
  grid-area: role;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  &.closed {
    display: none;
  }
`;

export const SelectorSpan = styled.span`
  cursor: pointer;
  color: ${theme.colors.darkGray};
`;
