import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  display: flex;
  min-height: 100%;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 6rem;
  font-weight: bold;
`;

function NotFoundPage() {
  return (
    <Container>
      <Title>404</Title>
      <h2>Desculpe, não conseguimos encontrar essa página.</h2>
      <p>Mas não se preocupe, você pode voltar para a nossa tela principal</p>
      <Link to="/">Ir para a página principal</Link>
    </Container>
  );
}

export default NotFoundPage;
