import HomeIcon from '../icons/HomeIcon/HomeIcon';
import RightArrowIcon from '../icons/RightArrowIcon/RightArrowIcon';
import ColorfulText from '../ColorfulText/ColorfulText';
import { Container, SectionInfo, SectionTitle } from './Header.style';
import theme from '../../theme';
import { ReactNode } from 'react';

interface HeaderProps {
  pageTitle: string;
  children?: ReactNode;
}

const Header = (props: HeaderProps) => {
  const { pageTitle, children } = props;
  return (
    <Container data-testid="Header">
      <SectionInfo data-testid="SectionInfo">
        <HomeIcon />
        <RightArrowIcon />
        <ColorfulText
          backgroundColor={theme.colors.buttonBackgroundPurple}
          textColor={theme.colors.buttonTextPurple}
        >
          {pageTitle}
        </ColorfulText>
      </SectionInfo>
      <SectionTitle>{pageTitle}</SectionTitle>
      <div>{children}</div>
    </Container>
  );
};

export default Header;
