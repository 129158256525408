import React, { ReactNode, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { Container, Row, RowDiv } from './Selector.style';

interface SelectorProps {
  icon: ReactNode;
  position: 'right' | 'left';
  width?: string;
  rowsArray: Array<{
    text: string;
    textColor?: string;
    icon?: ReactNode;
    onClick?: () => void;
    onClickWithData?: (
      data: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => void;
  }>;
}

const Selector = (props: SelectorProps) => {
  const { position, width, rowsArray, icon } = props;
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  useClickAway(ref, () => {
    setTimeout(() => setOpen(false), 100);
  });

  return (
    <div style={{ position: 'relative', cursor: 'pointer', zIndex: '2' }}>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        {icon}
      </div>
      {open && (
        <Container
          className={position === 'right' ? 'right' : 'left'}
          width={width}
          ref={ref}
        >
          {rowsArray.map((item) => (
            <RowDiv onClick={() => setOpen(false)} key={item.text}>
              <Row
                onClick={item.onClick ? item.onClick : item.onClickWithData}
                textColor={item.textColor}
              >
                {item.text}
              </Row>
              {item.icon}
            </RowDiv>
          ))}
        </Container>
      )}
    </div>
  );
};

export default Selector;
