import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import theme from '../../theme';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Icon from '../Icon/Icon';
import CheckIcon from '../icons/CheckIcon/CheckIcon';
import ChevronDownIcon from '../icons/ChevronDownIcon/ChevronDownIcon';
import {
  Container,
  EmptyContainer,
  Item,
  Label,
  LoadingContainer,
  Option,
  OptionsList,
  Select,
  SelectContainer,
  SelectIcon,
  Tag,
} from './SelectSearch.styles';

export interface SelectDataType {
  id: string | null;
  text: string;
  subText?: string;
}

function defaultGetOptionDisabled<Item>(_option: Item) {
  return false;
}

interface SelectSearchProps {
  data: SelectDataType[];
  label?: string;
  name?: string;
  placeholder?: string;
  value?: SelectDataType | null;
  onChange: (value: SelectDataType) => void;
  onSearch: (text: string) => void;
  labelStyle?: object;
  newTagText?: string;
  loading?: boolean;
  disabled?: boolean;
  onAddNew?: (text: string) => void;
  error?: string;
  getDisabledOption?: (option: SelectDataType) => boolean;
}

function SelectSearch(props: SelectSearchProps) {
  const {
    label,
    placeholder,
    data,
    value,
    onChange,
    onSearch,
    labelStyle,
    newTagText,
    loading,
    disabled,
    onAddNew,
    error,
    name,
    getDisabledOption = defaultGetOptionDisabled,
  } = props;
  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState(value?.text || '');
  const ref = useRef([] as HTMLElement[]);
  const inputRef = useRef<HTMLInputElement>(null);

  function hideOptions() {
    setTimeout(() => {
      let hasActiveItem = false;
      ref.current.forEach((item: HTMLElement) => {
        if (item === document.activeElement) {
          hasActiveItem = true;
        }
      });
      if (!hasActiveItem) setShowOptions(false);
    }, 200);
  }

  function hardCloseOptions() {
    setShowOptions(false);
  }

  function handleClickButton() {
    if (showOptions) {
      inputRef?.current?.blur();
    } else {
      inputRef?.current?.focus();
    }
  }

  function openOptions() {
    setShowOptions(true);
  }

  function onInputTextChange(text: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(text.target.value);
    onSearch(text.target.value);
  }

  function selectItem(item: SelectDataType) {
    setInputValue(item.text ?? '');
    onChange(item);
    hardCloseOptions();
  }

  function selectAddNew() {
    hardCloseOptions();
    onAddNew?.(inputValue);
    onChange({ id: '', text: inputValue });
  }

  useEffect(() => {
    setInputValue(value?.text ?? '');
  }, [value]);

  return (
    <SelectContainer data-testid={name?.toLowerCase().replaceAll(' ', '-')}>
      {loading && !value ? (
        <LoadingContainer>
          <Skeleton
            width={'100%'}
            height={'100%'}
            style={{ display: 'inline-block' }}
          />
        </LoadingContainer>
      ) : (
        <Container
          id="select-block"
          $showOptions={showOptions}
          $disabled={disabled}
        >
          <Select
            type="search"
            ref={inputRef}
            onFocus={openOptions}
            onBlur={hideOptions}
            placeholder={placeholder || ''}
            value={inputValue}
            onChange={onInputTextChange}
            id={name || label?.toLocaleLowerCase().replaceAll(' ', '-')}
          />
          <Label
            htmlFor={name || label?.toLocaleLowerCase().replaceAll(' ', '-')}
            $hasLabel
          >
            <span style={{ ...labelStyle }}>{label}</span>
          </Label>
          <SelectIcon $showOptions={showOptions}>
            <Icon onClick={handleClickButton}>
              <ChevronDownIcon />
            </Icon>
          </SelectIcon>
          <OptionsList $showOptions={showOptions}>
            {data?.length > 0 ? (
              data.map((item: SelectDataType, index: number) => (
                <Option
                  ref={(element) =>
                    (ref.current[index] =
                      element || document.createElement('p'))
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') selectItem(item);
                  }}
                  key={index}
                  tabIndex={0}
                  onBlur={hideOptions}
                  onClick={
                    getDisabledOption(item)
                      ? () => undefined
                      : () => selectItem(item)
                  }
                  $isSelected={inputValue === item.text}
                  $disabled={getDisabledOption(item)}
                >
                  <Item>
                    <p>{item.text}</p>
                    {item.subText && (
                      <Tag>
                        <span>{item.subText}</span>
                      </Tag>
                    )}
                  </Item>
                  {inputValue === item.text && (
                    <Icon>
                      <CheckIcon color={theme.colors.darkBlue} />
                    </Icon>
                  )}
                </Option>
              ))
            ) : onAddNew && inputValue !== '' ? (
              <Option
                ref={(element) =>
                  (ref.current[0] = element || document.createElement('p'))
                }
                tabIndex={0}
                onBlur={hideOptions}
                onClick={selectAddNew}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') selectAddNew();
                }}
              >
                <p>{inputValue}</p>
                <Tag>
                  <span>{newTagText || 'Adicionar novo'}</span>
                </Tag>
              </Option>
            ) : (
              <EmptyContainer>
                <span>Nenhum dado encontrado</span>
              </EmptyContainer>
            )}
          </OptionsList>
        </Container>
      )}

      {error && <ErrorMessage message={error} />}
    </SelectContainer>
  );
}

export default SelectSearch;
