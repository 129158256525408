import { useMemo, useState } from 'react';
import { UserFragment } from '../../generated/graphqlV2';
import SelectSearch, { SelectDataType } from '../SelectSearch/SelectSearch';

interface SelectOrganizationProps {
  user: UserFragment | undefined;
  onChange: (value: SelectDataType) => void;
  name?: string;
  loading?: boolean;
  value?: SelectDataType | null;
}

const SelectOrganization = (props: SelectOrganizationProps) => {
  const { user, onChange, loading, name, value } = props;

  const userOrganizations = useMemo(() => {
    if (!user) return [{ id: '', text: '' }];
    const parsedOrganizationsData = user.organizations.map((organization) => {
      return {
        id: organization.id,
        text: organization.name,
      };
    });
    return parsedOrganizationsData;
  }, [user?.organizations]);

  const [filteredOrganizationOptions, setFilterdOrganizationOptions] =
    useState(userOrganizations);

  function searchOrganizationOptions(search: string) {
    const filteredData = userOrganizations.filter((item) => {
      return item.text.toLowerCase().search(search.toLowerCase()) !== -1;
    });
    setFilterdOrganizationOptions(filteredData);
  }

  return (
    <SelectSearch
      data={filteredOrganizationOptions}
      onSearch={searchOrganizationOptions}
      onChange={onChange}
      name={name}
      loading={loading}
      label="Unidade"
      value={value}
    />
  );
};

export default SelectOrganization;
