import { FuelTypeEnum } from '../generated/graphqlV2';

export const formatFuelEnum = (fuel: FuelTypeEnum | undefined | null) => {
  if (fuel) {
    if (fuel === 'arla_32') return 'Arla 32';
    if (fuel === 'diesel') return 'Diesel';
    if (fuel === 'ethanol') return 'Etanol';
    if (fuel === 'gasoline') return 'Gasolina';
    if (fuel === 'gnv') return 'GNV';
    if (fuel === 'kerosene') return 'Querosene';
    if (fuel === 'other') return 'Outro';
  }
};
