import styled from 'styled-components';
import theme from '../../../../theme';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 80px);
`;

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 32px;
  column-gap: 30px;
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 20px;
  flex: 10;
  margin: 16px 25px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 20px;
  margin-bottom: 16px;
`;

export const TextPlacer = styled.input`
  width: 100%;
  padding: 14px 8px;
  margin-bottom: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: ${theme.colors.black};
  &::placeholder {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: ${theme.colors.middleGray};
  }
  &.selector {
    margin-top: 32px;
  }
  &.error {
    border: 1px solid red;
  }
`;
export const TextArea = styled.textarea`
  width: 100%;
  padding: 14px 8px;
  margin-bottom: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: ${theme.colors.middleGray};
  resize: none;
  &::placeholder {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: ${theme.colors.middleGray};
  }
`;

export const ErrorMessage = styled.p`
  margin-top: -16px;
  margin-bottom: 16px;
  color: red;
`;

export const Footer = styled.div`
  padding: 16px 24px;
  border-top: 1px solid #e4e4e4;
  box-shadow: 0px 0px 40px rgba(140, 152, 164, 0.176);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const PurpleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.buttonTextPurple};
  color: ${theme.colors.white};
  padding: 9px 0;
  cursor: pointer;
  width: 100%;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
`;
