import styled from 'styled-components';

export const CardPlacer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 30px 30px;
  height: 100%;
  column-gap: 32px;
  row-gap: 32px;
  width: fit-content;
`;

export const EmptyCard = styled.div`
  border: 1px dashed #d7dfe9;
  border-radius: 4px;
  width: 300px;
  height: 250px;
  padding: 16px;
  background-color: transparent;
  margin-bottom: 32px;
`;
