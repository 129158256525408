import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as AnimatedLogo } from './logo-icon-animated.svg';

const floating = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-15px);
  }
  100% {
    transform: translatey(0px);
  }
`;

const shadowReact = keyframes`
  0% {
    margin: 0;
    opacity: 0.3;
  }
  50% {
    margin: 0 20% 0 20%;
    opacity: 0.7;
  }
  100% {
    margin: 0;
    opacity: 0.3;
  }
`;

interface ContainerProps {
  fullPage: boolean;
}

const Container = styled.div<ContainerProps>`
  height: ${(props) => (props.fullPage ? '100vh' : '100%')};
  width: ${(props) => (props.fullPage ? '100vw' : '100%')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: '#123214';
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999; // Safe z-index
`;

const Stage = styled.div`
  width: 150px;
  padding-bottom: 30px;
`;

const Label = styled.div`
  text-align: center;
  font-weight: 300;
  color: '#213541';
`;

const Logo = styled.div`
  animation: ${floating} ease-in-out 3.5s infinite;
`;

const LogoShadow = styled.div`
  position: relative;
  height: 16px;
  opacity: 0.1;
  transform: translateY(-40px);
  border-radius: 100%;
  top: 0;
  margin: 0;
  box-shadow: 0 50px 15px 0 rgba(0, 0, 0, 0.8);
  animation: ${shadowReact} ease-in-out 3.5s infinite;
`;

export interface FullLoadingProps {
  label?: string;
  fullPage?: boolean;
}

function FullLoading(props: FullLoadingProps) {
  const { label = ' Carregando...', fullPage = true } = props;
  return (
    <Container fullPage={fullPage}>
      <div>
        <Stage>
          <Logo>
            <AnimatedLogo />
          </Logo>
          <LogoShadow />
        </Stage>
        <Label>{label}</Label>
      </div>
    </Container>
  );
}

export default FullLoading;
