import React, { useEffect } from 'react';
import { ToastMessage, useToast } from '../../../hooks/useToast';
import AlertCircleIcon from '../../icons/AlertCircleIcon/AlertCircleIcon';
import CircleCheckIcon from '../../icons/CircleCheckIcon/CircleCheckIcon';
import InfoIcon from '../../icons/InfoIcon/InfoIcon';
import { Container } from './ToastMessage.styles';

interface MessageProps {
  message: ToastMessage;
}

const icons = {
  info: <InfoIcon width={22} height={22} />,
  success: <CircleCheckIcon width={22} height={22} />,
  error: <AlertCircleIcon width={22} height={22} />,
};

const Message: React.FC<MessageProps> = ({ message }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [message, removeToast]);

  return (
    <Container type={message.type} $hasDescription={!!message.description}>
      {icons[message.type || 'info']}

      <div>
        {message.title}
        {message.description && <p>{message.description}</p>}
      </div>

      <button
        onClick={() => removeToast(message.id)}
        type="button"
        style={{ fontSize: '16px', cursor: 'pointer' }}
      >
        X
      </button>
    </Container>
  );
};

export default Message;
