import { UserRole } from '../generated/graphqlV2';

export interface UserPossibleRole {
  type: UserRole;
  label: string;
}

export const USER_ROLES: UserPossibleRole[] = [
  {
    type: UserRole.Admin,
    label: 'Administrador',
  },
  {
    type: UserRole.Director,
    label: 'Diretor',
  },
  {
    type: UserRole.Driver,
    label: 'Motorista',
  },
  {
    type: UserRole.Manager,
    label: 'Gestor',
  },
  {
    type: UserRole.User,
    label: 'Usuário',
  },
];

export const SELECTOR_ROLES = [
  {
    type: UserRole.Admin,
    label: 'Administrador',
  },
  {
    type: UserRole.Driver,
    label: 'Motorista',
  },
  {
    type: UserRole.Manager,
    label: 'Gestor',
  },
];
