import ColorfulText from '../../../components/ColorfulText/ColorfulText';
import ActionButtonIcon from '../../../components/icons/ActionButtonIcon/ActionButtonIcon';
import AscendingSortIcon from '../../../components/icons/AscendingSortIcon/AscendingSortIcon';
import DescendingSortIcon from '../../../components/icons/DescendingSortIcon/DescendingSortIcon';
import NoSortIcon from '../../../components/icons/NoSortIcon/NoSortIcon';
import { Table } from '../../../components/Table/TableTypes';
import { HeaderData } from '../../../components/Table/styles';
import { cpfMask } from '../../../helpers/cpf-mask';
import theme from '../../../theme';
import { SortingType } from '../contexts/UsersTableContext';
import { useUsersDrawerContext } from '../contexts/UsersDrawerContext';
import Selector from '../../../components/Selector/Selector';
import { useModalContext } from '../../../contexts/ModalContext';
import DeleteModalContent from '../components/DeleteModalContent/DeleteModalContent';
import { UserFragment } from '../../../generated/graphqlV2';
import DrawerHeader from '../../../components/CustomDrawer/DrawerHeader/DrawerHeader';
import { removeDisabledOrganizations } from './remove-disabled-organizations';
import { removePrimaryOrganization } from './remove-primary-organization';

interface TableProps {
  users: UserFragment[];
  sortBy: (sortOption: string) => void;
  sortConfig: {
    propertyName: string | undefined;
    sortType: SortingType | undefined;
  };
  currentPage: number;
  maxItensRendered: number;
}

export default function formatUsersTableData(
  props: TableProps,
): Table<UserFragment> {
  const { sortBy, sortConfig, users, currentPage, maxItensRendered } = props;
  const SortDirection = (property: string) => {
    if (sortConfig.propertyName === property) {
      if (sortConfig.sortType === SortingType.Descending) {
        return <DescendingSortIcon />;
      } else {
        return <AscendingSortIcon />;
      }
    }
    return <NoSortIcon />;
  };
  const parsedTableData = users.map((user) => ({
    ...user,
    organizations: removePrimaryOrganization(
      removeDisabledOrganizations(user.organizations),
    ),
  }));
  return {
    header: [
      {
        render: () => <HeaderData>#</HeaderData>,
      },
      {
        render: () => (
          <HeaderData onClick={() => sortBy('name')} className="sortable">
            <span>Nome</span>
            {SortDirection('name')}
          </HeaderData>
        ),
      },
      {
        render: () => (
          <HeaderData onClick={() => sortBy('email')} className="sortable">
            <span>E-mail</span>
            {SortDirection('email')}
          </HeaderData>
        ),
      },
      {
        render: () => (
          <HeaderData onClick={() => sortBy('cpf')} className="sortable">
            <span>CPF</span>
            {SortDirection('cpf')}
          </HeaderData>
        ),
      },
      {
        render: () => <HeaderData>Unidade</HeaderData>,
      },
      {
        render: () => <HeaderData></HeaderData>,
      },
    ],
    rows: parsedTableData.map((user, index) => ({
      data: user,
      cells: [
        {
          render: () => (
            <span>
              {currentPage !== 0
                ? index + 1 + (currentPage - 1) * maxItensRendered
                : index + 1}
            </span>
          ),
        },
        {
          render: (user) => <span>{user.name}</span>,
        },
        {
          render: (user) => <span>{user.email}</span>,
        },
        {
          render: (user) => <span>{cpfMask(user.cpf)}</span>,
        },
        {
          render: (user) => (
            <>
              {user.organizations.map((organization, index) => (
                <ColorfulText
                  key={index}
                  backgroundColor={theme.colors.darkBlue}
                  textColor={theme.colors.white}
                >
                  {organization.name}
                </ColorfulText>
              ))}
            </>
          ),
        },
        {
          render: (user) => {
            const {
              isOpen,
              setIsOpen,
              setIsEdit,
              setSelectedUser,
              setdrawerHeader,
            } = useUsersDrawerContext();
            const {
              setOpenModal,
              setModalTitle,
              setContent,
              setModalDescription,
            } = useModalContext();
            const handleEdit = () => {
              setdrawerHeader(<DrawerHeader title="Editar gestor" />);
              setIsOpen(!isOpen);
              setSelectedUser(user);
              setIsEdit(true);
            };
            const handleDelete = () => {
              setOpenModal(true);
              setModalTitle(`Confirmar remoção`);
              setModalDescription([
                `Tem certeza que deseja deletar o usuário ${user.name}?`,
              ]);
              setSelectedUser(user);
              setContent(
                <DeleteModalContent
                  user={user}
                  closeModal={() => setOpenModal(false)}
                />,
              );
            };
            const SelectorArray =
              user.role === 'director'
                ? [{ text: 'Editar', onClick: handleEdit }]
                : [
                    { text: 'Editar', onClick: handleEdit },
                    { text: 'Deletar', onClick: handleDelete },
                  ];
            return (
              <>
                {user.role === 'manager' ? (
                  <Selector
                    position="right"
                    icon={<ActionButtonIcon />}
                    rowsArray={SelectorArray}
                    width="60px"
                  />
                ) : (
                  ''
                )}
              </>
            );
          },
        },
      ],
    })),
  };
}
