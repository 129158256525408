/* eslint-disable react/prop-types */
import { useOrgsManagementDrawerContext } from '../../contexts/OrgsManagementDrawerContext';
import {
  Form,
  Footer,
  Inputs,
  PurpleButton,
  Row,
} from './OrgsManagementDrawerContent.style';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import { SelectDataType } from '../../../../components/MultipleSelectSearch/MultipleSelectSearch';
import { useOrganizationCRUDContext } from '../../contexts/OrganizationCRUDContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '../../../../hooks/useToast';
import { cnpjMask } from '../../../../helpers/cnpj-mask';

interface FormValues {
  name: string;
  phone: string;
  address: string;
  cnpj: string;
  users: SelectDataType[];
}

const schema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
});

const OrgsManagementDrawerContentOnEdit = () => {
  const { selectedOrganization, setIsOpen } = useOrgsManagementDrawerContext();
  const { editOrganization, editOrganizationIsLoading } =
    useOrganizationCRUDContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: selectedOrganization?.name || undefined,
      phone: selectedOrganization?.phone || undefined,
      address: selectedOrganization?.address || undefined,
      cnpj: selectedOrganization?.cnpj || undefined,
      users:
        selectedOrganization?.users.filter((user) => user.role === 'manager') ||
        undefined,
    },
  });
  const { addToast } = useToast();

  const onSubmit = async (data: FormValues) => {
    if (selectedOrganization) {
      try {
        const payload = {
          id: selectedOrganization?.id,
          cnpj: data.cnpj?.replace(/[^0-9]/g, ''),
          name: data.name,
          address: data.address,
          phone: data.phone,
        };
        await editOrganization(payload);
        addToast({ title: 'Unidade editada com sucesso!', type: 'success' });
      } catch (error) {
        addToast({ title: 'Erro na edição da unidade.', type: 'error' });
        console.log('Erro: ', error);
      } finally {
        setIsOpen(false);
      }
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Inputs>
        <Row>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                label={
                  selectedOrganization?.primaryOrganization === null
                    ? 'Nome da matriz'
                    : 'Nome da unidade'
                }
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                type="text"
                width="550px"
                disabled={editOrganizationIsLoading}
                error={errors.name?.message && 'O nome é obrigatório'}
                loading={editOrganizationIsLoading}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="cnpj"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                label="CNPJ"
                onChange={props.field.onChange}
                name={props.field.name}
                value={cnpjMask(props.field.value)}
                type="text"
                width="550px"
                disabled={editOrganizationIsLoading}
                loading={editOrganizationIsLoading}
                error={
                  errors.cnpj?.message &&
                  (props.field.value
                    ? 'Insira todos os 14 caracteres'
                    : 'O CNPJ é obrigatório')
                }
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="phone"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <Input
                label="Telefone"
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                type="text"
                width="550px"
                disabled={editOrganizationIsLoading}
                loading={editOrganizationIsLoading}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="address"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <Input
                label="Endereço"
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                type="text"
                width="550px"
                disabled={editOrganizationIsLoading}
                loading={editOrganizationIsLoading}
              />
            )}
          />
        </Row>
      </Inputs>
      <Footer>
        <PurpleButton disabled={editOrganizationIsLoading}>
          {selectedOrganization?.primaryOrganization === null
            ? 'Editar Matriz'
            : 'Editar unidade'}
        </PurpleButton>
      </Footer>
    </Form>
  );
};

export default OrgsManagementDrawerContentOnEdit;
