import styled, { css } from 'styled-components';
import theme from '../../theme';

interface SelectProps {
  $showOptions?: boolean;
  $disabled?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const NoManagersText = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.colors.black};
`;

export const SelectContainer = styled.div`
  width: 100%;
  height: calc(100vh - 158px);
`;

export const SearchContainer = styled.div`
  width: 100%;
  height: 44px;
  position: relative;
  margin-bottom: 16px;
`;

export const Search = styled.input`
  width: 100%;
  max-width: calc(100%-16px);
  padding: 13px 8px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #898989;
  &:focus {
    outline: none !important;
    border: 1px solid ${theme.colors.darkBlue};
  }
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
`;

export const SelectIcon = styled.div<SelectProps>`
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s ease;

  ${({ $showOptions }) =>
    $showOptions &&
    css`
      transform: rotate(180deg) translateY(50%);
    `}
`;

export const List = styled.ul`
  padding: 0;
  width: 100%;
  height: 85%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.darkBlue};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.sidebarHover};
  }
`;

export const ListItem = styled.li`
  list-style-type: none;
  width: calc(100% - 32px);
  margin-bottom: 8px;
  transition: all 0.2s ease;
  &:nth-child(odd) {
    background-color: #f9fafb;
  }
  padding: 12px 16px;
  &:hover {
    background-color: rgba(14, 45, 102, 0.1);
  }
`;

export const ListData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ManagerIcon = styled.div`
  border-radius: 50%;
  border: 1px solid white;
  padding: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.lightPurple};
  color: #7758c799;
`;

export const Name = styled.span`
  color: #667085;
  font-size: 14px;
`;

export const Warning = styled.span`
  max-width: 240px;
  word-wrap: normal;
  margin-left: 100px;
  font-size: 14px;
  color: #98a2b3;
`;

export const RemoveButton = styled.span`
  margin-right: 30px;
  color: #667085;
  cursor: pointer;
  font-size: 14px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
`;
