import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  grid-area: header;
  background-color: ${theme.colors.backgroundColor};
  display: grid;
  grid-template-areas:
    'info info'
    'title button';
  padding: 16px 28px;
  row-gap: 16px;
  justify-content: space-between;
`;
export const SectionInfo = styled.div`
  grid-area: info;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;
export const SectionTitle = styled.h1`
  grid-area: title;
  color: ${theme.colors.headerBlack};
  font-size: 28px;
  font-weight: 600;
`;
