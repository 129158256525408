import React, { useCallback, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useClickAway } from 'react-use';
import theme from '../../theme';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Icon from '../Icon/Icon';
import Check from '../icons/CheckIcon/CheckIcon';
import ChevronDown from '../icons/ChevronDownIcon/ChevronDownIcon';
import {
  Container,
  EmptyContainer,
  Item,
  Label,
  LoadingContainer,
  Option,
  OptionsList,
  Select,
  SelectIcon,
  Tag,
} from './MutipleSelectSearch.styles';

export interface SelectDataType {
  id: string;
  text: string;
  subText?: string;
}

interface MultiSelectProps {
  data: SelectDataType[];
  label?: string;
  name?: string;
  placeholder?: string;
  value?: SelectDataType[];
  onChange: (value: SelectDataType[]) => void;
  onSearch: (text: string) => void;
  search?: string;
  labelStyle?: object;
  newTagText?: string;
  loading?: boolean;
  disabled?: boolean;
  onAddNew?: (text: string) => void;
  error?: string;
  getDisabledOption?: (option: SelectDataType) => boolean;
  selectAll?: boolean;
  size?: 'normal' | 'small';
}

function defaultGetOptionDisabled<Item>(_option: Item) {
  return false;
}

const emptyObject = {};

function MultipleSelectSearch(props: MultiSelectProps) {
  const {
    label,
    placeholder,
    data,
    value,
    onChange,
    onSearch,
    search,
    labelStyle,
    newTagText,
    loading,
    disabled,
    onAddNew,
    error,
    name,
    selectAll,
    getDisabledOption = defaultGetOptionDisabled,
    size,
  } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const containerRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  function hardCloseOptions() {
    setShowOptions(false);
  }

  const openOptions = useCallback(() => {
    setInputValue('');
    onSearch('');
    setShowOptions(true);
  }, [onSearch]);

  const handleClickButton = useCallback(() => {
    if (showOptions) {
      setShowOptions(false);
      inputRef.current?.blur();
    } else {
      openOptions();
      inputRef.current?.focus();
    }
  }, [openOptions, showOptions]);

  function onInputTextChange(text: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(text.target.value);
    onSearch(text.target.value);
  }

  const selectItem = useCallback(
    (item: SelectDataType) => {
      const foundValue = value?.find((v) => v.id === item.id);

      if (foundValue && value) {
        onChange(value?.filter((v) => v.id !== item.id));
      } else {
        onChange([...(value ?? []), item]);
      }
    },
    [onChange, value],
  );

  function handleSelectAll() {
    if (value?.length === data.length) {
      onChange([]);
    } else {
      onChange(data);
    }

    onSearch('');
    setShowOptions(false);
  }

  function selectAddNew() {
    hardCloseOptions();
    onAddNew?.(inputValue);
  }

  useEffect(() => {
    if (showOptions) return;

    let inputText = '';

    if (value?.length && value.length > 0) {
      inputText = `${value.length} ${
        value.length > 1 ? 'itens selecionados' : 'item selecionado'
      }`;
    } else {
      inputText = 'Nenhum item selecionado';
    }

    setInputValue(inputText);
  }, [value, showOptions]);

  useClickAway(containerRef, () => {
    containerRef?.current?.blur();

    setShowOptions(false);
  });

  return (
    <div
      data-testid={name?.toLowerCase().replaceAll(' ', '-')}
      ref={containerRef}
    >
      {loading ? (
        <LoadingContainer>
          <Skeleton
            width={'100%'}
            height={'100%'}
            style={{ display: 'inline-block' }}
          />
        </LoadingContainer>
      ) : (
        <Container
          id="select-block"
          $showOptions={showOptions}
          $disabled={disabled}
          $size={size}
        >
          <Select
            type="search"
            onFocus={openOptions}
            ref={inputRef}
            $showOptions={showOptions}
            placeholder={placeholder || ''}
            value={inputValue}
            onChange={onInputTextChange}
            id={name || label?.toLocaleLowerCase().replaceAll(' ', '-')}
          />
          <Label
            htmlFor={name || label?.toLocaleLowerCase().replaceAll(' ', '-')}
            $hasLabel
          >
            <span style={{ ...labelStyle }}>{label}</span>
          </Label>
          <SelectIcon
            $size={size}
            $showOptions={showOptions}
            onClick={handleClickButton}
          >
            <Icon onClick={handleClickButton}>
              <ChevronDown />
            </Icon>
          </SelectIcon>

          <OptionsList $showOptions={showOptions} $size={size}>
            {data?.length > 0 ? (
              <>
                {!search && selectAll && (
                  <Option
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') handleSelectAll();
                    }}
                    tabIndex={0}
                    onClick={handleSelectAll}
                    $isSelected={value?.length === data.length}
                    $size={size}
                  >
                    <p>Todos</p>
                    {value?.length === data.length && (
                      <Icon>
                        <Check color={theme.colors.darkBlue} />
                      </Icon>
                    )}
                  </Option>
                )}
                {data.map((item: SelectDataType, index: number) => (
                  <Option
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') selectItem(item);
                    }}
                    key={index}
                    tabIndex={0}
                    onClick={
                      getDisabledOption(item)
                        ? () => emptyObject
                        : () => selectItem(item)
                    }
                    $isSelected={inputValue === item.text}
                    $disabled={getDisabledOption(item)}
                    $size={size}
                  >
                    <Item>
                      <p>{item.text}</p>
                      {item.subText && (
                        <Tag>
                          <span>{item.subText}</span>
                        </Tag>
                      )}
                    </Item>
                    {value?.find((velue) => velue.id === item.id) && (
                      <Icon>
                        <Check color={theme.colors.darkBlue} />
                      </Icon>
                    )}
                  </Option>
                ))}
              </>
            ) : onAddNew && inputValue !== '' ? (
              <Option
                tabIndex={0}
                onClick={selectAddNew}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') selectAddNew();
                }}
                $size={size}
              >
                <p>{inputValue}</p>
                <Tag>
                  <span>{newTagText || 'Adicionar novo'}</span>
                </Tag>
              </Option>
            ) : (
              <EmptyContainer>
                <span>Nenhum dado encontrado</span>
              </EmptyContainer>
            )}
          </OptionsList>
        </Container>
      )}

      {error && <ErrorMessage message={error} />}
    </div>
  );
}

export default MultipleSelectSearch;
