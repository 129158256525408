import React from 'react';
import Skeleton from 'react-loading-skeleton';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import {
  Container,
  LoadingContainer,
  TextAreaContainer,
} from './TextArea.styles';

interface TextAreaProps {
  error?: string;
  label?: string;
  width?: string;
  name: string;
  loading?: boolean;
  onChange: (value: string | null) => void;
  value?: string | null;
  placeholder?: string;
  id?: string;
  labelActive?: boolean;
}

function TextArea(props: TextAreaProps) {
  const {
    placeholder,
    value,
    onChange,
    error,
    name,
    id,
    label,
    width,
    loading,
    labelActive,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const emptyValue = event.target.value === '';

    if (emptyValue) {
      onChange(null);
    } else {
      onChange(event.target.value);
    }
  };

  return (
    <div>
      <Container $hasLabel={!!label} $width={width}>
        <TextAreaContainer $labelActive={labelActive}>
          {loading ? (
            <LoadingContainer>
              <Skeleton width="100%" height="100%" />
            </LoadingContainer>
          ) : (
            <>
              <textarea
                placeholder={label ? ' ' : placeholder}
                value={value ?? ''}
                onChange={handleChange}
                name={name}
                id={id ?? name}
              />
              {label && <label htmlFor={id ?? name}>{label}</label>}
            </>
          )}
        </TextAreaContainer>
      </Container>
      {error && <ErrorMessage message={error} />}
    </div>
  );
}

export default TextArea;
