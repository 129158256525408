import CompaniesIcon from '../../../components/icons/CompaniesIcon/CompaniesIcon';
import UserIcon from '../../../components/icons/UserIcon/UserIcon';
import VehicleIcon from '../../../components/icons/VehicleIcon/VehicleIcon';

export const pagesOptions = [
  /* {
        icon: <LocationIcon />,
        title: 'Indicadores',
        route: '/dashboard',
        testId: 'NavigateToDashboard',
    }, */
  {
    icon: <CompaniesIcon />,
    title: 'Matriz e Filiais',
    route: '/',
    testId: 'NavigateToMain',
  },
  {
    icon: <UserIcon />,
    title: 'Gestores',
    route: '/users',
    testId: 'NavigateToUsers',
  },
  {
    icon: <VehicleIcon />,
    title: 'Veículos',
    route: '/vehicles',
    testId: 'NavigateToVehicles',
  },
  /* {
    icon: <BriefCaseIcon />,
    title: 'Fornecedores',
    route: '/providers',
    testId: 'NavigateToProviders',
  }, */
];
