import React, { useMemo, useState } from 'react';
import { UserFragment } from '../../generated/graphqlV2';
import MultipleSelectSearch, {
  SelectDataType,
} from '../MultipleSelectSearch/MultipleSelectSearch';

interface OrganizationsSelectorProps {
  currentUser: UserFragment | undefined;
  onChange: (value: SelectDataType[]) => void;
  name?: string;
  value?: SelectDataType[];
  disabled?: boolean;
  error?: string;
  loading?: boolean;
}

const OrganizationsSelector = (props: OrganizationsSelectorProps) => {
  const { currentUser, onChange, name, value, disabled, error, loading } =
    props;

  const userOrganizations = useMemo(() => {
    if (!currentUser) return [{ id: '', text: '' }];
    const parsedOrganizationsData = currentUser.organizations.map(
      (organization) => {
        return {
          id: organization.id,
          text: organization.name,
        };
      },
    );
    return parsedOrganizationsData;
  }, [currentUser?.organizations]);

  const [filteredOrganizationOptions, setFilterdOrganizationOptions] =
    useState(userOrganizations);

  function searchOrganizationOptions(search: string) {
    const filteredData = userOrganizations.filter((item) => {
      return item.text.toLowerCase().search(search.toLowerCase()) !== -1;
    });
    setFilterdOrganizationOptions(filteredData);
  }

  const disbledOption = (organization: SelectDataType) =>
    organization.id ===
    currentUser?.organizations.find(
      (organization) => !organization.primaryOrganization,
    )?.id;

  return (
    <MultipleSelectSearch
      onSearch={searchOrganizationOptions}
      data={filteredOrganizationOptions}
      label="Selecione a(s) empresa(s)"
      value={value}
      name={name}
      onChange={onChange}
      disabled={disabled}
      error={error}
      loading={loading}
      getDisabledOption={disbledOption}
    />
  );
};

export default OrganizationsSelector;
