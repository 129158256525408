import styled from 'styled-components';
import theme from '../../theme';

export const Screen = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.darkBlueTransparent};
  z-index: 19;
`;

export const ModalContainer = styled.div`
  background-color: ${theme.colors.white};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  -webkit-box-shadow: -1px 4px 18px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 4px 18px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 4px 18px 0px rgba(0, 0, 0, 0.75);
  padding: 36px 40px;
  z-index: 20;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
`;

export const ModalTitle = styled.span`
  color: ${theme.colors.darkBlue};
  font-size: 20px;
  margin-bottom: 8px;
`;
export const ModalDescription = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-self: center;
`;
