import styled from 'styled-components';
import theme from '../../../../theme';

export const Container = styled.div`
  padding: 0 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
  height: 78vh;
`;

export const RowsContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  background-color: white;
  height: 100%;
  &::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.darkBlue};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.sidebarHover};
  }
`;

export const SearchContainer = styled.div`
  background-color: white;
  border: 1px solid ${theme.colors.tableBorder};
  width: 100%;
`;

export const TotalUsers = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid ${theme.colors.tableBorder};
  background-color: white;
  padding: 20px 0;
  span {
    margin-left: 35px;
    margin-right: 200px;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.colors.infleetBlue};
  }
`;
export const PaginationRow = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.tableBorder};
  background-color: white;
`;
