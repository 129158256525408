import { useMemo } from 'react';
import DrawerHeader from '../../../../components/CustomDrawer/DrawerHeader/DrawerHeader';
import OrganizationCard from '../../../../components/OrganizationCard/OrganizationCard';
import { OrganizationWithUsersFragment } from '../../../../generated/graphqlV2';
import { useOrganizationDataContext } from '../../contexts/OrganizationDataContext';
import {
  OrgsDrawerTypes,
  useOrgsManagementDrawerContext,
} from '../../contexts/OrgsManagementDrawerContext';
import { CardPlacer, EmptyCard } from './OrganizationsCards.styles';

const OrganizationCards = () => {
  const { organizationsWithUsers, headquarterOrganization } =
    useOrganizationDataContext();
  const { setIsOpen, setdrawerHeader, setdrawerType, setSelectedOrganization } =
    useOrgsManagementDrawerContext();
  const handleEditClick = (
    selectedOrganization: OrganizationWithUsersFragment,
  ) => {
    const drawerHeader =
      selectedOrganization.primaryOrganization === null ? (
        <DrawerHeader title="Editar matriz" />
      ) : (
        <DrawerHeader title="Editar unidade" />
      );
    setIsOpen(true);
    setdrawerHeader(drawerHeader);
    setdrawerType(OrgsDrawerTypes.Edit);
    setSelectedOrganization(selectedOrganization);
  };

  const handleViewClick = (
    selectedOrganization: OrganizationWithUsersFragment | undefined,
  ) => {
    setdrawerHeader(
      <DrawerHeader title={selectedOrganization?.name || ''} hasIcon={true} />,
    );
    setIsOpen(true);
    setdrawerType(OrgsDrawerTypes.View);
    setSelectedOrganization(selectedOrganization);
  };

  const organizationsList = useMemo(
    () =>
      [
        headquarterOrganization,
        ...(organizationsWithUsers as OrganizationWithUsersFragment[]),
      ] as OrganizationWithUsersFragment[],
    [headquarterOrganization, organizationsWithUsers],
  );
  return (
    <CardPlacer>
      {organizationsList?.map((organization, index) => {
        if (!organization) return <div key={index}></div>;
        return (
          <OrganizationCard
            key={index}
            name={organization.name}
            address={organization.address}
            managers={organization.users}
            primaryOrganization={organization.primaryOrganization}
            handleEditClick={handleEditClick}
            handleViewClick={handleViewClick}
            organization={organization}
            usersQuantity={
              organization.users.filter((user) => user.role === 'manager')
                .length
            }
          />
        );
      })}
      <EmptyCard></EmptyCard>
    </CardPlacer>
  );
};

export default OrganizationCards;
