import { ReactNode } from 'react';
import { ActualTooltip, Container, HoverPlace } from './Tooltip.style';

interface TooltipProps {
  children: ReactNode;
  rowList: string[];
  fontSize?: string;
}

const Tooltip = (props: TooltipProps) => {
  const { children, fontSize = '12px', rowList } = props;
  return (
    <Container>
      <HoverPlace>{children}</HoverPlace>
      <ActualTooltip fontSize={fontSize}>
        {rowList.map((rowText, index: number) => (
          <p key={`${index}-${rowText}`}>{rowText}</p>
        ))}
      </ActualTooltip>
    </Container>
  );
};

export default Tooltip;
