import { ReactNode } from 'react';
import { Container } from './ColorfulText.style';

interface ColorfulTextProps {
  children: ReactNode;
  textColor: string;
  backgroundColor: string;
  width?: string;
}

const ColorfulText = (props: ColorfulTextProps) => {
  const { children, textColor, backgroundColor, width } = props;
  return (
    <Container
      data-testid="ColorfulText"
      textColor={textColor}
      backgroundColor={backgroundColor}
      width={width}
    >
      <span>{children}</span>
    </Container>
  );
};

export default ColorfulText;
