import Header from '../../components/Header/Header';
import UsersTable from './components/UsersTable/UsersTable';
import { Container } from './Users.style';
import UsersTableContextProvider from './contexts/UsersTableContext';
import AddButton from '../../components/AddButton/AddButton';
import { useUsersDrawerContext } from './contexts/UsersDrawerContext';
import DrawerHeader from '../../components/CustomDrawer/DrawerHeader/DrawerHeader';

const UsersPageContent = () => {
  const { setIsOpen, setIsEdit, setdrawerHeader, setSelectedUser } =
    useUsersDrawerContext();
  const handleClick = () => {
    setIsOpen(true);
    setIsEdit(false);
    setdrawerHeader(<DrawerHeader title="Adicionar novo gestor" />);
    setSelectedUser(undefined);
  };
  return (
    <UsersTableContextProvider>
      <Container data-testid="UsersPage">
        <Header pageTitle="Gestores">
          <AddButton onClick={handleClick}>Adicionar novo</AddButton>
        </Header>
        <UsersTable />
      </Container>
    </UsersTableContextProvider>
  );
};

export default UsersPageContent;
