import Header from '../../components/Header/Header';
import VehiclesTable from './components/VehiclesTable/VehiclesTable';
import { Container } from './VehiclesPage.style';

const VehiclesPageContent = () => {
  return (
    <Container data-testid="VehiclesPage">
      <Header pageTitle="Veículos"></Header>
      <VehiclesTable />
    </Container>
  );
};

export default VehiclesPageContent;
