import { createContext, useContext, useMemo, useState } from 'react';
import CircularProgress from '../../../components/CircularProgress/CircularProgress';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import DrawerHeader from '../../../components/CustomDrawer/DrawerHeader/DrawerHeader';
import { LoadingContainer } from '../../../components/ManagersList/ManagersList.styles';
import VehiclesDrawerContent from '../components/VehiclesDrawerContent/VehiclesDrawerContent';
import { useVehiclesCRUDContext } from './VehiclesCRUDContext';
import { VehicleUsefulInfo } from './VehiclesTableContext';

interface Drawer {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isEdit: boolean;
  setIsEdit: (isEdit: boolean) => void;
  selectedVehicle: VehicleUsefulInfo | undefined;
  setSelectedVehicle: (selectedVehicle: VehicleUsefulInfo) => void;
  drawerHeader: JSX.Element;
  setdrawerHeader: (drawerHeader: JSX.Element) => void;
}

interface VehiclesDrawerContextProps {
  children: React.ReactNode;
}

const emptyObject = {};

const VehiclesDrawerContext = createContext<Drawer>(emptyObject as Drawer);

function VehiclesDrawerContextProvider(props: VehiclesDrawerContextProps) {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<VehicleUsefulInfo>();
  const [isEdit, setIsEdit] = useState(false);
  const { isSubmitting } = useVehiclesCRUDContext();
  const [drawerHeader, setdrawerHeader] = useState(
    <DrawerHeader title="Editar veículo" />,
  );

  const value = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      isEdit,
      setIsEdit,
      selectedVehicle,
      setSelectedVehicle,
      drawerHeader,
      setdrawerHeader,
    }),
    [
      isOpen,
      setIsOpen,
      isEdit,
      setIsEdit,
      selectedVehicle,
      setSelectedVehicle,
      drawerHeader,
      setdrawerHeader,
    ],
  );
  return (
    <VehiclesDrawerContext.Provider value={value}>
      <CustomDrawer
        closeDrawer={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        header={drawerHeader}
        content={
          <VehiclesDrawerContent
            isEdit={isEdit}
            selectedVehicle={selectedVehicle}
          />
        }
      />
      {isSubmitting && (
        <LoadingContainer>
          <CircularProgress infinite={true} size={100} />
        </LoadingContainer>
      )}
      {children}
    </VehiclesDrawerContext.Provider>
  );
}

export function useVehiclesDrawerContext() {
  const context = useContext(VehiclesDrawerContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useVehiclesDrawerContext must be used within a VehiclesDrawerContextProvider',
    );
  }
  return context;
}

export default VehiclesDrawerContextProvider;
