import styled from 'styled-components';

interface ContainerProps {
  width?: string;
}

interface RowProps {
  textColor?: string;
}

export const Container = styled.div<ContainerProps>`
  height: fit-content;
  min-width: ${(props) => (props.width != null ? props.width : '240px')};
  border-radius: 4px;
  background-color: white;
  position: absolute;
  top: 50%;
  color: black;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  &.right {
    right: calc(100% + 7px);
  }
  &.left {
    left: calc(100% + 7px);
  }
`;
export const RowDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  cursor: pointer;
`;

export const Row = styled.p<RowProps>`
  font-size: 14px;
  color: ${(props) => props.textColor};
`;
