import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
  jsonb: any;
};

/** Relatório diário de telemetria */
export type AfterUsage = {
  __typename?: 'AfterUsage';
  date: Scalars['DateTime'];
  distanceTraveled: Scalars['Float'];
  distanceTraveledAfter: Scalars['Float'];
  distanceTraveledBefore: Scalars['Float'];
  distanceTraveledBetween: Scalars['Float'];
  timeWithIgnitionOn: Scalars['Int'];
  timeWithIgnitionOnAfter: Scalars['Int'];
  timeWithIgnitionOnBefore: Scalars['Int'];
  timeWithIgnitionOnBetween: Scalars['Int'];
  vehicle: Vehicle;
  vehicleId: Scalars['ID'];
};

/** Aplicações */
export type App = {
  __typename?: 'App';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizations: Array<Organization>;
  slugName: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

/** Modulos existentes na aplicações */
export type AppModule = {
  __typename?: 'AppModule';
  name: Scalars['String'];
  slugName: Scalars['String'];
};

export type Authorization = {
  __typename?: 'Authorization';
  accessToken: Scalars['String'];
  refreshToken?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  user: User;
};

/** Service Category */
export type Category = {
  __typename?: 'Category';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Name */
  name: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export type ChangeUserVehiclesInput = {
  attach?: InputMaybe<Array<Scalars['ID']>>;
  detach?: InputMaybe<Array<Scalars['ID']>>;
  user: Scalars['ID'];
};

export type ChangeVehicleDriverInput = {
  driver: Scalars['ID'];
  organization: Scalars['ID'];
  vehicle: Scalars['ID'];
};

export type ChangeVehicleMeasureInput = {
  measure: Scalars['Float'];
  measuredAt: Scalars['DateTime'];
  measurement: MeasurementTypeEnum;
  vehicleId?: InputMaybe<Scalars['ID']>;
};

export type ChecklistAnswer = {
  __typename?: 'ChecklistAnswer';
  /** resposta dada a um item */
  answered: Scalars['String'];
  /** Comentario da resposta */
  comment?: Maybe<ChecklistComment>;
  /** id da Answer */
  id: Scalars['ID'];
  /** Item id respondido */
  itemId: Scalars['ID'];
  /** Foto da resposta */
  photo?: Maybe<ChecklistPhoto>;
  /** Submission id */
  submissionId: Scalars['ID'];
  /** Se a answer foi bem sucedida */
  success: Scalars['Boolean'];
};

export type ChecklistChoice = {
  __typename?: 'ChecklistChoice';
  /** When true: if its choose open an issue */
  fail: Scalars['Boolean'];
  id: Scalars['ID'];
  /** The item this choice belongs to */
  itemId: Scalars['ID'];
  /** The frontend name of the choice */
  label: Scalars['String'];
  /** The item this choice belongs to */
  organizationId: Scalars['ID'];
  /** The frontend position */
  position: Scalars['Int'];
};

export type ChecklistComment = {
  __typename?: 'ChecklistComment';
  /** Id da resposta a que se refere */
  answerId: Scalars['ID'];
  /** Conteudo do comentario */
  content: Scalars['String'];
  id: Scalars['ID'];
};

export type ChecklistForm = {
  __typename?: 'ChecklistForm';
  id: Scalars['ID'];
  /** List of items from this form */
  items: Array<ChecklistItem>;
  /** The organization this form belongs to */
  organizationId: Scalars['String'];
  title: Scalars['String'];
};

export type ChecklistFormPaginate = {
  __typename?: 'ChecklistFormPaginate';
  /** Formulários */
  checklistForms: Array<ChecklistForm>;
  /** Página atual */
  pageNumber: Scalars['Int'];
  /** Tamanho da página */
  pageSize: Scalars['Int'];
  /** Total de registros */
  total: Scalars['Int'];
  /** Total de páginas */
  totalPages: Scalars['Int'];
};

export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  /** List of choices from this item */
  choices?: Maybe<Array<ChecklistChoice>>;
  /** The criticity of item being created */
  criticity?: Maybe<ChecklistItemCriticity>;
  /** Description from this item */
  description?: Maybe<Scalars['String']>;
  /** If fail between determined range */
  failBetweenRange?: Maybe<Scalars['Boolean']>;
  /** If fail between beyond range */
  failBeyondRange?: Maybe<Scalars['Boolean']>;
  /** The form this item belongs to */
  formId: Scalars['ID'];
  id: Scalars['ID'];
  /** Instructions on how to answer */
  instructions?: Maybe<Scalars['String']>;
  /** Maximum set to this range */
  maxRange?: Maybe<Scalars['Float']>;
  /** Minimum set to this range */
  minRange?: Maybe<Scalars['Float']>;
  /** Form is from Organization id */
  organizationId: Scalars['ID'];
  /** The frontend position */
  position: Scalars['Int'];
  /** If item requires an answer, else its optional */
  required: Scalars['Boolean'];
  /** Instructions on how to answer */
  title: Scalars['String'];
  /** The type of item being created */
  type: ChecklistItemType;
  /** especificação de um type */
  variant: Scalars['Int'];
  /** Requerir comentário se resposta falhar */
  whenFailRequireComment?: Maybe<Scalars['Boolean']>;
  /** Requerir foto se resposta falhar */
  whenFailRequirePhoto?: Maybe<Scalars['Boolean']>;
  /** Requerir comentário se resposta passar */
  whenSuccessRequireComment?: Maybe<Scalars['Boolean']>;
  /** Requerir foto se resposta passar */
  whenSuccessRequirePhoto?: Maybe<Scalars['Boolean']>;
};

export enum ChecklistItemCriticity {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
}

export enum ChecklistItemType {
  Datetime = 'datetime',
  Enum = 'enum',
  Measure = 'measure',
  Range = 'range',
  Signature = 'signature',
  Text = 'text',
}

export type ChecklistPhoto = {
  __typename?: 'ChecklistPhoto';
  /** Id da resposta a que se refere */
  answerId: Scalars['ID'];
  id: Scalars['ID'];
  /** Id do storage da foto */
  storageId: Scalars['String'];
};

export type ChecklistSubmission = {
  __typename?: 'ChecklistSubmission';
  /** List of Answers from this submission */
  answers: Array<ChecklistAnswer>;
  /** Answered form id */
  formId: Scalars['ID'];
  /** Submission Id */
  id: Scalars['ID'];
  /** Form is from Organization id */
  organizationId: Scalars['ID'];
  /** The number of Submission */
  protocol: Scalars['Int'];
  /** Date the user starts the form */
  startedAt: Scalars['DateTime'];
  /** Date the user submitted the form */
  submittedAt: Scalars['DateTime'];
  /** Id of the user who answered the form */
  submittedById: Scalars['ID'];
  /** Se a submissão foi bem sucedida */
  success: Scalars['Boolean'];
  /** Vehicle id that the user selected for the form */
  vehicleId: Scalars['ID'];
};

export type ChecklistSubmissionFiltersInput = {
  /** Data de final de pesquisa */
  endAt?: InputMaybe<Scalars['DateTime']>;
  /** Issues filtering */
  issues?: InputMaybe<ChecklistSubmissionFiltersIssuesInput>;
  /** Data de inicial de pesquisa */
  startAt?: InputMaybe<Scalars['DateTime']>;
  /** Id of the user who answered the form */
  submittedBy?: InputMaybe<Scalars['ID']>;
  /** The status of success to filter */
  success?: InputMaybe<Scalars['Boolean']>;
  /** Vehicle id that the user selected for the form */
  vehicles?: InputMaybe<Array<Scalars['ID']>>;
};

export type ChecklistSubmissionFiltersIssuesInput = {
  /** The criticity of items of issues from Submissions */
  criticity: ChecklistItemCriticity;
};

export type ChecklistSubmissionPaginate = {
  __typename?: 'ChecklistSubmissionPaginate';
  /** Formulários */
  checklistSubmissions: Array<ChecklistSubmission>;
  /** Página atual */
  pageNumber: Scalars['Int'];
  /** Tamanho da página */
  pageSize: Scalars['Int'];
  /** Total de registros */
  total: Scalars['Int'];
  /** Total de páginas */
  totalPages: Scalars['Int'];
};

/** Chip */
export type Chip = {
  __typename?: 'Chip';
  broker: Scalars['String'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  device?: Maybe<Device>;
  deviceId?: Maybe<Scalars['ID']>;
  enabledAt?: Maybe<Scalars['DateTime']>;
  iccid: Scalars['String'];
  id: Scalars['ID'];
  msisdn: Scalars['String'];
  telecomOperator: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export enum CostTypeEnum {
  Discount = 'discount',
  Extra = 'extra',
  Labor = 'labor',
  Tax = 'tax',
}

export type CreateChecklistAnswerWithSubmissionInput = {
  /** Answer that the user gave to the item */
  answered: Scalars['String'];
  /** Comentario da resposta */
  comment?: InputMaybe<CreateChecklistCommentWithAnswerInput>;
  /** Answered item id */
  itemId: Scalars['ID'];
  /** Foto da resposta */
  photo?: InputMaybe<CreateChecklistPhotoWithAnswerInput>;
};

export type CreateChecklistChoiceInput = {
  /** When true: if its choose open an issue */
  fail?: InputMaybe<Scalars['Boolean']>;
  /** The item this choice belongs to */
  itemId: Scalars['ID'];
  /** The frontend name of the choice */
  label: Scalars['String'];
  /** The frontend position */
  position: Scalars['Int'];
};

export type CreateChecklistChoiceWithItemInput = {
  /** When true: if its choose open an issue */
  fail?: InputMaybe<Scalars['Boolean']>;
  /** The frontend name of the choice */
  label: Scalars['String'];
};

export type CreateChecklistCommentWithAnswerInput = {
  /** Conteudo do comentario */
  content: Scalars['String'];
};

export type CreateChecklistFormInput = {
  /** List of items from this form */
  items?: InputMaybe<Array<CreateChecklistItemWithFormInput>>;
  /** Form title */
  title: Scalars['String'];
};

export type CreateChecklistItemInput = {
  /** List of choices from this item */
  choices?: InputMaybe<Array<CreateChecklistChoiceWithItemInput>>;
  /** The criticity of item being created */
  criticity?: InputMaybe<ChecklistItemCriticity>;
  /** Description from this item */
  description?: InputMaybe<Scalars['String']>;
  /** If fail between determined range */
  failBetweenRange?: InputMaybe<Scalars['Boolean']>;
  /** If fail between beyond range */
  failBeyondRange?: InputMaybe<Scalars['Boolean']>;
  /** The form this item belongs to */
  formId: Scalars['ID'];
  /** Instructions on how to answer */
  instructions?: InputMaybe<Scalars['String']>;
  /** Maximum set to this range */
  maxRange?: InputMaybe<Scalars['Float']>;
  /** Minimum set to this range */
  minRange?: InputMaybe<Scalars['Float']>;
  /** The frontend position */
  position: Scalars['Int'];
  /** If item requires an answer, else its optional */
  required: Scalars['Boolean'];
  /** Instructions on how to answer */
  title: Scalars['String'];
  /** The type of item being created */
  type: ChecklistItemType;
  /** especificação de um type */
  variant?: InputMaybe<Scalars['Int']>;
  /** Requerir comentário se resposta falhar */
  whenFailRequireComment?: InputMaybe<Scalars['Boolean']>;
  /** Requerir foto se resposta falhar */
  whenFailRequirePhoto?: InputMaybe<Scalars['Boolean']>;
  /** Requerir comentário se resposta passar */
  whenSuccessRequireComment?: InputMaybe<Scalars['Boolean']>;
  /** Requerir foto se resposta passar */
  whenSuccessRequirePhoto?: InputMaybe<Scalars['Boolean']>;
};

export type CreateChecklistItemWithFormInput = {
  /** List of choices from this item */
  choices?: InputMaybe<Array<CreateChecklistChoiceWithItemInput>>;
  /** The criticity of item being created */
  criticity?: InputMaybe<ChecklistItemCriticity>;
  /** Description from this item */
  description?: InputMaybe<Scalars['String']>;
  /** If fail between determined range */
  failBetweenRange?: InputMaybe<Scalars['Boolean']>;
  /** If fail between beyond range */
  failBeyondRange?: InputMaybe<Scalars['Boolean']>;
  /** Instructions on how to answer */
  instructions?: InputMaybe<Scalars['String']>;
  /** Maximum set to this range */
  maxRange?: InputMaybe<Scalars['Float']>;
  /** Minimum set to this range */
  minRange?: InputMaybe<Scalars['Float']>;
  /** If item requires an answer, else its optional */
  required: Scalars['Boolean'];
  /** Instructions on how to answer */
  title: Scalars['String'];
  /** The type of item being created */
  type: ChecklistItemType;
  /** especificação de um type */
  variant?: InputMaybe<Scalars['Int']>;
  /** Requerir comentário se resposta falhar */
  whenFailRequireComment?: InputMaybe<Scalars['Boolean']>;
  /** Requerir foto se resposta falhar */
  whenFailRequirePhoto?: InputMaybe<Scalars['Boolean']>;
  /** Requerir comentário se resposta passar */
  whenSuccessRequireComment?: InputMaybe<Scalars['Boolean']>;
  /** Requerir foto se resposta passar */
  whenSuccessRequirePhoto?: InputMaybe<Scalars['Boolean']>;
};

export type CreateChecklistPhotoWithAnswerInput = {
  /** Id do storage da foto */
  storageId: Scalars['ID'];
};

export type CreateChecklistSubmissionInput = {
  /** List of Answers from this submission */
  answers: Array<CreateChecklistAnswerWithSubmissionInput>;
  /** Answered form id */
  formId: Scalars['ID'];
  /** Date the user starts the form */
  startedAt: Scalars['DateTime'];
  /** Date the user submitted the form */
  submittedAt: Scalars['DateTime'];
  /** Id of the user who answered the form */
  submittedById: Scalars['ID'];
  /** Vehicle id that the user selected for the form */
  vehicleId: Scalars['ID'];
};

export type CreateChipInput = {
  broker: Scalars['String'];
  deviceId?: InputMaybe<Scalars['ID']>;
  enabledAt?: InputMaybe<Scalars['DateTime']>;
  iccid: Scalars['String'];
  msisdn: Scalars['String'];
  telecomOperator: Scalars['String'];
};

export type CreateDeviceInput = {
  chipId?: InputMaybe<Scalars['ID']>;
  installedAt?: InputMaybe<Scalars['DateTime']>;
  /** Modelo do dispositivo */
  model?: InputMaybe<Scalars['String']>;
  /** MSISDN do Chip */
  msisdn?: InputMaybe<Scalars['String']>;
  observation?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  technicianId?: InputMaybe<Scalars['ID']>;
  tenure?: InputMaybe<Scalars['String']>;
  uninstalledAt?: InputMaybe<Scalars['DateTime']>;
  /** IMEI do dispositivo */
  uniqueId: Scalars['String'];
  vehicleId?: InputMaybe<Scalars['ID']>;
};

export type CreateDriverInput = {
  /** Data de nascimento */
  birthday?: InputMaybe<Scalars['DateTime']>;
  canLogin?: InputMaybe<Scalars['Boolean']>;
  cnh?: InputMaybe<Scalars['String']>;
  cnhCategory?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  password?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type CreateDriverTokenInput = {
  sku: Scalars['String'];
  token: Scalars['String'];
  type: Scalars['String'];
};

export type CreateFeatureFlagInput = {
  enabled: Scalars['Boolean'];
  /** Código da feature */
  feature: Scalars['String'];
  organization?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type CreateFuellingInput = {
  /** Quantidade de combustível abastecido em L */
  amount: Scalars['Float'];
  /** Custo em R$ do abastecimento */
  cost?: InputMaybe<Scalars['Float']>;
  /** Campo livre para inserção de observações */
  description?: InputMaybe<Scalars['String']>;
  driver?: InputMaybe<Scalars['ID']>;
  /** Encheu o tanque ou não? */
  filledUp?: InputMaybe<Scalars['Boolean']>;
  /** Tipo do combustível: diesel, gas... */
  fuelType: Scalars['String'];
  /** Horimetro no momento do abastecimento */
  horimeter?: InputMaybe<Scalars['Float']>;
  invoiceEvidence?: InputMaybe<Scalars['ID']>;
  /** Abastecido no posto interno ou não */
  isInternal: Scalars['Boolean'];
  /** Data que o abastecimento foi realizado */
  occurredAt: Scalars['DateTime'];
  /** Odômetro no momento do abastecimento */
  odometer?: InputMaybe<Scalars['Float']>;
  odometerEvidence?: InputMaybe<Scalars['ID']>;
  provider?: InputMaybe<Scalars['ID']>;
  tank?: InputMaybe<Scalars['ID']>;
  vehicle: Scalars['ID'];
};

export type CreateGeofenceInput = {
  /** Geometria da cerca em GeoJSON */
  area: Scalars['jsonb'];
  /** Código hexiadecimal da cor #HEX */
  color: Scalars['String'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
  parking?: InputMaybe<Scalars['Boolean']>;
  /** Tipo da cerca: poligon e circle */
  type: GeofenceTypeEnum;
};

export type CreateGroupInput = {
  /** Nome */
  name: Scalars['String'];
  /** Organização */
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Veiculos */
  vehicles: Array<Scalars['ID']>;
};

export type CreateIntegrationInput = {
  credentials?: InputMaybe<Scalars['jsonb']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  source: Scalars['String'];
};

export type CreateMaintenanceParteInput = {
  /** Nome da peca */
  name: Scalars['String'];
  organization: Scalars['ID'];
  standard: Scalars['Boolean'];
};

export type CreateMaintenanceReminderInput = {
  /** Frequência do lembrete por medida */
  frequencyMeasure?: InputMaybe<Scalars['Float']>;
  /** Frequência do lembrete por data */
  frequencyTime?: InputMaybe<Scalars['Int']>;
  /** Medição na última manuteção */
  lastMeasure: Scalars['Float'];
  /** Data da última manuteção */
  lastOccurrence: Scalars['DateTime'];
  /** Serviço */
  service: Scalars['ID'];
  /** Aviso com antecedência por medida */
  thresholdMeasure?: InputMaybe<Scalars['Float']>;
  /** Aviso com antecedência por data */
  thresholdTime?: InputMaybe<Scalars['Int']>;
  /** Veículo */
  vehicle: Scalars['ID'];
};

export type CreateMaintenanceServiceInput = {
  category?: InputMaybe<Scalars['ID']>;
  /** Nome do serviço */
  name: Scalars['String'];
  organization?: InputMaybe<Scalars['ID']>;
  standard?: InputMaybe<Scalars['Boolean']>;
};

export type CreateMaintenanceTaskInput = {
  /** Data que o serviço foi finalizado */
  completedAt?: InputMaybe<Scalars['DateTime']>;
  /** Descricao do servico */
  description?: InputMaybe<Scalars['String']>;
  driver?: InputMaybe<Scalars['ID']>;
  evidence?: InputMaybe<Scalars['ID']>;
  /** Valor do horimeter */
  horimeter?: InputMaybe<Scalars['Float']>;
  /** Valor do odometro */
  odometer?: InputMaybe<Scalars['Float']>;
  organization?: InputMaybe<Scalars['ID']>;
  /** pecas */
  parts: Array<ReferenceTaskPartInput>;
  /** precos */
  prices: Array<ReferenceTaskCostInput>;
  provider?: InputMaybe<Scalars['ID']>;
  service?: InputMaybe<Scalars['ID']>;
  /** Data que o serviço começou */
  startedAt?: InputMaybe<Scalars['DateTime']>;
  type: TaskTypeEnum;
  vehicle: Scalars['ID'];
};

export type CreateOpsServiceInput = {
  category: OpsServiceCategory;
  deviceId?: InputMaybe<Scalars['ID']>;
  deviceTenure?: InputMaybe<Scalars['String']>;
  isTaxable: Scalars['Boolean'];
  isTrial: Scalars['Boolean'];
  occurredAt: Scalars['DateTime'];
  organizationId: Scalars['ID'];
  shouldDisableVehicle?: InputMaybe<Scalars['Boolean']>;
  technicianId: Scalars['ID'];
  useCameraData?: InputMaybe<Scalars['Boolean']>;
  vehicleId: Scalars['ID'];
};

export type CreateOrderInput = {
  /** Custo em R$ da ordem */
  amount?: InputMaybe<Scalars['Float']>;
  /** Numero da Nota */
  invoiceNumber: Scalars['Float'];
  /** Data da Ordem */
  occurredAt: Scalars['DateTime'];
  organization: Scalars['ID'];
  /** Origem da Ordem */
  source: Scalars['String'];
  tank: Scalars['ID'];
  /** Valor em R$ da ordem */
  value?: InputMaybe<Scalars['Float']>;
};

export type CreateOrganizationInput = {
  address?: InputMaybe<Scalars['String']>;
  apps: Array<Scalars['ID']>;
  capacity?: InputMaybe<OrganizationCapacity>;
  category?: InputMaybe<Scalars['String']>;
  cnpj: Scalars['String'];
  contractBeganAt?: InputMaybe<Scalars['DateTime']>;
  contractEndedAt?: InputMaybe<Scalars['DateTime']>;
  contractMonthDuration?: InputMaybe<Scalars['Float']>;
  disabledAt?: InputMaybe<Scalars['DateTime']>;
  monthlyPayment?: InputMaybe<Scalars['Float']>;
  /** Razão Social */
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  primaryOrganization?: InputMaybe<Scalars['ID']>;
  sector?: InputMaybe<Scalars['String']>;
  signUpFee?: InputMaybe<Scalars['Float']>;
  trialAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateProviderInput = {
  /** CNPJ do fornecedor / oficina */
  cnpj?: InputMaybe<Scalars['String']>;
  /** Nome do fornecedor ou oficina */
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type CreateShippingInput = {
  city: Scalars['String'];
  devices?: InputMaybe<Array<Scalars['ID']>>;
  devolution: Scalars['Boolean'];
  occurredAt: Scalars['DateTime'];
  organizationId?: InputMaybe<Scalars['ID']>;
  state: Scalars['String'];
  technicianId?: InputMaybe<Scalars['ID']>;
};

export type CreateTankInput = {
  /** Capacidade máxima do tanque */
  capacity?: InputMaybe<Scalars['Float']>;
  /** Campo livre para inserção de observações */
  description?: InputMaybe<Scalars['String']>;
  /** Volume inicial do tanque */
  initialVolume?: InputMaybe<Scalars['Float']>;
  /** Nome do tanque de combustível */
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type CreateTechnicianInput = {
  additionalFee?: InputMaybe<Scalars['Float']>;
  address?: InputMaybe<Scalars['String']>;
  bankAccount?: InputMaybe<Scalars['String']>;
  bankAccountDigit?: InputMaybe<Scalars['String']>;
  bankAgency?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  bankOwnerDocumentNumber?: InputMaybe<Scalars['String']>;
  bankOwnerName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  contractSignedAt?: InputMaybe<Scalars['DateTime']>;
  document?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  installationPrice?: InputMaybe<Scalars['Float']>;
  maintenancePrice?: InputMaybe<Scalars['Float']>;
  movementPrice?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  observation?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  pix?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  uninstallationPrice?: InputMaybe<Scalars['Float']>;
};

export type CreateTrafficInfractionInput = {
  /** Endereço */
  address?: InputMaybe<Scalars['String']>;
  /** Custo */
  cost?: InputMaybe<Scalars['Float']>;
  driver?: InputMaybe<Scalars['ID']>;
  /** Data de vencimento */
  dueDate?: InputMaybe<Scalars['DateTime']>;
  infraction: Scalars['ID'];
  /** Localização */
  location?: InputMaybe<TrafficInfractionLocationInput>;
  /** Observação */
  observation?: InputMaybe<Scalars['String']>;
  /** Data da multa */
  occurredAt: Scalars['DateTime'];
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Pago em... */
  paidAt?: InputMaybe<Scalars['DateTime']>;
  /** Custo Pago */
  paidCost?: InputMaybe<Scalars['Float']>;
  /** Documento de referência */
  referenceDocument?: InputMaybe<Scalars['String']>;
  vehicle: Scalars['ID'];
};

export type CreateUserInput = {
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  modules: Array<Scalars['String']>;
  name: Scalars['String'];
  /** primary user organization */
  organization?: InputMaybe<Scalars['ID']>;
  organizations?: InputMaybe<Array<Scalars['ID']>>;
  password: Scalars['String'];
  role: UserRole;
};

export type CreateVehicleInput = {
  /** Autonomia do veículo */
  autonomy?: InputMaybe<Scalars['Float']>;
  avatarType?: InputMaybe<VehicleTypeEnum>;
  /** Saturação da bateria */
  basePower?: InputMaybe<Scalars['Float']>;
  /** Marca, ex: GM, Nissan, Toyota... */
  brand?: InputMaybe<Scalars['String']>;
  /** Veículo desabilitado */
  disabledAt?: InputMaybe<Scalars['DateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  /** Documento */
  document?: InputMaybe<Scalars['String']>;
  /** Tipo do combustível: diesel, gas etc. */
  fuelType?: InputMaybe<FuelTypeEnum>;
  lastDriverToken?: InputMaybe<Scalars['String']>;
  /** Velocidade máxima */
  maxSpeed?: InputMaybe<Scalars['Float']>;
  /** Volume máximo */
  maximumVolume?: InputMaybe<Scalars['Int']>;
  /** Carga máxima */
  maximumWeight?: InputMaybe<Scalars['Int']>;
  /** Velocidade mínima para ser considerado como parado */
  minSpeed?: InputMaybe<Scalars['Float']>;
  /** Duração mínima do estacionamento */
  minimalParkingDuration?: InputMaybe<Scalars['Float']>;
  /** Modelo, ex: Fiesta, March, C3... */
  model?: InputMaybe<Scalars['String']>;
  /** Pagamento Recorrente Mensal */
  monthlyPayment?: InputMaybe<Scalars['Float']>;
  /** Observação */
  observation?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  /** Identificação */
  plate: Scalars['String'];
  /** Preço */
  price?: InputMaybe<Scalars['Float']>;
  products?: InputMaybe<Array<Scalars['String']>>;
  /** Taxa de Adesão */
  signUpFee?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  /** Data de início da cobrança */
  taxabledAt?: InputMaybe<Scalars['DateTime']>;
  type: Scalars['String'];
  useCameraData?: InputMaybe<Scalars['Boolean']>;
  useObdData?: InputMaybe<Scalars['Boolean']>;
  /** Ano do veículo */
  year?: InputMaybe<Scalars['String']>;
};

export type CreateWebhookInput = {
  /** POST URL para onde os dados serão enviados */
  endpoint: Scalars['String'];
  headers: Scalars['jsonb'];
  sendEvents: Scalars['Boolean'];
  sendPositions: Scalars['Boolean'];
};

/** Medição diaria com base na telemetria */
export type DailyWorkMeasure = {
  __typename?: 'DailyWorkMeasure';
  /** Horário inicial para contabilizar o horimetro */
  beginMeasureHorimeterAt: Scalars['DateTime'];
  /** Horário inicial para contabilizar o odometro */
  beginMeasureOdometerAt: Scalars['DateTime'];
  changedByUser?: Maybe<User>;
  /** Usuário que mudou a medida */
  changedByUserId?: Maybe<Scalars['ID']>;
  date: Scalars['DateTime'];
  distanceTraveled?: Maybe<Scalars['Float']>;
  finalHorimeter?: Maybe<Scalars['Int']>;
  finalOdometer?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  initialHorimeter: Scalars['Int'];
  initialOdometer: Scalars['Float'];
  timeWithIgnitionOn?: Maybe<Scalars['Int']>;
  vehicle: Vehicle;
  vehicleId: Scalars['ID'];
};

export type DeleteChecklistFormInput = {
  id: Scalars['String'];
};

/** Dispositivo */
export type Device = {
  __typename?: 'Device';
  chip?: Maybe<Chip>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  installedAt?: Maybe<Scalars['DateTime']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  model?: Maybe<Scalars['String']>;
  observation?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  technician?: Maybe<Technician>;
  technicianId?: Maybe<Scalars['ID']>;
  tenure?: Maybe<Scalars['String']>;
  uninstalledAt?: Maybe<Scalars['DateTime']>;
  uniqueId: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle?: Maybe<Vehicle>;
  vehicleId?: Maybe<Scalars['ID']>;
};

export type DevicePaginate = {
  __typename?: 'DevicePaginate';
  /** Dispositivos */
  devices: Array<Device>;
  /** Total de registros */
  total: Scalars['Int'];
};

/** Arquivo armazenado */
export type DocumentFile = {
  __typename?: 'DocumentFile';
  category: Scalars['String'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  organization: Organization;
  /** @deprecated Should use organization.id instead */
  organizationId: Scalars['ID'];
  size: Scalars['Float'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Url para acesso ao arquivo */
  url: Scalars['String'];
};

/** Motorista */
export type Driver = {
  __typename?: 'Driver';
  /** Status do Motorista */
  active?: Maybe<Scalars['Boolean']>;
  /** Data de nascimento */
  birthday: Scalars['DateTime'];
  cnh?: Maybe<Scalars['String']>;
  cnhCategory?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que o motorista foi deletado */
  deletedAt?: Maybe<Scalars['DateTime']>;
  driverToken?: Maybe<DriverToken>;
  /** @deprecated Não será mais utilizado nas futuras versões */
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organization: Organization;
  /** @deprecated Não será mais utilizado nas futuras versões */
  source?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  vehicle?: Maybe<Vehicle>;
};

/** Mapeamento de motorista */
export type DriverMapping = {
  __typename?: 'DriverMapping';
  attributes: Scalars['jsonb'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  driver?: Maybe<Driver>;
  /** @deprecated Should use driver.id instead */
  driverId?: Maybe<Scalars['ID']>;
  externalId: Scalars['String'];
  id: Scalars['ID'];
  integration: Integration;
  /** @deprecated Should use integration.id instead */
  integrationId: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  /** @deprecated Should use organization.id instead */
  organizationId: Scalars['ID'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};

export type DriverProfiles = {
  __typename?: 'DriverProfiles';
  averageSpeed: Scalars['Float'];
  deviceOverSpeedGrade: Scalars['Float'];
  distanceDeviceOverSpeed: Scalars['Float'];
  distanceTraveled: Scalars['Float'];
  driverEvents: Scalars['jsonb'];
  driverId: Scalars['ID'];
  eventsPerKm: Scalars['Float'];
  generalGrade: Scalars['Float'];
  hardAccelerationGrade: Scalars['Float'];
  hardBrakingGrade: Scalars['Float'];
  hardCorneringGrade: Scalars['Float'];
  idleGrade: Scalars['Float'];
  timeDeviceOverSpeed: Scalars['Float'];
  timeStoppedWithIgnitionOn: Scalars['Float'];
  timeWithIgnitionOn: Scalars['Float'];
};

export type DriverRanking = {
  __typename?: 'DriverRanking';
  avgSpeed: Scalars['Float'];
  driverId: Scalars['ID'];
  maxSpeed: Scalars['Float'];
  telemetrySummary: Scalars['jsonb'];
  totalTelemetryEvents: Scalars['Int'];
};

export type DriverToken = {
  __typename?: 'DriverToken';
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']>;
  fullToken: Scalars['String'];
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']>;
  sku: Scalars['String'];
  token: Scalars['String'];
  type: Scalars['String'];
  vehicle?: Maybe<Vehicle>;
  vehicleId?: Maybe<Scalars['ID']>;
};

export type DriverTokenExistsResponse = {
  __typename?: 'DriverTokenExistsResponse';
  exists: Scalars['Boolean'];
  token: Scalars['String'];
};

export type DriverTokenPaginate = {
  __typename?: 'DriverTokenPaginate';
  /** Identificadores */
  driverTokens: Array<DriverToken>;
  /** Página atual */
  pageNumber: Scalars['Int'];
  /** Tamanho da página */
  pageSize: Scalars['Int'];
  /** Total de registros */
  total: Scalars['Int'];
  /** Total de páginas */
  totalPages: Scalars['Int'];
};

/** Eventos do veículo */
export type Event = {
  __typename?: 'Event';
  address?: Maybe<Scalars['String']>;
  attributes: Scalars['jsonb'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']>;
  geofence?: Maybe<Geofence>;
  id: Scalars['ID'];
  isAlarm: Scalars['Boolean'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  mediaUrl?: Maybe<Scalars['String']>;
  /** @deprecated Não teremos mais o evento relacionado a organização apenas ao veículo */
  organization?: Maybe<Organization>;
  origin: Scalars['String'];
  /** Data de ocorrência do evento */
  reportedAt: Scalars['DateTime'];
  slugName: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
  /** Histórico da troca do motorista */
  vehicleDriverChangeHistory?: Maybe<VehicleDriverChangeHistory>;
  vehicleId: Scalars['ID'];
};

export type EventOccurrences = {
  __typename?: 'EventOccurrences';
  boardActivated: Scalars['Int'];
  boardDeactivated: Scalars['Int'];
  cockpitDoorClose: Scalars['Int'];
  cockpitDoorOpen: Scalars['Int'];
  deviceMoving: Scalars['Int'];
  deviceOverspeed: Scalars['Int'];
  deviceOverweight: Scalars['Int'];
  deviceStopped: Scalars['Int'];
  driverChange: Scalars['Int'];
  fault: Scalars['Int'];
  geofenceEnter: Scalars['Int'];
  geofenceExit: Scalars['Int'];
  hardAcceleration: Scalars['Int'];
  hardBraking: Scalars['Int'];
  hardCornering: Scalars['Int'];
  ignitionOff: Scalars['Int'];
  ignitionOn: Scalars['Int'];
  plugged: Scalars['Int'];
  routeOverspeed: Scalars['Int'];
  trailerOpen: Scalars['Int'];
  unplugged: Scalars['Int'];
};

/** Resumo dos Eventos */
export type EventSummary = {
  __typename?: 'EventSummary';
  occurrences: Scalars['Int'];
  slugName: Scalars['String'];
};

/** Funcionalidades que estão liberadas para o cliente */
export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que a feature foi bloqueada */
  disabledAt?: Maybe<Scalars['DateTime']>;
  /** Data que a feature foi liberada */
  enabledAt?: Maybe<Scalars['DateTime']>;
  /** Código da feature */
  feature: Scalars['String'];
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export type FilterOrder = {
  amountMax?: InputMaybe<Scalars['Float']>;
  amountMin?: InputMaybe<Scalars['Float']>;
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  tanks: Array<Scalars['ID']>;
  valueMax?: InputMaybe<Scalars['Float']>;
  valueMin?: InputMaybe<Scalars['Float']>;
};

export type FilterPartInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type FilterServiceInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type FilterTaskInput = {
  categories?: InputMaybe<Array<Scalars['ID']>>;
  drivers?: InputMaybe<Array<Scalars['ID']>>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  provider?: InputMaybe<Array<Scalars['ID']>>;
  services?: InputMaybe<Array<Scalars['ID']>>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<TaskTypeEnum>;
  vehicles?: InputMaybe<Array<Scalars['ID']>>;
};

export type FindInfractionInput = {
  /** Código */
  code?: InputMaybe<Scalars['String']>;
  /** Valor em R$ da infração */
  cost?: InputMaybe<Scalars['Float']>;
  /** Descrição */
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** Pontos */
  points?: InputMaybe<Scalars['Float']>;
};

/** Tipos de combustíveis */
export type FuelType = {
  __typename?: 'FuelType';
  description: Scalars['String'];
  slugName: Scalars['String'];
};

export enum FuelTypeEnum {
  AdditivesGasoline = 'additives_gasoline',
  Arla_32 = 'arla_32',
  Diesel = 'diesel',
  DieselS10 = 'diesel_s10',
  Ethanol = 'ethanol',
  Gasoline = 'gasoline',
  Gnv = 'gnv',
  Kerosene = 'kerosene',
  Other = 'other',
}

/** Mapeamento dos tipos de combustível */
export type FuelTypeMapping = {
  __typename?: 'FuelTypeMapping';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  fuelType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integration: Integration;
  /** @deprecated Should use integration.id instead */
  integrationId: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  /** @deprecated Should use organization.id instead */
  organizationId: Scalars['ID'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};

/** Abastecimento */
export type Fuelling = {
  __typename?: 'Fuelling';
  /** Quantidade de combustível abastecido em L */
  amount: Scalars['Float'];
  attributes: Scalars['jsonb'];
  /** Custo em R$ do abastecimento */
  cost?: Maybe<Scalars['Float']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  /** Campo livre para inserção de observações */
  description?: Maybe<Scalars['String']>;
  driver?: Maybe<Driver>;
  externalId?: Maybe<Scalars['String']>;
  /** Tipo do combustível: diesel, gas etc. */
  fuelType: Scalars['String'];
  /** Horímetro no momento do abastecimento */
  horimeter?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoiceEvidence?: Maybe<DocumentFile>;
  /** Abastecido no posto interno ou não */
  isInternal: Scalars['Boolean'];
  /** Data que o abastecimento foi realizado */
  occurredAt: Scalars['DateTime'];
  /** Odômetro no momento do abastecimento */
  odometer?: Maybe<Scalars['Float']>;
  odometerEvidence?: Maybe<DocumentFile>;
  /** @deprecated Não será mais utilizado nas futuras versões */
  organization?: Maybe<Organization>;
  provider?: Maybe<Provider>;
  source: Scalars['String'];
  tank?: Maybe<Tank>;
  tankTransaction?: Maybe<TankTransaction>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

/** Referência de abastecimento */
export type FuellingReference = {
  __typename?: 'FuellingReference';
  /** Valor máximo aceitável para horimetro */
  maxHorimeter?: Maybe<Scalars['Float']>;
  /** Valor máximo aceitável para odometro */
  maxOdometer?: Maybe<Scalars['Float']>;
  /** Valor mínimo aceitável para horimetro */
  minHorimeter: Scalars['Float'];
  /** Valor mínimo aceitável para odometro */
  minOdometer: Scalars['Float'];
};

export type FuellingWithComputedProperties = {
  __typename?: 'FuellingWithComputedProperties';
  /** Quantidade de combustível abastecido em L */
  amount: Scalars['Float'];
  attributes: Scalars['jsonb'];
  /** Custo em R$ do abastecimento */
  cost?: Maybe<Scalars['Float']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  /** Campo livre para inserção de observações */
  description?: Maybe<Scalars['String']>;
  driver?: Maybe<Driver>;
  efficiency?: Maybe<Scalars['Float']>;
  externalId?: Maybe<Scalars['String']>;
  /** Tipo do combustível: diesel, gas etc. */
  fuelType: Scalars['String'];
  /** Horímetro no momento do abastecimento */
  horimeter?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoiceEvidence?: Maybe<DocumentFile>;
  /** Abastecido no posto interno ou não */
  isInternal: Scalars['Boolean'];
  measureCalculated?: Maybe<Scalars['Float']>;
  /** Data que o abastecimento foi realizado */
  occurredAt: Scalars['DateTime'];
  /** Odômetro no momento do abastecimento */
  odometer?: Maybe<Scalars['Float']>;
  odometerEvidence?: Maybe<DocumentFile>;
  odometerOrHorimeter: Scalars['Float'];
  /** @deprecated Não será mais utilizado nas futuras versões */
  organization?: Maybe<Organization>;
  provider?: Maybe<Provider>;
  source: Scalars['String'];
  tank?: Maybe<Tank>;
  tankTransaction?: Maybe<TankTransaction>;
  unitCost: Scalars['Float'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

export type FuellingWithConsolidateProperties = {
  __typename?: 'FuellingWithConsolidateProperties';
  totalAdditivesGasoline: Scalars['Float'];
  totalAdditivesGasolineCost: Scalars['Float'];
  totalAdditivesGasolineEfficiency?: Maybe<Scalars['Float']>;
  totalAdditivesGasolineMeasure: Scalars['Float'];
  totalAdditivesGasolineUnitCost?: Maybe<Scalars['Float']>;
  totalDiesel: Scalars['Float'];
  totalDieselCost: Scalars['Float'];
  totalDieselEfficiency?: Maybe<Scalars['Float']>;
  totalDieselMeasure: Scalars['Float'];
  totalDieselS10: Scalars['Float'];
  totalDieselS10Cost: Scalars['Float'];
  totalDieselS10Efficiency?: Maybe<Scalars['Float']>;
  totalDieselS10Measure: Scalars['Float'];
  totalDieselS10UnitCost?: Maybe<Scalars['Float']>;
  totalDieselUnitCost?: Maybe<Scalars['Float']>;
  totalEthanol: Scalars['Float'];
  totalEthanolCost: Scalars['Float'];
  totalEthanolEfficiency?: Maybe<Scalars['Float']>;
  totalEthanolMeasure: Scalars['Float'];
  totalEthanolUnitCost?: Maybe<Scalars['Float']>;
  totalGasoline: Scalars['Float'];
  totalGasolineCost: Scalars['Float'];
  totalGasolineEfficiency?: Maybe<Scalars['Float']>;
  totalGasolineMeasure: Scalars['Float'];
  totalGasolineUnitCost?: Maybe<Scalars['Float']>;
  totalGnv: Scalars['Float'];
  totalGnvCost: Scalars['Float'];
  totalGnvEfficiency?: Maybe<Scalars['Float']>;
  totalGnvMeasure: Scalars['Float'];
  totalGnvUnitCost?: Maybe<Scalars['Float']>;
  totalKerosene: Scalars['Float'];
  totalKeroseneCost: Scalars['Float'];
  totalKeroseneEfficiency?: Maybe<Scalars['Float']>;
  totalKeroseneMeasure: Scalars['Float'];
  totalKeroseneUnitCost?: Maybe<Scalars['Float']>;
  totalOtherFuelling: Scalars['Float'];
  totalOtherFuellingCost: Scalars['Float'];
  totalOtherFuellingEfficiency?: Maybe<Scalars['Float']>;
  totalOtherFuellingMeasure: Scalars['Float'];
  totalOtherFuellingUnitCost?: Maybe<Scalars['Float']>;
  vehicle: Vehicle;
};

/** Endereço */
export type Geocode = {
  __typename?: 'Geocode';
  boundingBox?: Maybe<Array<Array<Scalars['Float']>>>;
  category: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  /** Endereço completo */
  description: Scalars['String'];
  place?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  settlement?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** Geocercas */
export type Geofence = {
  __typename?: 'Geofence';
  /** Geometria da cerca em GeoJSON */
  area: Scalars['jsonb'];
  /** Código hexiadecimal da cor #HEX */
  color: Scalars['String'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parking?: Maybe<Scalars['Boolean']>;
  /** Tipo da cerca: poligon e circle */
  type: GeofenceTypeEnum;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export enum GeofenceTypeEnum {
  Circle = 'circle',
  Polygon = 'polygon',
}

export type GetVehiclesFuellingSummaryResponse = {
  __typename?: 'GetVehiclesFuellingSummaryResponse';
  amount: Scalars['Float'];
  fuellings: Scalars['Float'];
  totalAdditivesGasoline: Scalars['Float'];
  totalAdditivesGasolineCost: Scalars['Float'];
  totalAdditivesGasolineEfficiency?: Maybe<Scalars['Float']>;
  totalAdditivesGasolineMeasure: Scalars['Float'];
  totalAdditivesGasolineUnitCost?: Maybe<Scalars['Float']>;
  totalArla32: Scalars['Float'];
  totalArla32Cost: Scalars['Float'];
  totalArla32UnitCost?: Maybe<Scalars['Float']>;
  totalCost: Scalars['Float'];
  totalDiesel: Scalars['Float'];
  totalDieselCost: Scalars['Float'];
  totalDieselEfficiency?: Maybe<Scalars['Float']>;
  totalDieselMeasure: Scalars['Float'];
  totalDieselS10: Scalars['Float'];
  totalDieselS10Cost: Scalars['Float'];
  totalDieselS10Efficiency?: Maybe<Scalars['Float']>;
  totalDieselS10Measure: Scalars['Float'];
  totalDieselS10UnitCost?: Maybe<Scalars['Float']>;
  totalDieselUnitCost?: Maybe<Scalars['Float']>;
  totalEthanol: Scalars['Float'];
  totalEthanolCost: Scalars['Float'];
  totalEthanolEfficiency?: Maybe<Scalars['Float']>;
  totalEthanolMeasure: Scalars['Float'];
  totalEthanolUnitCost?: Maybe<Scalars['Float']>;
  totalGasoline: Scalars['Float'];
  totalGasolineCost: Scalars['Float'];
  totalGasolineEfficiency?: Maybe<Scalars['Float']>;
  totalGasolineMeasure: Scalars['Float'];
  totalGasolineUnitCost?: Maybe<Scalars['Float']>;
  totalGnv: Scalars['Float'];
  totalGnvCost: Scalars['Float'];
  totalGnvEfficiency?: Maybe<Scalars['Float']>;
  totalGnvMeasure: Scalars['Float'];
  totalGnvUnitCost?: Maybe<Scalars['Float']>;
  totalKerosene: Scalars['Float'];
  totalKeroseneCost: Scalars['Float'];
  totalKeroseneEfficiency?: Maybe<Scalars['Float']>;
  totalKeroseneMeasure: Scalars['Float'];
  totalKeroseneUnitCost?: Maybe<Scalars['Float']>;
  totalOtherFuellings: Scalars['Float'];
  totalOtherFuellingsCost: Scalars['Float'];
  totalOtherFuellingsEfficiency?: Maybe<Scalars['Float']>;
  totalOtherFuellingsMeasure: Scalars['Float'];
  totalOtherFuellingsUnitCost?: Maybe<Scalars['Float']>;
  vehicleId: Scalars['String'];
};

/** Grupo */
export type Group = {
  __typename?: 'Group';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Veículos associados ao grupo */
  vehicles: Array<Vehicle>;
};

/** Histórico do horimetro */
export type HorimeterHistory = {
  __typename?: 'HorimeterHistory';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  current: Scalars['Float'];
  fuelling?: Maybe<Fuelling>;
  id: Scalars['ID'];
  /** Data de medição */
  measuredAt: Scalars['DateTime'];
  previous?: Maybe<Scalars['Float']>;
  source: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

/** Infrações de transito */
export type Infraction = {
  __typename?: 'Infraction';
  /** Código */
  code: Scalars['String'];
  /** Valor em R$ da infração */
  cost?: Maybe<Scalars['Float']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que a multa foi deletada */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Descrição */
  description: Scalars['String'];
  id: Scalars['ID'];
  /** Pontos */
  points?: Maybe<Scalars['Float']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Integrações */
export type Integration = {
  __typename?: 'Integration';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  enabledAt?: Maybe<Scalars['DateTime']>;
  hasPendency: Scalars['Boolean'];
  id: Scalars['ID'];
  intents: Array<IntegrationIntent>;
  organization: Organization;
  /** @deprecated Should use organization.id instead */
  organizationId: Scalars['ID'];
  pendingDriverMappings: Scalars['Int'];
  pendingFuelTypeMappings: Scalars['Int'];
  pendingProviderMappings: Scalars['Int'];
  pendingVehicleMappings: Scalars['Int'];
  setupCompletedAt?: Maybe<Scalars['DateTime']>;
  setupErrorMessage?: Maybe<Scalars['String']>;
  setupProgress?: Maybe<Scalars['Float']>;
  source: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Integrações */
export type IntegrationIntent = {
  __typename?: 'IntegrationIntent';
  attributes: Scalars['jsonb'];
  completed: Scalars['Boolean'];
  completedAt: Scalars['DateTime'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  error?: Maybe<Scalars['String']>;
  errorDescription?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integration: Integration;
  /** @deprecated Should use integration.id instead */
  integrationId: Scalars['ID'];
  progress?: Maybe<Scalars['Float']>;
  storage?: Maybe<DocumentFile>;
  /** @deprecated Should use storage.id instead */
  storageId?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export type ListTrafficInfractionInput = {
  endAt?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  vehicle: Scalars['ID'];
};

/** DEPRECATED - Use Position - Localização atual do veículo */
export type Location = {
  __typename?: 'Location';
  accuracy: Scalars['Float'];
  address?: Maybe<Scalars['String']>;
  altitude: Scalars['Float'];
  attributes: Scalars['jsonb'];
  course: Scalars['Float'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data no rastreador */
  deviceTime: Scalars['DateTime'];
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']>;
  /** Data real da localização */
  fixTime: Scalars['DateTime'];
  id: Scalars['ID'];
  ignition?: Maybe<Scalars['Boolean']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  /** Data de quando a posição chegou ao servidor */
  serverTime: Scalars['DateTime'];
  source: Scalars['String'];
  speed: Scalars['Float'];
  status: VehicleStatus;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
  vehicle: Vehicle;
  vehicleId: Scalars['ID'];
  weight?: Maybe<Scalars['Float']>;
};

export type MaintenanceCostPerTypeReport = {
  __typename?: 'MaintenanceCostPerTypeReport';
  /** Manutenção Corretiva */
  correctiveMaintenanceCost: Scalars['Float'];
  /** Data */
  date: Scalars['String'];
  /** Manutenção Preventiva */
  preventiveMaintenanceCost: Scalars['Float'];
  /** Quantidade */
  quantity: Scalars['Float'];
};

export type MaintenanceCostPerTypeReportInput = {
  /** Data final do filtro */
  endAt: Scalars['DateTime'];
  /** Data inicial do filtro */
  startAt: Scalars['DateTime'];
  vehicleIds: Array<Scalars['ID']>;
};

export type MaintenanceCostPerTypeReportResponse = {
  __typename?: 'MaintenanceCostPerTypeReportResponse';
  /** pecas */
  report: Array<MaintenanceCostPerTypeReport>;
  /** Data */
  vehicleId: Scalars['String'];
};

/** Lembrete */
export type MaintenanceReminder = {
  __typename?: 'MaintenanceReminder';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Frequência do lembrete por medida */
  frequencyMeasure?: Maybe<Scalars['Float']>;
  /** Frequência do lembrete por data */
  frequencyTime?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Medição na última manuteção */
  lastMeasure: Scalars['Float'];
  /** Data da última manuteção */
  lastOccurrence: Scalars['DateTime'];
  /** Dias restantes, quando negativo representa quantos dias se passaram */
  remainingDays: Scalars['Int'];
  /** Medição restante, quando negativo representa quantas Horas/KM se passaram */
  remainingMeasure: Scalars['Int'];
  /** Serviço */
  service: Service;
  /** Aviso com antecedência por medida */
  thresholdMeasure?: Maybe<Scalars['Float']>;
  /** Aviso com antecedência por data */
  thresholdTime?: Maybe<Scalars['Int']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Veículo */
  vehicle: Vehicle;
};

export type MaintenanceSummaryInput = {
  /** Data final do filtro */
  endAt: Scalars['DateTime'];
  /** Data inicial do filtro */
  startAt: Scalars['DateTime'];
  vehicleIds: Array<Scalars['ID']>;
};

export type MaintenanceSummaryResponse = {
  __typename?: 'MaintenanceSummaryResponse';
  /** Custo médio */
  averageCost?: Maybe<Scalars['Float']>;
  /** Quantidade de manutenções */
  maintenances?: Maybe<Scalars['Float']>;
  /** Custo máximo */
  maximumCost?: Maybe<Scalars['Float']>;
  /** Custo mínimo */
  minimumCost?: Maybe<Scalars['Float']>;
  /** Custo total */
  totalCost?: Maybe<Scalars['Float']>;
  /** Id do veiculo */
  vehicleId: Scalars['String'];
};

export enum MeasurementTypeEnum {
  Horimeter = 'horimeter',
  Odometer = 'odometer',
}

export type Mutation = {
  __typename?: 'Mutation';
  addVehicleDriver: Vehicle;
  authorize: Authorization;
  changeUserPassword: Scalars['Boolean'];
  changeUserVehicles: Scalars['Boolean'];
  changeVehicleMeasure: DailyWorkMeasure;
  createChecklistChoice: ChecklistChoice;
  createChecklistForm: ChecklistForm;
  createChecklistItem: ChecklistItem;
  createChecklistSubmission: ChecklistSubmission;
  createChip: Chip;
  createDevice: Device;
  createDriver: Driver;
  createDriverTokens: Array<DriverToken>;
  createFeatureFlag: FeatureFlag;
  createFuelling: Fuelling;
  createGeofence: Geofence;
  createGroup: Group;
  createInstallationServices: Array<OpsService>;
  createIntegration: Integration;
  createMaintenanceReminders: Array<MaintenanceReminder>;
  createOrder: Order;
  createOrganization: Organization;
  createPart: Part;
  createProvider: Provider;
  createService: Service;
  createShipping: Shipping;
  createTank: Tank;
  createTask: Task;
  createTechnician: Technician;
  createTrafficInfraction: TrafficInfraction;
  createUninstallationServices: Array<OpsService>;
  createUnmappedDrivers: Array<DriverMapping>;
  createUnmappedProviders: Array<ProviderMapping>;
  createUnmappedVehicles: Array<VehicleMapping>;
  createUser: User;
  createVehicle: Vehicle;
  createWebhook: Webhook;
  deactivateVehicle: Vehicle;
  deleteChecklistForm: Scalars['Boolean'];
  deleteChip: Scalars['Boolean'];
  deleteDevice: Scalars['Boolean'];
  deleteDriver: Scalars['Boolean'];
  deleteDriverToken: Scalars['Boolean'];
  deleteFeatureFlag: Scalars['Boolean'];
  deleteFuelling: Scalars['Boolean'];
  deleteGeofence: Scalars['Boolean'];
  deleteGroup: Scalars['Boolean'];
  deleteIntegration: Scalars['Boolean'];
  deleteMaintenanceReminder: Scalars['Boolean'];
  deleteOrder: Scalars['Boolean'];
  deletePart: Scalars['Boolean'];
  deleteProvider: Scalars['Boolean'];
  deleteService: Scalars['Boolean'];
  deleteTank: Scalars['Boolean'];
  deleteTask: Scalars['Boolean'];
  deleteTechnician: Scalars['Boolean'];
  deleteTrafficInfraction: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  deleteWebhook: Scalars['Boolean'];
  deviceImports: Array<Device>;
  disableVehicle: Vehicle;
  enableVehicle: Vehicle;
  login: Authorization;
  notificationsRegisterDeviceToken: Scalars['Boolean'];
  payTrafficInfraction: TrafficInfraction;
  removeVehicleDriver: Vehicle;
  requestPasswordRecovery: Scalars['Boolean'];
  requestVehicleVideo: VehicleVideoResponse;
  resetPassword: Scalars['Boolean'];
  retryImportIntegrationIntent: IntegrationIntent;
  saveNotificationPreferences: Array<NotificationPreference>;
  saveVehicles: Array<Vehicle>;
  setCurrentDriverToVehicleDriverChangeHistory: VehicleDriverChangeHistory;
  setDriverToken: Driver;
  setPreviousDriverToVehicleDriverChangeHistory: VehicleDriverChangeHistory;
  /** @deprecated Should use addVehicleDriver instead */
  setVehicleDriver: Vehicle;
  /** @deprecated Should use the changeVehicleMeasure */
  setVehicleMeasure: Scalars['Boolean'];
  technicianImports: Array<Technician>;
  unsetDriverToken: Driver;
  updateChecklistChoice: ChecklistChoice;
  updateChecklistForm: ChecklistForm;
  updateChecklistItem: ChecklistItem;
  updateChip: Chip;
  updateDevice: Device;
  updateDriver: Driver;
  updateDriverMappings: Array<DriverMapping>;
  updateFeatureFlag: FeatureFlag;
  updateFuelTypeMappings: Array<FuelTypeMapping>;
  updateFuelling: Fuelling;
  updateGeofence: Geofence;
  updateGroup: Group;
  updateMaintenanceReminder: MaintenanceReminder;
  updateOpsService: OpsService;
  updateOrder: Order;
  updateOrganization: Organization;
  updateProvider: Provider;
  updateProviderMappings: Array<ProviderMapping>;
  updateService: Service;
  updateShipping: Shipping;
  updateTank: Tank;
  updateTask: Task;
  updateTechnician: Technician;
  updateTrafficInfraction: TrafficInfraction;
  updateUser: User;
  updateVehicle: Vehicle;
  updateVehicleMappings: Array<VehicleMapping>;
  updateWebhook: Webhook;
  uploadFileIntegration: IntegrationIntent;
  vehicleImports: Array<Vehicle>;
};

export type MutationAddVehicleDriverArgs = {
  payload: ChangeVehicleDriverInput;
};

export type MutationAuthorizeArgs = {
  appName: Scalars['String'];
  userId: Scalars['ID'];
};

export type MutationChangeUserPasswordArgs = {
  password: Scalars['String'];
  user: Scalars['ID'];
};

export type MutationChangeUserVehiclesArgs = {
  payload: ChangeUserVehiclesInput;
};

export type MutationChangeVehicleMeasureArgs = {
  payload: ChangeVehicleMeasureInput;
};

export type MutationCreateChecklistChoiceArgs = {
  payload: CreateChecklistChoiceInput;
};

export type MutationCreateChecklistFormArgs = {
  payload: CreateChecklistFormInput;
};

export type MutationCreateChecklistItemArgs = {
  payload: CreateChecklistItemInput;
};

export type MutationCreateChecklistSubmissionArgs = {
  payload: CreateChecklistSubmissionInput;
};

export type MutationCreateChipArgs = {
  createChipInput: CreateChipInput;
};

export type MutationCreateDeviceArgs = {
  payload: CreateDeviceInput;
};

export type MutationCreateDriverArgs = {
  payload: CreateDriverInput;
};

export type MutationCreateDriverTokensArgs = {
  payload: Array<CreateDriverTokenInput>;
};

export type MutationCreateFeatureFlagArgs = {
  payload: CreateFeatureFlagInput;
};

export type MutationCreateFuellingArgs = {
  payload: CreateFuellingInput;
};

export type MutationCreateGeofenceArgs = {
  payload: CreateGeofenceInput;
};

export type MutationCreateGroupArgs = {
  payload: CreateGroupInput;
};

export type MutationCreateInstallationServicesArgs = {
  payload: Array<CreateOpsServiceInput>;
};

export type MutationCreateIntegrationArgs = {
  payload: CreateIntegrationInput;
};

export type MutationCreateMaintenanceRemindersArgs = {
  reminders: Array<CreateMaintenanceReminderInput>;
};

export type MutationCreateOrderArgs = {
  payload: CreateOrderInput;
};

export type MutationCreateOrganizationArgs = {
  payload: CreateOrganizationInput;
};

export type MutationCreatePartArgs = {
  payload: CreateMaintenanceParteInput;
};

export type MutationCreateProviderArgs = {
  payload: CreateProviderInput;
};

export type MutationCreateServiceArgs = {
  payload: CreateMaintenanceServiceInput;
};

export type MutationCreateShippingArgs = {
  createShippingInput: CreateShippingInput;
};

export type MutationCreateTankArgs = {
  payload: CreateTankInput;
};

export type MutationCreateTaskArgs = {
  payload: CreateMaintenanceTaskInput;
};

export type MutationCreateTechnicianArgs = {
  createTechnicianInput: CreateTechnicianInput;
};

export type MutationCreateTrafficInfractionArgs = {
  payload: CreateTrafficInfractionInput;
};

export type MutationCreateUninstallationServicesArgs = {
  payload: Array<CreateOpsServiceInput>;
};

export type MutationCreateUnmappedDriversArgs = {
  mappings: Array<Scalars['ID']>;
};

export type MutationCreateUnmappedProvidersArgs = {
  mappings: Array<Scalars['ID']>;
};

export type MutationCreateUnmappedVehiclesArgs = {
  mappings: Array<Scalars['ID']>;
};

export type MutationCreateUserArgs = {
  payload: CreateUserInput;
};

export type MutationCreateVehicleArgs = {
  payload: CreateVehicleInput;
};

export type MutationCreateWebhookArgs = {
  payload: CreateWebhookInput;
};

export type MutationDeactivateVehicleArgs = {
  vehicle: Scalars['ID'];
};

export type MutationDeleteChecklistFormArgs = {
  payload: DeleteChecklistFormInput;
};

export type MutationDeleteChipArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteDeviceArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteDriverArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteDriverTokenArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteFeatureFlagArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteFuellingArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteGeofenceArgs = {
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteIntegrationArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteMaintenanceReminderArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteOrderArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePartArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProviderArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteServiceArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTankArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTaskArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTechnicianArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTrafficInfractionArgs = {
  ids: Array<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteWebhookArgs = {
  id: Scalars['ID'];
};

export type MutationDeviceImportsArgs = {
  file: Scalars['Upload'];
};

export type MutationDisableVehicleArgs = {
  vehicle: Scalars['ID'];
};

export type MutationEnableVehicleArgs = {
  vehicle: Scalars['ID'];
};

export type MutationLoginArgs = {
  password: Scalars['String'];
  remember?: InputMaybe<Scalars['Boolean']>;
  username: Scalars['String'];
};

export type MutationNotificationsRegisterDeviceTokenArgs = {
  platform: NotificationPlatformTypeEnum;
  token: Scalars['String'];
};

export type MutationPayTrafficInfractionArgs = {
  payload: PayTrafficInfractionInput;
};

export type MutationRemoveVehicleDriverArgs = {
  payload: ChangeVehicleDriverInput;
};

export type MutationRequestPasswordRecoveryArgs = {
  username: Scalars['String'];
};

export type MutationRequestVehicleVideoArgs = {
  camera?: InputMaybe<VehicleCameraEnum>;
  occurredAt: Scalars['DateTime'];
  organizationId: Scalars['ID'];
  vehicleId: Scalars['ID'];
};

export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationRetryImportIntegrationIntentArgs = {
  intentId: Scalars['ID'];
};

export type MutationSaveNotificationPreferencesArgs = {
  notificationPreferences: Array<SaveNotificationPreferencesInput>;
};

export type MutationSaveVehiclesArgs = {
  payload: Array<CreateVehicleInput>;
};

export type MutationSetCurrentDriverToVehicleDriverChangeHistoryArgs = {
  payload: SetDriverToVehicleDriverChangeHistoryInput;
};

export type MutationSetDriverTokenArgs = {
  payload: SetDriverTokenInput;
};

export type MutationSetPreviousDriverToVehicleDriverChangeHistoryArgs = {
  payload: SetDriverToVehicleDriverChangeHistoryInput;
};

export type MutationSetVehicleDriverArgs = {
  payload: SetVehicleDriverInput;
};

export type MutationSetVehicleMeasureArgs = {
  payload: SetVehicleMeasureInput;
};

export type MutationTechnicianImportsArgs = {
  file: Scalars['Upload'];
};

export type MutationUnsetDriverTokenArgs = {
  payload: SetDriverTokenInput;
};

export type MutationUpdateChecklistChoiceArgs = {
  payload: UpdateChecklistChoiceInput;
};

export type MutationUpdateChecklistFormArgs = {
  payload: UpdateChecklistFormInput;
};

export type MutationUpdateChecklistItemArgs = {
  payload: UpdateChecklistItemInput;
};

export type MutationUpdateChipArgs = {
  updateChipInput: UpdateChipInput;
};

export type MutationUpdateDeviceArgs = {
  payload: UpdateDeviceInput;
};

export type MutationUpdateDriverArgs = {
  payload: UpdateDriverInput;
};

export type MutationUpdateDriverMappingsArgs = {
  payload: Array<UpdateDriverMappingInput>;
};

export type MutationUpdateFeatureFlagArgs = {
  payload: UpdateFeatureFlagInput;
};

export type MutationUpdateFuelTypeMappingsArgs = {
  payload: Array<UpdateFuelTypeMappingInput>;
};

export type MutationUpdateFuellingArgs = {
  payload: UpdateFuellingInput;
};

export type MutationUpdateGeofenceArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
  payload: UpdateGeofenceInput;
};

export type MutationUpdateGroupArgs = {
  payload: UpdateGroupInput;
};

export type MutationUpdateMaintenanceReminderArgs = {
  payload: UpdateMaintenanceReminderInput;
};

export type MutationUpdateOpsServiceArgs = {
  payload: UpdateOpsServiceInput;
};

export type MutationUpdateOrderArgs = {
  payload: UpdateOrderInput;
};

export type MutationUpdateOrganizationArgs = {
  payload: UpdateOrganizationInput;
};

export type MutationUpdateProviderArgs = {
  payload: UpdateProviderInput;
};

export type MutationUpdateProviderMappingsArgs = {
  payload: Array<UpdateProviderMappingInput>;
};

export type MutationUpdateServiceArgs = {
  payload: UpdateMaintenanceServiceInput;
};

export type MutationUpdateShippingArgs = {
  updateShippingInput: UpdateShippingInput;
};

export type MutationUpdateTankArgs = {
  payload: UpdateTankInput;
};

export type MutationUpdateTaskArgs = {
  payload: UpdateMaintenanceTaskInput;
};

export type MutationUpdateTechnicianArgs = {
  updateTechnicianInput: UpdateTechnicianInput;
};

export type MutationUpdateTrafficInfractionArgs = {
  payload: UpdateTrafficInfractionInput;
};

export type MutationUpdateUserArgs = {
  payload: UpdateUserInput;
};

export type MutationUpdateVehicleArgs = {
  payload: UpdateVehicleInput;
};

export type MutationUpdateVehicleMappingsArgs = {
  payload: Array<UpdateVehicleMappingInput>;
};

export type MutationUpdateWebhookArgs = {
  payload: UpdateWebhookInput;
};

export type MutationUploadFileIntegrationArgs = {
  file: Scalars['Upload'];
  integrationId: Scalars['ID'];
};

export type MutationVehicleImportsArgs = {
  file: Scalars['Upload'];
  organizationId: Scalars['ID'];
};

/** Notification */
export type Notification = {
  __typename?: 'Notification';
  /** @deprecated No longer available */
  actions: Array<NotificationAction>;
  attributes: Scalars['jsonb'];
  category: NotificationCategory;
  /** Corpo da notificação */
  content: Array<Scalars['jsonb']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** @deprecated No longer available */
  imageUrl: Scalars['String'];
  /** @deprecated No longer available */
  interactedAt: Scalars['DateTime'];
  readAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Notification action */
export type NotificationAction = {
  __typename?: 'NotificationAction';
  deepLinkAndroid: Scalars['String'];
  deepLinkIOS: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  url: Scalars['String'];
};

/** Categoria de notificação */
export type NotificationCategory = {
  __typename?: 'NotificationCategory';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  module: Scalars['String'];
  name: Scalars['String'];
  slugName: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export enum NotificationPlatformTypeEnum {
  Android = 'android',
  Ios = 'ios',
  Web = 'web',
}

/** User Notification Preferences */
export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  /** Categoria daquela preferencia */
  category: NotificationCategory;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Se vai receber pelo email */
  email: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Se vai receber uma push notification */
  pushNotification: Scalars['Boolean'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Notification summary */
export type NotificationSummary = {
  __typename?: 'NotificationSummary';
  expired: Scalars['Int'];
  interacted: Scalars['Int'];
  read: Scalars['Int'];
  unExpired: Scalars['Int'];
  unInteracted: Scalars['Int'];
  unRead: Scalars['Int'];
};

/** Histórico do odometro */
export type OdometerHistory = {
  __typename?: 'OdometerHistory';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  current: Scalars['Float'];
  fuelling?: Maybe<Fuelling>;
  id: Scalars['ID'];
  /** Data de medição */
  measuredAt: Scalars['DateTime'];
  previous?: Maybe<Scalars['Float']>;
  source: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

export type OpsService = {
  __typename?: 'OpsService';
  category: OpsServiceCategory;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  device?: Maybe<Device>;
  deviceId: Scalars['ID'];
  id: Scalars['ID'];
  isTaxable: Scalars['Boolean'];
  occurredAt: Scalars['DateTime'];
  organization?: Maybe<Organization>;
  organizationId: Scalars['ID'];
  technician?: Maybe<Technician>;
  technicianId: Scalars['ID'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  vehicle?: Maybe<Vehicle>;
  vehicleId: Scalars['ID'];
};

export enum OpsServiceCategory {
  Installation = 'installation',
  Uninstallation = 'uninstallation',
}

export type OpsServicePaginate = {
  __typename?: 'OpsServicePaginate';
  /** Serviços */
  opsServices: Array<OpsService>;
  /** Total de registros */
  total: Scalars['Int'];
};

/** Ordem */
export type Order = {
  __typename?: 'Order';
  /** Quantidade em litros */
  amount: Scalars['Float'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Numero da Nota */
  invoiceNumber: Scalars['Float'];
  /** Data que a ordem foi realizada */
  occurredAt: Scalars['DateTime'];
  organization: Organization;
  /** Origem da Ordem */
  source: Scalars['String'];
  tank?: Maybe<Tank>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Valor em R$ */
  value: Scalars['Float'];
};

/** Organização */
export type Organization = {
  __typename?: 'Organization';
  address?: Maybe<Scalars['String']>;
  apps: Array<App>;
  capacity?: Maybe<OrganizationCapacity>;
  category?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  /** Data de fechamento do cliente */
  contractBeganAt?: Maybe<Scalars['DateTime']>;
  /** Data de desativação do cliente */
  contractEndedAt?: Maybe<Scalars['DateTime']>;
  /** Duração do contrato */
  contractMonthDuration?: Maybe<Scalars['Int']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Organização com acesso cortado em */
  disabledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Pagamento recorrente mensal */
  monthlyPayment?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  /** Organização matriz da filial. */
  primaryOrganization?: Maybe<Organization>;
  sector?: Maybe<Scalars['String']>;
  /** Configurações globais da organização */
  settings: Scalars['jsonb'];
  /** Taxa de adesão */
  signUpFee?: Maybe<Scalars['Float']>;
  /** Início do trial */
  trialAt?: Maybe<Scalars['DateTime']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
};

export enum OrganizationCapacity {
  Five = 'Five',
  Four = 'Four',
  One = 'One',
  Six = 'Six',
  Three = 'Three',
  Two = 'Two',
}

/** Parts */
export type Part = {
  __typename?: 'Part';
  /** status da peca */
  active: Scalars['Boolean'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Nome da Peca */
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  standard: Scalars['Boolean'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export type PayTrafficInfractionInput = {
  driver?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** Observação */
  observation?: InputMaybe<Scalars['String']>;
  /** Data da multa */
  occurredAt: Scalars['DateTime'];
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Pago em... */
  paidAt: Scalars['DateTime'];
  /** Custo Pago */
  paidCost: Scalars['Float'];
};

/** Posição do veículo */
export type Position = {
  __typename?: 'Position';
  accuracy: Scalars['Float'];
  address?: Maybe<Scalars['String']>;
  altitude: Scalars['Float'];
  attributes: Scalars['jsonb'];
  course: Scalars['Float'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data no rastreador */
  deviceTime: Scalars['DateTime'];
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']>;
  /** Data real da localização */
  fixTime: Scalars['DateTime'];
  id: Scalars['ID'];
  ignition?: Maybe<Scalars['Boolean']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  /** Data de quando a posição chegou ao servidor */
  serverTime: Scalars['DateTime'];
  source: Scalars['String'];
  speed: Scalars['Float'];
  status: VehicleStatus;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
  vehicle: Vehicle;
  vehicleId: Scalars['ID'];
  weight?: Maybe<Scalars['Float']>;
};

/** Fornecedor */
export type Provider = {
  __typename?: 'Provider';
  /** Status */
  active: Scalars['Boolean'];
  attributes: Scalars['jsonb'];
  cnpj?: Maybe<Scalars['String']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  source: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Mapeamento de fornecedor */
export type ProviderMapping = {
  __typename?: 'ProviderMapping';
  attributes: Scalars['jsonb'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  externalId: Scalars['String'];
  id: Scalars['ID'];
  integration: Integration;
  /** @deprecated Should use integration.id instead */
  integrationId: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  /** @deprecated Should use organization.id instead */
  organizationId: Scalars['ID'];
  provider?: Maybe<Provider>;
  /** @deprecated Should use provider.id instead */
  providerId?: Maybe<Scalars['ID']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  afterUsages: Array<AfterUsage>;
  app: App;
  apps: Array<App>;
  categories: Array<Category>;
  checklistForm: ChecklistForm;
  checklistForms: ChecklistFormPaginate;
  checklistSubmission: ChecklistSubmission;
  checklistSubmissions: ChecklistSubmissionPaginate;
  chips: Array<Chip>;
  countEvents: Array<EventSummary>;
  dailyWorkMeasures: Array<DailyWorkMeasure>;
  devices: DevicePaginate;
  driverMappings: Array<DriverMapping>;
  driverProfiles: Array<DriverProfiles>;
  driverTokens: DriverTokenPaginate;
  driverTokensExists: Array<DriverTokenExistsResponse>;
  drivers: Array<Driver>;
  driversRanking: Array<DriverRanking>;
  featureFlags: Array<FeatureFlag>;
  findDriversEvents: Array<Event>;
  findGeocode: Geocode;
  findInfractions: Array<Infraction>;
  findMaintenanceReminders: Array<MaintenanceReminder>;
  findPaginatedUsers: UserPaginate;
  /** Busca as posições de um veículo em um período. */
  findPositions: Array<Position>;
  findSupportArticle: SupportArticle;
  findTripsByVehicle: Array<Trip>;
  findVehicleEvents: Array<Event>;
  findVehicleFuellings: Array<Fuelling>;
  findVehicleFuellingsConsolidated: Array<FuellingWithConsolidateProperties>;
  findVehicleFuellingsWithComputedProperties: Array<FuellingWithComputedProperties>;
  findVehicles: VehiclePaginate;
  fuelTypeMappings: Array<FuelTypeMapping>;
  fuelTypes: Array<FuelType>;
  geofence: Geofence;
  geofences: Array<Geofence>;
  getFuellingReference: FuellingReference;
  getVehiclesFuellingSummary: Array<GetVehiclesFuellingSummaryResponse>;
  groups: Array<Group>;
  integration: Integration;
  integrations: Array<Integration>;
  listNotificationCategories: Array<NotificationCategory>;
  listNotificationPreferences: Array<NotificationPreference>;
  maintenanceCostPerTypeReport: Array<MaintenanceCostPerTypeReportResponse>;
  maintenanceReminder: MaintenanceReminder;
  maintenanceSummary: Array<MaintenanceSummaryResponse>;
  me: User;
  modules: Array<AppModule>;
  myFeatureFlags: Array<UserFeatureFlag>;
  notifications: Array<Notification>;
  notificationsSummary: NotificationSummary;
  opsService: OpsServicePaginate;
  orders: Array<Order>;
  organizations: Array<Organization>;
  parts: Array<Part>;
  pendingIntegrationMappingTotal: Scalars['Int'];
  providerMappings: Array<ProviderMapping>;
  providers: Array<Provider>;
  routeDetails: RouteDetails;
  services: Array<Service>;
  shippings: Array<Shipping>;
  tanks: Array<Tank>;
  tasks: Array<Task>;
  technicians: Array<Technician>;
  totalTimeStopped: Array<TotalResponse>;
  totalTimeStoppedWithIgnitionOn: Array<TotalResponse>;
  trafficInfractions: Array<TrafficInfraction>;
  user: User;
  users: Array<User>;
  vehicle: Vehicle;
  vehicleDriverChangeHistory: VehicleDriverChangeHistory;
  vehicleDriverHistories: VehicleDriverHistoryPaginate;
  vehicleMappings: Array<VehicleMapping>;
  vehicleReport: Array<VehicleReport>;
  vehicleTypes: Array<VehicleType>;
  vehicles: Array<Vehicle>;
  vehiclesByPlate: Array<Vehicle>;
  vehiclesFuellingSummary: Array<VehiclesFuellingSummaryResponse>;
  vehiclesMaxSpeed: Array<TotalResponse>;
  vehiclesRanking: Array<VehicleRanking>;
  vehiclesSummaries: Array<VehicleSummary>;
  vehiclesTotalDistance: Array<TotalResponse>;
  vehiclesTotalTimeWithIgnitionOn: Array<TotalResponse>;
  webhooks: Array<Webhook>;
  workShiftEventTypes: Array<WorkShiftEventType>;
};

export type QueryAfterUsagesArgs = {
  endAt: Scalars['DateTime'];
  endWorkTime: Scalars['String'];
  startAt: Scalars['DateTime'];
  startWorkTime: Scalars['String'];
  vehicles: Array<Scalars['ID']>;
};

export type QueryAppArgs = {
  id: Scalars['ID'];
};

export type QueryChecklistFormArgs = {
  id: Scalars['ID'];
};

export type QueryChecklistSubmissionArgs = {
  submissionId: Scalars['ID'];
};

export type QueryChecklistSubmissionsArgs = {
  filters?: InputMaybe<ChecklistSubmissionFiltersInput>;
};

export type QueryCountEventsArgs = {
  driverIds?: InputMaybe<Array<Scalars['ID']>>;
  endAt: Scalars['DateTime'];
  geofences?: InputMaybe<Array<Scalars['ID']>>;
  organizationId?: InputMaybe<Scalars['ID']>;
  slugNames?: InputMaybe<Array<Scalars['String']>>;
  startAt: Scalars['DateTime'];
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type QueryDailyWorkMeasuresArgs = {
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  vehicles: Array<Scalars['ID']>;
};

export type QueryDevicesArgs = {
  hasVehicle?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchValue?: InputMaybe<Scalars['String']>;
  searchVehicleOrganization?: InputMaybe<Scalars['ID']>;
  searchVehiclePlate?: InputMaybe<Scalars['String']>;
  vehicleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryDriverMappingsArgs = {
  integrationId: Scalars['ID'];
};

export type QueryDriverProfilesArgs = {
  drivers: Array<Scalars['ID']>;
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
};

export type QueryDriverTokensArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sku?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type QueryDriverTokensExistsArgs = {
  payload: Array<Scalars['String']>;
};

export type QueryDriversArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type QueryDriversRankingArgs = {
  drivers: Array<Scalars['ID']>;
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
};

export type QueryFindDriversEventsArgs = {
  driverIds?: InputMaybe<Array<Scalars['ID']>>;
  endAt: Scalars['DateTime'];
  geofences?: InputMaybe<Array<Scalars['ID']>>;
  organizationId?: InputMaybe<Scalars['ID']>;
  slugNames?: InputMaybe<Array<Scalars['String']>>;
  startAt: Scalars['DateTime'];
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type QueryFindGeocodeArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type QueryFindInfractionsArgs = {
  payload: FindInfractionInput;
};

export type QueryFindPaginatedUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryFindPositionsArgs = {
  endAt: Scalars['DateTime'];
  limit?: InputMaybe<Scalars['Int']>;
  startAt: Scalars['DateTime'];
  vehicle: Scalars['ID'];
};

export type QueryFindSupportArticleArgs = {
  id: Scalars['ID'];
};

export type QueryFindTripsByVehicleArgs = {
  finishedAt: Scalars['DateTime'];
  startedAt: Scalars['DateTime'];
  vehicle: Scalars['ID'];
};

export type QueryFindVehicleEventsArgs = {
  driverIds?: InputMaybe<Array<Scalars['ID']>>;
  endAt: Scalars['DateTime'];
  geofences?: InputMaybe<Array<Scalars['ID']>>;
  organizationId?: InputMaybe<Scalars['ID']>;
  slugNames?: InputMaybe<Array<Scalars['String']>>;
  startAt: Scalars['DateTime'];
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type QueryFindVehicleFuellingsArgs = {
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  vehicles: Array<Scalars['ID']>;
};

export type QueryFindVehicleFuellingsConsolidatedArgs = {
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  vehicles: Array<Scalars['ID']>;
};

export type QueryFindVehicleFuellingsWithComputedPropertiesArgs = {
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  vehicles: Array<Scalars['ID']>;
};

export type QueryFindVehiclesArgs = {
  filter?: InputMaybe<VehicleFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryFuelTypeMappingsArgs = {
  integrationId: Scalars['ID'];
};

export type QueryGeofenceArgs = {
  id: Scalars['ID'];
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type QueryGeofencesArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetFuellingReferenceArgs = {
  date: Scalars['DateTime'];
  vehicle: Scalars['ID'];
};

export type QueryGetVehiclesFuellingSummaryArgs = {
  payload: ReportInput;
};

export type QueryIntegrationArgs = {
  id: Scalars['ID'];
};

export type QueryMaintenanceCostPerTypeReportArgs = {
  payload: MaintenanceCostPerTypeReportInput;
};

export type QueryMaintenanceReminderArgs = {
  id: Scalars['ID'];
};

export type QueryMaintenanceSummaryArgs = {
  payload: MaintenanceSummaryInput;
};

export type QueryNotificationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryOpsServiceArgs = {
  category?: InputMaybe<OpsServiceCategory>;
  device?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationName?: InputMaybe<Scalars['String']>;
  technicianName?: InputMaybe<Scalars['String']>;
  vehiclePlate?: InputMaybe<Scalars['String']>;
};

export type QueryOrdersArgs = {
  filter?: InputMaybe<FilterOrder>;
};

export type QueryPartsArgs = {
  filter?: InputMaybe<FilterPartInput>;
};

export type QueryProviderMappingsArgs = {
  integrationId: Scalars['ID'];
};

export type QueryRouteDetailsArgs = {
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  vehicle: Scalars['ID'];
};

export type QueryServicesArgs = {
  filter?: InputMaybe<FilterServiceInput>;
};

export type QueryTasksArgs = {
  filter?: InputMaybe<FilterTaskInput>;
};

export type QueryTotalTimeStoppedArgs = {
  payload: ReportInput;
};

export type QueryTotalTimeStoppedWithIgnitionOnArgs = {
  payload: ReportInput;
};

export type QueryTrafficInfractionsArgs = {
  payload: ListTrafficInfractionInput;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['ID']>;
};

export type QueryVehicleArgs = {
  id: Scalars['ID'];
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type QueryVehicleDriverChangeHistoryArgs = {
  id: Scalars['ID'];
};

export type QueryVehicleDriverHistoriesArgs = {
  endedAt?: InputMaybe<Scalars['DateTime']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  startedAt?: InputMaybe<Scalars['DateTime']>;
  tokenId?: InputMaybe<Scalars['ID']>;
  vehicleId?: InputMaybe<Scalars['ID']>;
};

export type QueryVehicleMappingsArgs = {
  integrationId: Scalars['ID'];
};

export type QueryVehicleReportArgs = {
  payload: VehicleReportInput;
};

export type QueryVehiclesArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type QueryVehiclesByPlateArgs = {
  plate: Scalars['String'];
};

export type QueryVehiclesFuellingSummaryArgs = {
  payload: ReportInput;
};

export type QueryVehiclesMaxSpeedArgs = {
  payload: ReportInput;
};

export type QueryVehiclesRankingArgs = {
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  vehicles: Array<Scalars['ID']>;
};

export type QueryVehiclesSummariesArgs = {
  endAt: Scalars['DateTime'];
  groupedBy?: InputMaybe<SummaryDateGroupedByEnum>;
  startAt: Scalars['DateTime'];
  vehicles: Array<Scalars['ID']>;
};

export type QueryVehiclesTotalDistanceArgs = {
  payload: ReportInput;
};

export type QueryVehiclesTotalTimeWithIgnitionOnArgs = {
  payload: ReportInput;
};

export type ReferenceTaskCostInput = {
  /** Valor */
  amount?: InputMaybe<Scalars['Float']>;
  type: CostTypeEnum;
};

export type ReferenceTaskPartInput = {
  /** Valor */
  amount?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  /** Quantidade */
  quantity?: InputMaybe<Scalars['Int']>;
};

export type ReportInput = {
  /** Data final do filtro */
  endAt: Scalars['DateTime'];
  /** Data inicial do filtro */
  startAt: Scalars['DateTime'];
  vehicleIds: Array<Scalars['ID']>;
};

export type RouteDetails = {
  __typename?: 'RouteDetails';
  averageSpeed?: Maybe<Scalars['Float']>;
  engineIdle?: Maybe<Scalars['Float']>;
  geojson: Scalars['jsonb'];
  maximumSpeed?: Maybe<Scalars['Float']>;
  totalDistanceTraveled?: Maybe<Scalars['Float']>;
  totalTimeStopped?: Maybe<Scalars['Float']>;
  totalTimeStoppedWithIgnitionOn?: Maybe<Scalars['Float']>;
  totalTimeWithIgnitionOn?: Maybe<Scalars['Float']>;
};

export type SaveNotificationPreferencesInput = {
  email: Scalars['Boolean'];
  notificationCategoryId: Scalars['ID'];
  pushNotification: Scalars['Boolean'];
};

/** Service */
export type Service = {
  __typename?: 'Service';
  /** status do servico */
  active?: Maybe<Scalars['Boolean']>;
  category: Category;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que o serviço foi deletado */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Nome do Servico */
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  /** padrao do sistema */
  standard?: Maybe<Scalars['Boolean']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export type SetDriverToVehicleDriverChangeHistoryInput = {
  driver: Scalars['ID'];
  id: Scalars['ID'];
};

export type SetDriverTokenInput = {
  driver: Scalars['ID'];
  driverToken: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type SetVehicleDriverInput = {
  driver?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  vehicle: Scalars['ID'];
};

export type SetVehicleMeasureInput = {
  /** Valor atual do horimetro */
  horimeter?: InputMaybe<Scalars['Float']>;
  /** Valor atual do odometro */
  odometer?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  vehicle: Scalars['ID'];
};

/** Envios */
export type Shipping = {
  __typename?: 'Shipping';
  city: Scalars['String'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  devices?: Maybe<Array<Device>>;
  devolution: Scalars['Boolean'];
  id: Scalars['ID'];
  occurredAt: Scalars['DateTime'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']>;
  state: Scalars['String'];
  technician?: Maybe<Technician>;
  technicianId?: Maybe<Scalars['ID']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

/** Parada */
export type Stop = {
  __typename?: 'Stop';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  driverId?: Maybe<Scalars['ID']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  firstPosition: Position;
  id: Scalars['ID'];
  lastPosition?: Maybe<Position>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  startedAt: Scalars['DateTime'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicleId: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  newVehicleLocation: Location;
  syncIntegration: Integration;
  updateVehicle: Vehicle;
};

export type SubscriptionNewVehicleLocationArgs = {
  vehicles: Array<Scalars['ID']>;
};

export type SubscriptionSyncIntegrationArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type SubscriptionUpdateVehicleArgs = {
  vehicles: Array<Scalars['ID']>;
};

export enum SummaryDateGroupedByEnum {
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Month = 'month',
  Quarter = 'quarter',
  Second = 'second',
  Week = 'week',
  Year = 'year',
}

/** SupportArticle */
export type SupportArticle = {
  __typename?: 'SupportArticle';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

/** Tanque */
export type Tank = {
  __typename?: 'Tank';
  /** Status do tank */
  active?: Maybe<Scalars['Boolean']>;
  attributes: Scalars['jsonb'];
  balance: Scalars['Float'];
  /** Capacidade máxima do tanque */
  capacity?: Maybe<Scalars['Float']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que o tank foi deletado */
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Volume inicial do tanque */
  initialVolume?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  organization: Organization;
  /** Preço por litro */
  pricePerLiter?: Maybe<Scalars['Float']>;
  source: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Transação do tanque */
export type TankTransaction = {
  __typename?: 'TankTransaction';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  fuelling?: Maybe<Fuelling>;
  id: Scalars['ID'];
  order?: Maybe<Order>;
  tank: Tank;
  /** Tipo de lançamento */
  type: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Quantidade em litros */
  value: Scalars['Float'];
};

/** Service */
export type Task = {
  __typename?: 'Task';
  /** Custo total */
  amount: Scalars['Float'];
  /** Data que o servico foi concluido */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que a manutenção foi deletada */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Descricao do Servico */
  description: Scalars['String'];
  driver?: Maybe<Driver>;
  evidence?: Maybe<DocumentFile>;
  /** Horimetro */
  horimeter?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Odometro */
  odometer?: Maybe<Scalars['Float']>;
  parts: Array<TaskPart>;
  prices: Array<TaskCost>;
  provider?: Maybe<Provider>;
  service: Service;
  /** Data que o servico foi iniciado */
  startedAt?: Maybe<Scalars['DateTime']>;
  /** Tipo de Servico */
  type: TaskTypeEnum;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

/** Task Cost */
export type TaskCost = {
  __typename?: 'TaskCost';
  /** Valor total */
  amount: Scalars['Float'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  task: Task;
  /** Tipo de preco */
  type: CostTypeEnum;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Task_Parts */
export type TaskPart = {
  __typename?: 'TaskPart';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  part: Part;
  /** Valor em R$ */
  price: Scalars['Float'];
  /** Quantidade */
  quantity: Scalars['Int'];
  /** Subtotal */
  subTotal: Scalars['Float'];
  task: Task;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export enum TaskTypeEnum {
  Corrective = 'corrective',
  Preventive = 'preventive',
}

/** Técnico */
export type Technician = {
  __typename?: 'Technician';
  additionalFee?: Maybe<Scalars['Float']>;
  address?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankAccountDigit?: Maybe<Scalars['String']>;
  bankAgency?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankOwnerDocumentNumber?: Maybe<Scalars['String']>;
  bankOwnerName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contractSignedAt?: Maybe<Scalars['DateTime']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hasReceipt?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  installationPrice?: Maybe<Scalars['Float']>;
  maintenancePrice?: Maybe<Scalars['Float']>;
  movementPrice?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  observation?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pix?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  uninstallationPrice?: Maybe<Scalars['Float']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export type TotalResponse = {
  __typename?: 'TotalResponse';
  total: Scalars['Float'];
  vehicle_id: Scalars['String'];
};

/** Infrações de transito */
export type TrafficInfraction = {
  __typename?: 'TrafficInfraction';
  /** Endereço */
  address?: Maybe<Scalars['String']>;
  /** Valor em R$ da infração */
  cost?: Maybe<Scalars['Float']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que a multa foi deletada */
  deletedAt?: Maybe<Scalars['DateTime']>;
  driver?: Maybe<Driver>;
  /** Data de vencimento */
  dueDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  infraction: Infraction;
  /** Localização */
  location?: Maybe<Scalars['jsonb']>;
  /** Observação */
  observation?: Maybe<Scalars['String']>;
  /** Data da multa */
  occurredAt: Scalars['DateTime'];
  paidAt?: Maybe<Scalars['DateTime']>;
  /** Custo pago */
  paidCost?: Maybe<Scalars['Float']>;
  /** Documento referência */
  referenceDocument?: Maybe<Scalars['String']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

export type TrafficInfractionLocationInput = {
  /** Custo */
  latitude?: InputMaybe<Scalars['Float']>;
  /** Custo */
  longitude?: InputMaybe<Scalars['Float']>;
};

/** Viagem */
export type Trip = {
  __typename?: 'Trip';
  averageSpeed?: Maybe<Scalars['Float']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  distanceTraveled?: Maybe<Scalars['Float']>;
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']>;
  eventsJson?: Maybe<Scalars['jsonb']>;
  finalStop?: Maybe<Stop>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  fuelAmountConsumedObd?: Maybe<Scalars['Float']>;
  fuelConsumedLiters?: Maybe<Scalars['Float']>;
  fuelConsumedObd?: Maybe<Scalars['Float']>;
  fuelConsumedPrice?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  initialStop: Stop;
  maximumSpeed?: Maybe<Scalars['Float']>;
  startedAt: Scalars['DateTime'];
  timeAboveMaxConfSpeed?: Maybe<Scalars['Int']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
  vehicleId: Scalars['ID'];
};

export type UpdateChecklistChoiceInput = {
  /** Choice id */
  id: Scalars['ID'];
  /** The frontend name of the choice */
  label: Scalars['String'];
  /** The frontend position */
  position?: InputMaybe<Scalars['Int']>;
};

export type UpdateChecklistFormInput = {
  id: Scalars['String'];
  /** Form title */
  title: Scalars['String'];
};

export type UpdateChecklistItemInput = {
  /** Description from this item */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Instructions on how to answer */
  instructions?: InputMaybe<Scalars['String']>;
  /** The frontend position */
  position?: InputMaybe<Scalars['Int']>;
  /** Instructions on how to answer */
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateChipInput = {
  broker?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['ID']>;
  enabledAt?: InputMaybe<Scalars['DateTime']>;
  iccid?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  msisdn?: InputMaybe<Scalars['String']>;
  telecomOperator?: InputMaybe<Scalars['String']>;
};

export type UpdateDeviceInput = {
  chipId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  installedAt?: InputMaybe<Scalars['DateTime']>;
  /** Modelo do dispositivo */
  model?: InputMaybe<Scalars['String']>;
  /** MSISDN do Chip */
  msisdn?: InputMaybe<Scalars['String']>;
  observation?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  technicianId?: InputMaybe<Scalars['ID']>;
  tenure?: InputMaybe<Scalars['String']>;
  uninstalledAt?: InputMaybe<Scalars['DateTime']>;
  /** IMEI do dispositivo */
  uniqueId?: InputMaybe<Scalars['String']>;
  vehicleId?: InputMaybe<Scalars['ID']>;
};

export type UpdateDriverInput = {
  /** Data de nascimento */
  birthday?: InputMaybe<Scalars['DateTime']>;
  canLogin?: InputMaybe<Scalars['Boolean']>;
  cnh?: InputMaybe<Scalars['String']>;
  cnhCategory?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  password?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type UpdateDriverMappingInput = {
  driverId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  valid: Scalars['Boolean'];
};

export type UpdateFeatureFlagInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type UpdateFuelTypeMappingInput = {
  fuelType?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  valid: Scalars['Boolean'];
};

export type UpdateFuellingInput = {
  /** Quantidade de combustível abastecido em L */
  amount?: InputMaybe<Scalars['Float']>;
  /** Custo em R$ do abastecimento */
  cost?: InputMaybe<Scalars['Float']>;
  /** Campo livre para inserção de observações */
  description?: InputMaybe<Scalars['String']>;
  driver?: InputMaybe<Scalars['ID']>;
  /** Encheu o tanque ou não? */
  filledUp?: InputMaybe<Scalars['Boolean']>;
  /** Tipo do combustível: diesel, gas... */
  fuelType?: InputMaybe<Scalars['String']>;
  /** Horimetro no momento do abastecimento */
  horimeter?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoiceEvidence?: InputMaybe<Scalars['ID']>;
  /** Abastecido no posto interno ou não */
  isInternal?: InputMaybe<Scalars['Boolean']>;
  /** Data que o abastecimento foi realizado */
  occurredAt?: InputMaybe<Scalars['DateTime']>;
  /** Odômetro no momento do abastecimento */
  odometer?: InputMaybe<Scalars['Float']>;
  odometerEvidence?: InputMaybe<Scalars['ID']>;
  provider?: InputMaybe<Scalars['ID']>;
  tank?: InputMaybe<Scalars['ID']>;
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type UpdateGeofenceInput = {
  /** Geometria da cerca em GeoJSON */
  area?: InputMaybe<Scalars['jsonb']>;
  /** Código hexiadecimal da cor #HEX */
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  parking?: InputMaybe<Scalars['Boolean']>;
  /** Tipo da cerca: poligon e circle */
  type?: InputMaybe<GeofenceTypeEnum>;
};

export type UpdateGroupInput = {
  id: Scalars['ID'];
  /** Nome */
  name: Scalars['String'];
  /** Organização */
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Veiculos */
  vehicles: Array<Scalars['ID']>;
};

export type UpdateMaintenanceReminderInput = {
  /** Frequência do lembrete por medida */
  frequencyMeasure?: InputMaybe<Scalars['Float']>;
  /** Frequência do lembrete por data */
  frequencyTime?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Medição na última manuteção */
  lastMeasure?: InputMaybe<Scalars['Float']>;
  /** Data da última manuteção */
  lastOccurrence?: InputMaybe<Scalars['DateTime']>;
  /** Serviço */
  service?: InputMaybe<Scalars['ID']>;
  /** Aviso com antecedência por medida */
  thresholdMeasure?: InputMaybe<Scalars['Float']>;
  /** Aviso com antecedência por data */
  thresholdTime?: InputMaybe<Scalars['Int']>;
  /** Veículo */
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type UpdateMaintenanceServiceInput = {
  category?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** Nome do serviço */
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['ID']>;
  standard?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateMaintenanceTaskInput = {
  /** Data que o serviço foi finalizado */
  completedAt?: InputMaybe<Scalars['DateTime']>;
  /** Descricao do servico */
  description?: InputMaybe<Scalars['String']>;
  driver?: InputMaybe<Scalars['ID']>;
  evidence?: InputMaybe<Scalars['ID']>;
  /** Valor do horimeter */
  horimeter?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Valor do odometro */
  odometer?: InputMaybe<Scalars['Float']>;
  organization?: InputMaybe<Scalars['ID']>;
  /** pecas */
  parts?: InputMaybe<Array<ReferenceTaskPartInput>>;
  /** precos */
  prices?: InputMaybe<Array<ReferenceTaskCostInput>>;
  provider?: InputMaybe<Scalars['ID']>;
  service?: InputMaybe<Scalars['ID']>;
  /** Data que o serviço começou */
  startedAt?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<TaskTypeEnum>;
  vehicle?: InputMaybe<Scalars['ID']>;
};

export type UpdateOpsServiceInput = {
  category?: InputMaybe<OpsServiceCategory>;
  deviceId?: InputMaybe<Scalars['ID']>;
  deviceTenure?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isTaxable?: InputMaybe<Scalars['Boolean']>;
  isTrial?: InputMaybe<Scalars['Boolean']>;
  occurredAt?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  shouldDisableVehicle?: InputMaybe<Scalars['Boolean']>;
  technicianId?: InputMaybe<Scalars['ID']>;
  useCameraData?: InputMaybe<Scalars['Boolean']>;
  vehicleId?: InputMaybe<Scalars['ID']>;
};

export type UpdateOrderInput = {
  /** Custo em R$ da ordem */
  amount?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Numero da Nota */
  invoiceNumber?: InputMaybe<Scalars['Float']>;
  /** Data da Ordem */
  occurredAt?: InputMaybe<Scalars['DateTime']>;
  organization?: InputMaybe<Scalars['ID']>;
  /** Origem da Ordem */
  source?: InputMaybe<Scalars['String']>;
  tank?: InputMaybe<Scalars['ID']>;
  /** Valor em R$ da ordem */
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdateOrganizationInput = {
  address?: InputMaybe<Scalars['String']>;
  apps?: InputMaybe<Array<Scalars['ID']>>;
  capacity?: InputMaybe<OrganizationCapacity>;
  category?: InputMaybe<Scalars['String']>;
  cnpj?: InputMaybe<Scalars['String']>;
  contractBeganAt?: InputMaybe<Scalars['DateTime']>;
  /** Data que o contrato foi finalizado */
  contractEndedAt?: InputMaybe<Scalars['DateTime']>;
  contractMonthDuration?: InputMaybe<Scalars['Float']>;
  /** Data que o acesso do cliente será cortado */
  disabledAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  monthlyPayment?: InputMaybe<Scalars['Float']>;
  /** Razão Social */
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  primaryOrganization?: InputMaybe<Scalars['ID']>;
  sector?: InputMaybe<Scalars['String']>;
  /** Configurações globais da organização */
  settings?: InputMaybe<Scalars['jsonb']>;
  signUpFee?: InputMaybe<Scalars['Float']>;
  /** Data que o cliente começou o trial */
  trialAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateProviderInput = {
  /** CNPJ do fornecedor / oficina */
  cnpj?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Nome do fornecedor ou oficina */
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type UpdateProviderMappingInput = {
  id: Scalars['ID'];
  providerId?: InputMaybe<Scalars['ID']>;
  valid: Scalars['Boolean'];
};

export type UpdateShippingInput = {
  city?: InputMaybe<Scalars['String']>;
  devices?: InputMaybe<Array<Scalars['ID']>>;
  devolution?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  occurredAt?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Scalars['String']>;
  technicianId?: InputMaybe<Scalars['ID']>;
};

export type UpdateTankInput = {
  /** Capacidade máxima do tanque */
  capacity?: InputMaybe<Scalars['Float']>;
  /** Campo livre para inserção de observações */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Volume inicial do tanque */
  initialVolume?: InputMaybe<Scalars['Float']>;
  /** Nome do tanque de combustível */
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type UpdateTechnicianInput = {
  additionalFee?: InputMaybe<Scalars['Float']>;
  address?: InputMaybe<Scalars['String']>;
  bankAccount?: InputMaybe<Scalars['String']>;
  bankAccountDigit?: InputMaybe<Scalars['String']>;
  bankAgency?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  bankOwnerDocumentNumber?: InputMaybe<Scalars['String']>;
  bankOwnerName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  contractSignedAt?: InputMaybe<Scalars['DateTime']>;
  document?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  installationPrice?: InputMaybe<Scalars['Float']>;
  maintenancePrice?: InputMaybe<Scalars['Float']>;
  movementPrice?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  observation?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  pix?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  uninstallationPrice?: InputMaybe<Scalars['Float']>;
};

export type UpdateTrafficInfractionInput = {
  /** Endereço */
  address?: InputMaybe<Scalars['String']>;
  /** Custo */
  cost?: InputMaybe<Scalars['Float']>;
  driver?: InputMaybe<Scalars['ID']>;
  /** Data de vencimento */
  dueDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  infraction: Scalars['ID'];
  /** Localização */
  location?: InputMaybe<TrafficInfractionLocationInput>;
  /** Observação */
  observation?: InputMaybe<Scalars['String']>;
  /** Data da multa */
  occurredAt: Scalars['DateTime'];
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Pago em... */
  paidAt?: InputMaybe<Scalars['DateTime']>;
  /** Custo Pago */
  paidCost?: InputMaybe<Scalars['Float']>;
  /** Documento de referência */
  referenceDocument?: InputMaybe<Scalars['String']>;
  vehicle: Scalars['ID'];
};

export type UpdateUserInput = {
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  modules?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  /** primary user organization */
  organization?: InputMaybe<Scalars['ID']>;
  organizations?: InputMaybe<Array<Scalars['ID']>>;
  role?: InputMaybe<UserRole>;
};

export type UpdateVehicleInput = {
  /** Autonomia do veículo */
  autonomy?: InputMaybe<Scalars['Float']>;
  avatarType?: InputMaybe<VehicleTypeEnum>;
  /** Saturação da bateria */
  basePower?: InputMaybe<Scalars['Float']>;
  /** Marca, ex: GM, Nissan, Toyota... */
  brand?: InputMaybe<Scalars['String']>;
  /** Veículo desabilitado */
  disabledAt?: InputMaybe<Scalars['DateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  /** Documento */
  document?: InputMaybe<Scalars['String']>;
  /** Tipo do combustível: diesel, gas etc. */
  fuelType?: InputMaybe<FuelTypeEnum>;
  id: Scalars['ID'];
  lastDriverToken?: InputMaybe<Scalars['String']>;
  /** Velocidade máxima */
  maxSpeed?: InputMaybe<Scalars['Float']>;
  /** Volume máximo */
  maximumVolume?: InputMaybe<Scalars['Int']>;
  /** Carga máxima */
  maximumWeight?: InputMaybe<Scalars['Int']>;
  /** Velocidade mínima para ser considerado como parado */
  minSpeed?: InputMaybe<Scalars['Float']>;
  /** Duração mínima do estacionamento */
  minimalParkingDuration?: InputMaybe<Scalars['Float']>;
  /** Modelo, ex: Fiesta, March, C3... */
  model?: InputMaybe<Scalars['String']>;
  /** Pagamento Recorrente Mensal */
  monthlyPayment?: InputMaybe<Scalars['Float']>;
  /** Observação */
  observation?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Identificação */
  plate?: InputMaybe<Scalars['String']>;
  /** Preço */
  price?: InputMaybe<Scalars['Float']>;
  products?: InputMaybe<Array<Scalars['String']>>;
  /** Configurações globais do veículo */
  settings?: InputMaybe<Scalars['jsonb']>;
  /** Taxa de Adesão */
  signUpFee?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<Scalars['String']>;
  /** Data de início da cobrança */
  taxabledAt?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
  useCameraData?: InputMaybe<Scalars['Boolean']>;
  useObdData?: InputMaybe<Scalars['Boolean']>;
  /** Ano do veículo */
  year?: InputMaybe<Scalars['String']>;
};

export type UpdateVehicleMappingInput = {
  id: Scalars['ID'];
  valid: Scalars['Boolean'];
  vehicleId?: InputMaybe<Scalars['ID']>;
};

export type UpdateWebhookInput = {
  /** POST URL para onde os dados serão enviados */
  endpoint?: InputMaybe<Scalars['String']>;
  headers?: InputMaybe<Scalars['jsonb']>;
  id: Scalars['ID'];
  sendEvents?: InputMaybe<Scalars['Boolean']>;
  sendPositions?: InputMaybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  /** Lista de veículos que o usuário tem controle */
  availableVehicles: Array<Vehicle>;
  cpf?: Maybe<Scalars['String']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data que a usuario foi deletado */
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  /** Data de verificação do e-mail */
  emailVerifiedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** Modulos */
  modules: Scalars['jsonb'];
  name?: Maybe<Scalars['String']>;
  /** Organização principal do usuário */
  organization: Organization;
  /** Lista de organizações a qual o usuário tem acesso */
  organizations: Array<Organization>;
  /** RBAC: https://casl.js.org/ */
  permissions: Scalars['jsonb'];
  role: UserRole;
  /** Configurações globais da conta do usuário */
  settings: Scalars['jsonb'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Veículos diretamente associados ao usuário */
  vehicles: Array<Vehicle>;
};

/** Funcionalidades que estão liberadas para o usuário logado */
export type UserFeatureFlag = {
  __typename?: 'UserFeatureFlag';
  /** Status da funcionalidade */
  enabled: Scalars['Boolean'];
  /** Código da funcionalidade */
  feature: Scalars['String'];
};

export type UserFilterInput = {
  /** cpf do usuário */
  cpf?: InputMaybe<Scalars['String']>;
  /** Email do usuário */
  email?: InputMaybe<Scalars['String']>;
  /** Nome do usuário */
  name?: InputMaybe<Scalars['String']>;
  /** Nome ou cnpj da organização do usuário */
  organization?: InputMaybe<Scalars['String']>;
  /** Cargo do usuário */
  role?: InputMaybe<Scalars['String']>;
};

export type UserPaginate = {
  __typename?: 'UserPaginate';
  /** Total de registros */
  total: Scalars['Int'];
  /** Usuários */
  users: Array<User>;
};

export enum UserRole {
  Admin = 'admin',
  Director = 'director',
  Driver = 'driver',
  Manager = 'manager',
  User = 'user',
}

/** Veículo */
export type Vehicle = {
  __typename?: 'Vehicle';
  /** Autonomia do veículo */
  autonomy?: Maybe<Scalars['Float']>;
  avatarType: VehicleTypeEnum;
  /** Saturação da bateria */
  basePower?: Maybe<Scalars['Float']>;
  /** Marca, ex: GM, Nissan, Toyota... */
  brand?: Maybe<Scalars['String']>;
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Veículo desativado pelo cliente */
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  device?: Maybe<Device>;
  disabled: Scalars['Boolean'];
  /** Veículo desabilitado */
  disabledAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  /** Documento */
  document?: Maybe<Scalars['String']>;
  driver?: Maybe<Driver>;
  /** @deprecated Não será mais utilizado nas futuras versões */
  externalId?: Maybe<Scalars['String']>;
  fuelLevel?: Maybe<Scalars['Float']>;
  /** Tipo do combustível: diesel, gas etc. */
  fuelType?: Maybe<FuelTypeEnum>;
  groups: Array<Group>;
  horimeter: Scalars['Float'];
  horimeterAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastDriverToken?: Maybe<Scalars['String']>;
  lastHorimeterHistory?: Maybe<HorimeterHistory>;
  lastOdometerHistory?: Maybe<OdometerHistory>;
  location?: Maybe<Location>;
  /** Velocidade máxima */
  maxSpeed?: Maybe<Scalars['Float']>;
  /** Volume máximo */
  maximumVolume?: Maybe<Scalars['Int']>;
  /** Carga máxima */
  maximumWeight?: Maybe<Scalars['Int']>;
  /** Velocidade mínima para ser considerado como parado */
  minSpeed?: Maybe<Scalars['Float']>;
  /** Duração mínima do estacionamento */
  minimalParkingDuration?: Maybe<Scalars['Float']>;
  /** Modelo, ex: Fiesta, March, C3... */
  model?: Maybe<Scalars['String']>;
  /** Pagamento Recorrente Mensal */
  monthlyPayment?: Maybe<Scalars['Float']>;
  /** Observação */
  observation?: Maybe<Scalars['String']>;
  odometer: Scalars['Float'];
  odometerAt?: Maybe<Scalars['DateTime']>;
  organization: Organization;
  /** Lista de organizações que o veículo tá compartilhado */
  organizations: Array<Organization>;
  plate: Scalars['String'];
  pluggedStatus?: Maybe<Scalars['Boolean']>;
  pluggedStatusAt?: Maybe<Scalars['DateTime']>;
  /** Preço */
  price?: Maybe<Scalars['Float']>;
  products?: Maybe<Scalars['jsonb']>;
  /** Configurações globais do veículo */
  settings: Scalars['jsonb'];
  /** Taxa de Adesão */
  signUpFee?: Maybe<Scalars['Float']>;
  /** @deprecated Não será mais utilizado nas futuras versões */
  source?: Maybe<Scalars['String']>;
  /** @deprecated Should use the location.status instead, this should be removed in next releases */
  status: VehicleStatus;
  /** Volume máximo do tanque (L) */
  tankSize?: Maybe<Scalars['Int']>;
  /** Data de início da cobrança */
  taxabledAt?: Maybe<Scalars['DateTime']>;
  type: VehicleTypeEnum;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  /** Determina se front-end/mobile devem considerar ou não os dados da câmera do veículo */
  useCameraData: Scalars['Boolean'];
  /** Determina se o processamento deve considerar ou não os dados da rede CAN */
  useObdData: Scalars['Boolean'];
  /** Ano do veículo */
  year?: Maybe<Scalars['String']>;
};

export enum VehicleCameraEnum {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
}

/** Histórico de troca de motorista */
export type VehicleDriverChangeHistory = {
  __typename?: 'VehicleDriverChangeHistory';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  currentDriver?: Maybe<Driver>;
  currentDriverToken?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Data da troca de motorista */
  occurredAt: Scalars['DateTime'];
  position?: Maybe<Position>;
  previousDriver?: Maybe<Driver>;
  previousDriverToken?: Maybe<Scalars['String']>;
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
};

/** Histórico de motorista */
export type VehicleDriverHistory = {
  __typename?: 'VehicleDriverHistory';
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['ID']>;
  driverTokenId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  occurredAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  vehicle?: Maybe<Vehicle>;
  vehicleId?: Maybe<Scalars['ID']>;
};

export type VehicleDriverHistoryPaginate = {
  __typename?: 'VehicleDriverHistoryPaginate';
  /** Página atual */
  pageNumber: Scalars['Int'];
  /** Tamanho da página */
  pageSize: Scalars['Int'];
  /** Total de registros */
  total: Scalars['Int'];
  /** Total de páginas */
  totalPages: Scalars['Int'];
  /** Histórico */
  vehicleDriverHistories: Array<VehicleDriverHistory>;
};

export type VehicleFilterInput = {
  /** Desabilitado */
  disabled?: InputMaybe<Scalars['Boolean']>;
  /** Apelido */
  displayName?: InputMaybe<Scalars['String']>;
  /** Veículo tem dispositivo */
  hasDevice?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Nome da organização */
  organizationName?: InputMaybe<Scalars['String']>;
  /** Placa */
  plate?: InputMaybe<Scalars['String']>;
};

/** Mapeamento de veículos */
export type VehicleMapping = {
  __typename?: 'VehicleMapping';
  attributes: Scalars['jsonb'];
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Data de desativação */
  disabledAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id: Scalars['ID'];
  integration: Integration;
  /** @deprecated Should use integration.id instead */
  integrationId: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  /** @deprecated Should use organization.id instead */
  organizationId: Scalars['ID'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  valid: Scalars['Boolean'];
  vehicle?: Maybe<Vehicle>;
  /** @deprecated Should use vehicle.id instead */
  vehicleId?: Maybe<Scalars['ID']>;
};

export type VehiclePaginate = {
  __typename?: 'VehiclePaginate';
  /** Total de registros */
  total: Scalars['Int'];
  /** Veículos */
  vehicles: Array<Vehicle>;
};

export type VehicleRanking = {
  __typename?: 'VehicleRanking';
  avgSpeed: Scalars['Float'];
  engineIdleByDay: Scalars['jsonb'];
  maxSpeed: Scalars['Float'];
  percentageTimeEngineIdle: Scalars['Float'];
  telemetrySummary: Scalars['jsonb'];
  totalTelemetryEvents: Scalars['Int'];
  totalTraveled: Scalars['Float'];
  vehicleId: Scalars['ID'];
};

export type VehicleReport = {
  __typename?: 'VehicleReport';
  /** Autonomia do veiculo da tabela de abastecimento */
  autonomy?: Maybe<Scalars['Float']>;
  /** Custo por hora */
  costPerHour: Scalars['Float'];
  /** Custo por km */
  costPerKM: Scalars['Float'];
  /** Distancia percorrida em metros da tabela de viagem */
  distanceTraveled?: Maybe<Scalars['Float']>;
  /** valor das despesas */
  expenditure: Scalars['Float'];
  /** Quantidade de combustivel abastecimento */
  fuelConsumedLiters?: Maybe<Scalars['Float']>;
  /** Custo de combustivel da tabela de abastecimento */
  fuelConsumedPrice?: Maybe<Scalars['Float']>;
  /** Distancia percorrida em metros da tabela de abastecimento */
  fuelDistanceTraveled?: Maybe<Scalars['Float']>;
  /** Tempo gasto em ms da tabela de abastecimento */
  fuelTimeTraveled?: Maybe<Scalars['Float']>;
  /** Quantidade de manutenções */
  maintenance: Scalars['Float'];
  /** Custo das menutenções */
  maintenancePrice: Scalars['Float'];
  /** Competência do report */
  startAt: Scalars['String'];
  /** Tempo gasto em ms da tabela de viagem */
  timeTraveled?: Maybe<Scalars['Float']>;
  /** Total de gastos */
  totalCost: Scalars['Float'];
  /** Autonomia do veiculo da tabela de viegens */
  tripAutonomy?: Maybe<Scalars['Float']>;
  /** Quantidade de combustivel consumido da tabela de viagem */
  tripFuelConsumedLiters?: Maybe<Scalars['Float']>;
  /** Custo de combustivel da tabela de viagem */
  tripFuelConsumedPrice?: Maybe<Scalars['Float']>;
};

export type VehicleReportInput = {
  /** Data final do filtro */
  endAt: Scalars['DateTime'];
  organizationId?: InputMaybe<Scalars['ID']>;
  /** Data inicial do filtro */
  startAt: Scalars['DateTime'];
  vehicleId: Scalars['ID'];
};

export enum VehicleStatus {
  Off = 'OFF',
  On = 'ON',
  Unknown = 'UNKNOWN',
}

/** Relatório diário de telemetria */
export type VehicleSummary = {
  __typename?: 'VehicleSummary';
  averageSpeed: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  distanceTraveled: Scalars['Float'];
  endAt: Scalars['DateTime'];
  engineIdle: Scalars['Float'];
  eventOccurrences: EventOccurrences;
  /** @deprecated This field is not valid, should not be used */
  id: Scalars['ID'];
  maximumSpeed: Scalars['Float'];
  positions: Scalars['Int'];
  startAt: Scalars['DateTime'];
  timeOverSpeed: Scalars['Int'];
  timeStopped: Scalars['Int'];
  timeStoppedWithIgnitionOn: Scalars['Int'];
  timeWithIgnitionOn: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  vehicle: Vehicle;
  vehicleId: Scalars['ID'];
};

/** Tipo do veículo */
export type VehicleType = {
  __typename?: 'VehicleType';
  name: Scalars['String'];
  slugName: VehicleTypeEnum;
};

export enum VehicleTypeEnum {
  Bus = 'bus',
  Car = 'car',
  Equipment = 'equipment',
  Forklift = 'forklift',
  GarbageCollector = 'garbage_collector',
  Motorcycle = 'motorcycle',
  Trailer = 'trailer',
  Truck = 'truck',
}

export type VehicleVideoResponse = {
  __typename?: 'VehicleVideoResponse';
  /** Mensagem da resposta da requisição */
  message: Scalars['String'];
  /** Se requisição retornou com sucesso ou não */
  success: Scalars['Boolean'];
};

export type VehiclesFuellingSummaryResponse = {
  __typename?: 'VehiclesFuellingSummaryResponse';
  amount: Scalars['Float'];
  average_autonomy_from_horimeter?: Maybe<Scalars['Float']>;
  average_autonomy_from_odometer?: Maybe<Scalars['Float']>;
  average_cost: Scalars['Float'];
  average_unit_cost: Scalars['Float'];
  fuel_used_from_can?: Maybe<Scalars['Float']>;
  fuellings: Scalars['Float'];
  horimeter_autonomy_from_can?: Maybe<Scalars['Float']>;
  maximum_autonomy_from_horimeter?: Maybe<Scalars['Float']>;
  maximum_autonomy_from_odometer?: Maybe<Scalars['Float']>;
  maximum_cost: Scalars['Float'];
  maximum_unit_cost: Scalars['Float'];
  minimum_autonomy_from_horimeter?: Maybe<Scalars['Float']>;
  minimum_autonomy_from_odometer?: Maybe<Scalars['Float']>;
  minimum_cost: Scalars['Float'];
  minimum_unit_cost: Scalars['Float'];
  odometer_autonomy_from_can?: Maybe<Scalars['Float']>;
  total_cost: Scalars['Float'];
  vehicle_id: Scalars['String'];
};

/** Integração via Webhook */
export type Webhook = {
  __typename?: 'Webhook';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** POST URL to send positions */
  endpoint: Scalars['String'];
  headers: Scalars['jsonb'];
  id: Scalars['ID'];
  /** Data do envio da última mensagem */
  lastRequestAt?: Maybe<Scalars['DateTime']>;
  /** Conteúdo recebido na última requisição */
  lastRequestResponse?: Maybe<Scalars['String']>;
  /** Código HTTP recebido na última requisição */
  lastRequestStatus?: Maybe<Scalars['String']>;
  organization: Organization;
  /** Devem ser enviadas os eventos? */
  sendEvents: Scalars['Boolean'];
  /** Devem ser enviadas as posições? */
  sendPositions: Scalars['Boolean'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

/** Jornada */
export type WorkShift = {
  __typename?: 'WorkShift';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  /** Final da jornada */
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  organization: Organization;
  /** Inicio da jornada */
  startedAt: Scalars['DateTime'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
  user: User;
  vehicle: Vehicle;
};

/** Tipo de eventos de uma Jornada */
export type WorkShiftEventType = {
  __typename?: 'WorkShiftEventType';
  /** Data de criação */
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  /** Nome do icone do material-icons */
  iconName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  slugName: Scalars['String'];
  /** Data de modificação */
  updatedAt: Scalars['DateTime'];
};

export type AppModuleFragment = {
  __typename?: 'AppModule';
  name: string;
  slugName: string;
};

export type AppFragment = { __typename?: 'App'; id: string; name: string };

export type AppsQueryVariables = Exact<{ [key: string]: never }>;

export type AppsQuery = {
  __typename?: 'Query';
  apps: Array<{ __typename?: 'App'; id: string; name: string }>;
};

export type ModulesQueryVariables = Exact<{ [key: string]: never }>;

export type ModulesQuery = {
  __typename?: 'Query';
  modules: Array<{ __typename?: 'AppModule'; name: string; slugName: string }>;
};

export type AuthorizationFragment = {
  __typename?: 'Authorization';
  accessToken: string;
  refreshToken?: string | null;
  type: string;
};

export type AuthorizeMutationVariables = Exact<{
  userId: Scalars['ID'];
  appName: Scalars['String'];
}>;

export type AuthorizeMutation = {
  __typename?: 'Mutation';
  authorize: {
    __typename?: 'Authorization';
    accessToken: string;
    refreshToken?: string | null;
    type: string;
  };
};

export type LoginWithCredentialsMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginWithCredentialsMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'Authorization';
    accessToken: string;
    refreshToken?: string | null;
    user: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      cpf?: string | null;
      email?: string | null;
      role: UserRole;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
      settings: any;
      modules: any;
      permissions: any;
      organizations: Array<{
        __typename?: 'Organization';
        id: string;
        name: string;
        cnpj?: string | null;
        disabledAt?: any | null;
        createdAt: any;
        trialAt?: any | null;
        contractEndedAt?: any | null;
        address?: string | null;
        phone?: string | null;
        settings: any;
        primaryOrganization?: {
          __typename?: 'Organization';
          id: string;
          name: string;
          cnpj?: string | null;
        } | null;
      }>;
    };
  };
};

export type RequestPasswordRecoveryMutationVariables = Exact<{
  username: Scalars['String'];
}>;

export type RequestPasswordRecoveryMutation = {
  __typename?: 'Mutation';
  requestPasswordRecovery: boolean;
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: boolean;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    cpf?: string | null;
    email?: string | null;
    role: UserRole;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    settings: any;
    modules: any;
    permissions: any;
    organizations: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
      disabledAt?: any | null;
      createdAt: any;
      trialAt?: any | null;
      contractEndedAt?: any | null;
      address?: string | null;
      phone?: string | null;
      settings: any;
      primaryOrganization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        cnpj?: string | null;
      } | null;
    }>;
  };
};

export type DriverFragment = {
  __typename?: 'Driver';
  id: string;
  name?: string | null;
  cpf?: string | null;
  cnh?: string | null;
  token?: string | null;
  user?: { __typename?: 'User'; id: string } | null;
};

export type CreateOrganizationMutationVariables = Exact<{
  payload: CreateOrganizationInput;
}>;

export type CreateOrganizationMutation = {
  __typename?: 'Mutation';
  createOrganization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    cnpj?: string | null;
    disabledAt?: any | null;
    createdAt: any;
    trialAt?: any | null;
    contractEndedAt?: any | null;
    address?: string | null;
    phone?: string | null;
    settings: any;
    apps: Array<{ __typename?: 'App'; id: string; name: string }>;
    primaryOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
    } | null;
  };
};

export type OrganizationWithAppsFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  cnpj?: string | null;
  disabledAt?: any | null;
  createdAt: any;
  trialAt?: any | null;
  contractEndedAt?: any | null;
  address?: string | null;
  phone?: string | null;
  settings: any;
  apps: Array<{ __typename?: 'App'; id: string; name: string }>;
  primaryOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    cnpj?: string | null;
  } | null;
};

export type OrganizationWithUsersFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  cnpj?: string | null;
  disabledAt?: any | null;
  createdAt: any;
  trialAt?: any | null;
  contractEndedAt?: any | null;
  address?: string | null;
  phone?: string | null;
  settings: any;
  users: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    cpf?: string | null;
    email?: string | null;
    role: UserRole;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    settings: any;
    modules: any;
    permissions: any;
    organizations: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
      disabledAt?: any | null;
      createdAt: any;
      trialAt?: any | null;
      contractEndedAt?: any | null;
      address?: string | null;
      phone?: string | null;
      settings: any;
      primaryOrganization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        cnpj?: string | null;
      } | null;
    }>;
  }>;
  primaryOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    cnpj?: string | null;
  } | null;
};

export type OrganizationWithUsersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrganizationWithUsersQuery = {
  __typename?: 'Query';
  organizations: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    cnpj?: string | null;
    disabledAt?: any | null;
    createdAt: any;
    trialAt?: any | null;
    contractEndedAt?: any | null;
    address?: string | null;
    phone?: string | null;
    settings: any;
    users: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      cpf?: string | null;
      email?: string | null;
      role: UserRole;
      createdAt: any;
      updatedAt: any;
      deletedAt?: any | null;
      settings: any;
      modules: any;
      permissions: any;
      organizations: Array<{
        __typename?: 'Organization';
        id: string;
        name: string;
        cnpj?: string | null;
        disabledAt?: any | null;
        createdAt: any;
        trialAt?: any | null;
        contractEndedAt?: any | null;
        address?: string | null;
        phone?: string | null;
        settings: any;
        primaryOrganization?: {
          __typename?: 'Organization';
          id: string;
          name: string;
          cnpj?: string | null;
        } | null;
      }>;
    }>;
    primaryOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
    } | null;
  }>;
};

export type OrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  cnpj?: string | null;
  disabledAt?: any | null;
  createdAt: any;
  trialAt?: any | null;
  contractEndedAt?: any | null;
  address?: string | null;
  phone?: string | null;
  settings: any;
  primaryOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    cnpj?: string | null;
  } | null;
};

export type UpdateOrganizationMutationVariables = Exact<{
  payload: UpdateOrganizationInput;
}>;

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    cnpj?: string | null;
    disabledAt?: any | null;
    createdAt: any;
    trialAt?: any | null;
    contractEndedAt?: any | null;
    address?: string | null;
    phone?: string | null;
    settings: any;
    apps: Array<{ __typename?: 'App'; id: string; name: string }>;
    primaryOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
    } | null;
  };
};

export type ProviderFragment = {
  __typename?: 'Provider';
  id: string;
  name: string;
  cnpj?: string | null;
  source: string;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    cnpj?: string | null;
    disabledAt?: any | null;
    createdAt: any;
    trialAt?: any | null;
    contractEndedAt?: any | null;
    address?: string | null;
    phone?: string | null;
    settings: any;
    primaryOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
    } | null;
  };
};

export type ProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type ProvidersQuery = {
  __typename?: 'Query';
  providers: Array<{
    __typename?: 'Provider';
    id: string;
    name: string;
    cnpj?: string | null;
    source: string;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
      disabledAt?: any | null;
      createdAt: any;
      trialAt?: any | null;
      contractEndedAt?: any | null;
      address?: string | null;
      phone?: string | null;
      settings: any;
      primaryOrganization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        cnpj?: string | null;
      } | null;
    };
  }>;
};

export type CreateUserMutationVariables = Exact<{
  payload: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    cpf?: string | null;
    email?: string | null;
    role: UserRole;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    settings: any;
    modules: any;
    permissions: any;
    organizations: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
      disabledAt?: any | null;
      createdAt: any;
      trialAt?: any | null;
      contractEndedAt?: any | null;
      address?: string | null;
      phone?: string | null;
      settings: any;
      primaryOrganization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        cnpj?: string | null;
      } | null;
    }>;
  };
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser: boolean;
};

export type UserWithVehiclesFragment = {
  __typename?: 'User';
  id: string;
  name?: string | null;
  cpf?: string | null;
  email?: string | null;
  role: UserRole;
  createdAt: any;
  updatedAt: any;
  deletedAt?: any | null;
  settings: any;
  modules: any;
  permissions: any;
  availableVehicles: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null;
    model?: string | null;
    brand?: string | null;
    type: VehicleTypeEnum;
    year?: string | null;
    fuelType?: FuelTypeEnum | null;
    document?: string | null;
    observation?: string | null;
    avatarType: VehicleTypeEnum;
    price?: number | null;
    lastDriverToken?: string | null;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null;
    min_speed?: number | null;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null;
    plugged_status_at?: any | null;
  }>;
  organizations: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    cnpj?: string | null;
    disabledAt?: any | null;
    createdAt: any;
    trialAt?: any | null;
    contractEndedAt?: any | null;
    address?: string | null;
    phone?: string | null;
    settings: any;
    primaryOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
    } | null;
  }>;
};

export type UserWithVehiclesQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserWithVehiclesQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    cpf?: string | null;
    email?: string | null;
    role: UserRole;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    settings: any;
    modules: any;
    permissions: any;
    availableVehicles: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null;
      model?: string | null;
      brand?: string | null;
      type: VehicleTypeEnum;
      year?: string | null;
      fuelType?: FuelTypeEnum | null;
      document?: string | null;
      observation?: string | null;
      avatarType: VehicleTypeEnum;
      price?: number | null;
      lastDriverToken?: string | null;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null;
      min_speed?: number | null;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null;
      plugged_status_at?: any | null;
    }>;
    organizations: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
      disabledAt?: any | null;
      createdAt: any;
      trialAt?: any | null;
      contractEndedAt?: any | null;
      address?: string | null;
      phone?: string | null;
      settings: any;
      primaryOrganization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        cnpj?: string | null;
      } | null;
    }>;
  };
};

export type UserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    permissions: any;
    role: UserRole;
    settings: any;
    cpf?: string | null;
    email?: string | null;
    modules: any;
    name?: string | null;
    id: string;
    organization: { __typename?: 'Organization'; id: string; name: string };
  };
};

export type UserFragment = {
  __typename?: 'User';
  id: string;
  name?: string | null;
  cpf?: string | null;
  email?: string | null;
  role: UserRole;
  createdAt: any;
  updatedAt: any;
  deletedAt?: any | null;
  settings: any;
  modules: any;
  permissions: any;
  organizations: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    cnpj?: string | null;
    disabledAt?: any | null;
    createdAt: any;
    trialAt?: any | null;
    contractEndedAt?: any | null;
    address?: string | null;
    phone?: string | null;
    settings: any;
    primaryOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
    } | null;
  }>;
};

export type UpdateUserMutationVariables = Exact<{
  payload: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    cpf?: string | null;
    email?: string | null;
    role: UserRole;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    settings: any;
    modules: any;
    permissions: any;
    organizations: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
      disabledAt?: any | null;
      createdAt: any;
      trialAt?: any | null;
      contractEndedAt?: any | null;
      address?: string | null;
      phone?: string | null;
      settings: any;
      primaryOrganization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        cnpj?: string | null;
      } | null;
    }>;
  };
};

export type UsersWithVehiclesQueryVariables = Exact<{ [key: string]: never }>;

export type UsersWithVehiclesQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    cpf?: string | null;
    email?: string | null;
    role: UserRole;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    settings: any;
    modules: any;
    permissions: any;
    availableVehicles: Array<{
      __typename?: 'Vehicle';
      id: string;
      plate: string;
      displayName?: string | null;
      model?: string | null;
      brand?: string | null;
      type: VehicleTypeEnum;
      year?: string | null;
      fuelType?: FuelTypeEnum | null;
      document?: string | null;
      observation?: string | null;
      avatarType: VehicleTypeEnum;
      price?: number | null;
      lastDriverToken?: string | null;
      odometer: number;
      horimeter: number;
      fuelLevel?: number | null;
      settings: any;
      useObdData: boolean;
      max_speed?: number | null;
      min_speed?: number | null;
      created_at: any;
      updated_at: any;
      plugged_status?: boolean | null;
      plugged_status_at?: any | null;
    }>;
    organizations: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
      disabledAt?: any | null;
      createdAt: any;
      trialAt?: any | null;
      contractEndedAt?: any | null;
      address?: string | null;
      phone?: string | null;
      settings: any;
      primaryOrganization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        cnpj?: string | null;
      } | null;
    }>;
  }>;
};

export type UsersQueryVariables = Exact<{
  organization?: InputMaybe<Scalars['ID']>;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    cpf?: string | null;
    email?: string | null;
    role: UserRole;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    settings: any;
    modules: any;
    permissions: any;
    organizations: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
      disabledAt?: any | null;
      createdAt: any;
      trialAt?: any | null;
      contractEndedAt?: any | null;
      address?: string | null;
      phone?: string | null;
      settings: any;
      primaryOrganization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        cnpj?: string | null;
      } | null;
    }>;
  }>;
};

export type UpdateVehicleMutationVariables = Exact<{
  payload: UpdateVehicleInput;
}>;

export type UpdateVehicleMutation = {
  __typename?: 'Mutation';
  updateVehicle: {
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null;
    model?: string | null;
    brand?: string | null;
    type: VehicleTypeEnum;
    year?: string | null;
    fuelType?: FuelTypeEnum | null;
    document?: string | null;
    observation?: string | null;
    avatarType: VehicleTypeEnum;
    price?: number | null;
    lastDriverToken?: string | null;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null;
    min_speed?: number | null;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null;
    plugged_status_at?: any | null;
  };
};

export type VehicleWithDriverFragment = {
  __typename?: 'Vehicle';
  id: string;
  plate: string;
  displayName?: string | null;
  model?: string | null;
  brand?: string | null;
  type: VehicleTypeEnum;
  year?: string | null;
  fuelType?: FuelTypeEnum | null;
  document?: string | null;
  observation?: string | null;
  avatarType: VehicleTypeEnum;
  price?: number | null;
  lastDriverToken?: string | null;
  odometer: number;
  horimeter: number;
  fuelLevel?: number | null;
  settings: any;
  useObdData: boolean;
  max_speed?: number | null;
  min_speed?: number | null;
  created_at: any;
  updated_at: any;
  plugged_status?: boolean | null;
  plugged_status_at?: any | null;
  driver?: {
    __typename?: 'Driver';
    id: string;
    name?: string | null;
    cpf?: string | null;
    cnh?: string | null;
    token?: string | null;
    user?: { __typename?: 'User'; id: string } | null;
  } | null;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    cnpj?: string | null;
    disabledAt?: any | null;
    createdAt: any;
    trialAt?: any | null;
    contractEndedAt?: any | null;
    address?: string | null;
    phone?: string | null;
    settings: any;
    primaryOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
    } | null;
  };
};

export type VehicleFragment = {
  __typename?: 'Vehicle';
  id: string;
  plate: string;
  displayName?: string | null;
  model?: string | null;
  brand?: string | null;
  type: VehicleTypeEnum;
  year?: string | null;
  fuelType?: FuelTypeEnum | null;
  document?: string | null;
  observation?: string | null;
  avatarType: VehicleTypeEnum;
  price?: number | null;
  lastDriverToken?: string | null;
  odometer: number;
  horimeter: number;
  fuelLevel?: number | null;
  settings: any;
  useObdData: boolean;
  max_speed?: number | null;
  min_speed?: number | null;
  created_at: any;
  updated_at: any;
  plugged_status?: boolean | null;
  plugged_status_at?: any | null;
};

export type VehiclesQueryVariables = Exact<{ [key: string]: never }>;

export type VehiclesQuery = {
  __typename?: 'Query';
  vehicles: Array<{
    __typename?: 'Vehicle';
    id: string;
    plate: string;
    displayName?: string | null;
    model?: string | null;
    brand?: string | null;
    type: VehicleTypeEnum;
    year?: string | null;
    fuelType?: FuelTypeEnum | null;
    document?: string | null;
    observation?: string | null;
    avatarType: VehicleTypeEnum;
    price?: number | null;
    lastDriverToken?: string | null;
    odometer: number;
    horimeter: number;
    fuelLevel?: number | null;
    settings: any;
    useObdData: boolean;
    max_speed?: number | null;
    min_speed?: number | null;
    created_at: any;
    updated_at: any;
    plugged_status?: boolean | null;
    plugged_status_at?: any | null;
    driver?: {
      __typename?: 'Driver';
      id: string;
      name?: string | null;
      cpf?: string | null;
      cnh?: string | null;
      token?: string | null;
      user?: { __typename?: 'User'; id: string } | null;
    } | null;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      cnpj?: string | null;
      disabledAt?: any | null;
      createdAt: any;
      trialAt?: any | null;
      contractEndedAt?: any | null;
      address?: string | null;
      phone?: string | null;
      settings: any;
      primaryOrganization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        cnpj?: string | null;
      } | null;
    };
  }>;
};

export const AppModuleFragmentDoc = gql`
  fragment AppModule on AppModule {
    name
    slugName
  }
`;
export const AuthorizationFragmentDoc = gql`
  fragment Authorization on Authorization {
    accessToken
    refreshToken
    type
  }
`;
export const OrganizationFragmentDoc = gql`
  fragment Organization on Organization {
    id
    name
    cnpj
    disabledAt
    createdAt
    trialAt
    contractEndedAt
    disabledAt
    address
    phone
    settings
    primaryOrganization {
      id
      name
      cnpj
    }
  }
`;
export const AppFragmentDoc = gql`
  fragment App on App {
    id
    name
  }
`;
export const OrganizationWithAppsFragmentDoc = gql`
  fragment OrganizationWithApps on Organization {
    ...Organization
    apps {
      ...App
    }
  }
  ${OrganizationFragmentDoc}
  ${AppFragmentDoc}
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    name
    cpf
    email
    role
    createdAt
    updatedAt
    deletedAt
    settings
    modules
    permissions
    organizations {
      ...Organization
    }
  }
  ${OrganizationFragmentDoc}
`;
export const OrganizationWithUsersFragmentDoc = gql`
  fragment OrganizationWithUsers on Organization {
    ...Organization
    users {
      ...User
    }
  }
  ${OrganizationFragmentDoc}
  ${UserFragmentDoc}
`;
export const ProviderFragmentDoc = gql`
  fragment Provider on Provider {
    id
    name
    cnpj
    source
    organization {
      ...Organization
    }
  }
  ${OrganizationFragmentDoc}
`;
export const VehicleFragmentDoc = gql`
  fragment Vehicle on Vehicle {
    id
    plate
    displayName
    model
    brand
    type
    year
    fuelType
    document
    observation
    avatarType
    price
    lastDriverToken
    odometer
    horimeter
    fuelLevel
    max_speed: maxSpeed
    min_speed: minSpeed
    brand
    settings
    useObdData
    created_at: createdAt
    updated_at: updatedAt
    plugged_status: pluggedStatus
    plugged_status_at: pluggedStatusAt
  }
`;
export const UserWithVehiclesFragmentDoc = gql`
  fragment UserWithVehicles on User {
    ...User
    availableVehicles {
      ...Vehicle
    }
  }
  ${UserFragmentDoc}
  ${VehicleFragmentDoc}
`;
export const DriverFragmentDoc = gql`
  fragment Driver on Driver {
    id
    name
    cpf
    cnh
    token
    user {
      id
    }
  }
`;
export const VehicleWithDriverFragmentDoc = gql`
  fragment VehicleWithDriver on Vehicle {
    ...Vehicle
    driver {
      ...Driver
    }
    organization {
      ...Organization
    }
  }
  ${VehicleFragmentDoc}
  ${DriverFragmentDoc}
  ${OrganizationFragmentDoc}
`;
export const AppsDocument = gql`
  query apps {
    apps {
      ...App
    }
  }
  ${AppFragmentDoc}
`;

/**
 * __useAppsQuery__
 *
 * To run a query within a React component, call `useAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppsQuery(
  baseOptions?: Apollo.QueryHookOptions<AppsQuery, AppsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppsQuery, AppsQueryVariables>(AppsDocument, options);
}
export function useAppsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppsQuery, AppsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppsQuery, AppsQueryVariables>(
    AppsDocument,
    options,
  );
}
export type AppsQueryHookResult = ReturnType<typeof useAppsQuery>;
export type AppsLazyQueryHookResult = ReturnType<typeof useAppsLazyQuery>;
export type AppsQueryResult = Apollo.QueryResult<AppsQuery, AppsQueryVariables>;
export const ModulesDocument = gql`
  query modules {
    modules {
      ...AppModule
    }
  }
  ${AppModuleFragmentDoc}
`;

/**
 * __useModulesQuery__
 *
 * To run a query within a React component, call `useModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useModulesQuery(
  baseOptions?: Apollo.QueryHookOptions<ModulesQuery, ModulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ModulesQuery, ModulesQueryVariables>(
    ModulesDocument,
    options,
  );
}
export function useModulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModulesQuery,
    ModulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ModulesQuery, ModulesQueryVariables>(
    ModulesDocument,
    options,
  );
}
export type ModulesQueryHookResult = ReturnType<typeof useModulesQuery>;
export type ModulesLazyQueryHookResult = ReturnType<typeof useModulesLazyQuery>;
export type ModulesQueryResult = Apollo.QueryResult<
  ModulesQuery,
  ModulesQueryVariables
>;
export const AuthorizeDocument = gql`
  mutation authorize($userId: ID!, $appName: String!) {
    authorize(userId: $userId, appName: $appName) {
      ...Authorization
    }
  }
  ${AuthorizationFragmentDoc}
`;
export type AuthorizeMutationFn = Apollo.MutationFunction<
  AuthorizeMutation,
  AuthorizeMutationVariables
>;

/**
 * __useAuthorizeMutation__
 *
 * To run a mutation, you first call `useAuthorizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeMutation, { data, loading, error }] = useAuthorizeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      appName: // value for 'appName'
 *   },
 * });
 */
export function useAuthorizeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorizeMutation,
    AuthorizeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AuthorizeMutation, AuthorizeMutationVariables>(
    AuthorizeDocument,
    options,
  );
}
export type AuthorizeMutationHookResult = ReturnType<
  typeof useAuthorizeMutation
>;
export type AuthorizeMutationResult = Apollo.MutationResult<AuthorizeMutation>;
export type AuthorizeMutationOptions = Apollo.BaseMutationOptions<
  AuthorizeMutation,
  AuthorizeMutationVariables
>;
export const LoginWithCredentialsDocument = gql`
  mutation loginWithCredentials($email: String!, $password: String!) {
    login(username: $email, password: $password) {
      accessToken
      refreshToken
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type LoginWithCredentialsMutationFn = Apollo.MutationFunction<
  LoginWithCredentialsMutation,
  LoginWithCredentialsMutationVariables
>;

/**
 * __useLoginWithCredentialsMutation__
 *
 * To run a mutation, you first call `useLoginWithCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithCredentialsMutation, { data, loading, error }] = useLoginWithCredentialsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginWithCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithCredentialsMutation,
    LoginWithCredentialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoginWithCredentialsMutation,
    LoginWithCredentialsMutationVariables
  >(LoginWithCredentialsDocument, options);
}
export type LoginWithCredentialsMutationHookResult = ReturnType<
  typeof useLoginWithCredentialsMutation
>;
export type LoginWithCredentialsMutationResult =
  Apollo.MutationResult<LoginWithCredentialsMutation>;
export type LoginWithCredentialsMutationOptions = Apollo.BaseMutationOptions<
  LoginWithCredentialsMutation,
  LoginWithCredentialsMutationVariables
>;
export const RequestPasswordRecoveryDocument = gql`
  mutation requestPasswordRecovery($username: String!) {
    requestPasswordRecovery(username: $username)
  }
`;
export type RequestPasswordRecoveryMutationFn = Apollo.MutationFunction<
  RequestPasswordRecoveryMutation,
  RequestPasswordRecoveryMutationVariables
>;

/**
 * __useRequestPasswordRecoveryMutation__
 *
 * To run a mutation, you first call `useRequestPasswordRecoveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordRecoveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordRecoveryMutation, { data, loading, error }] = useRequestPasswordRecoveryMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useRequestPasswordRecoveryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPasswordRecoveryMutation,
    RequestPasswordRecoveryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPasswordRecoveryMutation,
    RequestPasswordRecoveryMutationVariables
  >(RequestPasswordRecoveryDocument, options);
}
export type RequestPasswordRecoveryMutationHookResult = ReturnType<
  typeof useRequestPasswordRecoveryMutation
>;
export type RequestPasswordRecoveryMutationResult =
  Apollo.MutationResult<RequestPasswordRecoveryMutation>;
export type RequestPasswordRecoveryMutationOptions = Apollo.BaseMutationOptions<
  RequestPasswordRecoveryMutation,
  RequestPasswordRecoveryMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const MeDocument = gql`
  query me {
    me {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CreateOrganizationDocument = gql`
  mutation createOrganization($payload: CreateOrganizationInput!) {
    createOrganization(payload: $payload) {
      ...OrganizationWithApps
    }
  }
  ${OrganizationWithAppsFragmentDoc}
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CreateOrganizationDocument, options);
}
export type CreateOrganizationMutationHookResult = ReturnType<
  typeof useCreateOrganizationMutation
>;
export type CreateOrganizationMutationResult =
  Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const OrganizationWithUsersDocument = gql`
  query organizationWithUsers {
    organizations {
      ...OrganizationWithUsers
    }
  }
  ${OrganizationWithUsersFragmentDoc}
`;

/**
 * __useOrganizationWithUsersQuery__
 *
 * To run a query within a React component, call `useOrganizationWithUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationWithUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationWithUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationWithUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationWithUsersQuery,
    OrganizationWithUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationWithUsersQuery,
    OrganizationWithUsersQueryVariables
  >(OrganizationWithUsersDocument, options);
}
export function useOrganizationWithUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationWithUsersQuery,
    OrganizationWithUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationWithUsersQuery,
    OrganizationWithUsersQueryVariables
  >(OrganizationWithUsersDocument, options);
}
export type OrganizationWithUsersQueryHookResult = ReturnType<
  typeof useOrganizationWithUsersQuery
>;
export type OrganizationWithUsersLazyQueryHookResult = ReturnType<
  typeof useOrganizationWithUsersLazyQuery
>;
export type OrganizationWithUsersQueryResult = Apollo.QueryResult<
  OrganizationWithUsersQuery,
  OrganizationWithUsersQueryVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation updateOrganization($payload: UpdateOrganizationInput!) {
    updateOrganization(payload: $payload) {
      ...OrganizationWithApps
    }
  }
  ${OrganizationWithAppsFragmentDoc}
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >(UpdateOrganizationDocument, options);
}
export type UpdateOrganizationMutationHookResult = ReturnType<
  typeof useUpdateOrganizationMutation
>;
export type UpdateOrganizationMutationResult =
  Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const ProvidersDocument = gql`
  query providers {
    providers {
      ...Provider
    }
  }
  ${ProviderFragmentDoc}
`;

/**
 * __useProvidersQuery__
 *
 * To run a query within a React component, call `useProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProvidersQuery,
    ProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProvidersQuery, ProvidersQueryVariables>(
    ProvidersDocument,
    options,
  );
}
export function useProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProvidersQuery,
    ProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProvidersQuery, ProvidersQueryVariables>(
    ProvidersDocument,
    options,
  );
}
export type ProvidersQueryHookResult = ReturnType<typeof useProvidersQuery>;
export type ProvidersLazyQueryHookResult = ReturnType<
  typeof useProvidersLazyQuery
>;
export type ProvidersQueryResult = Apollo.QueryResult<
  ProvidersQuery,
  ProvidersQueryVariables
>;
export const CreateUserDocument = gql`
  mutation createUser($payload: CreateUserInput!) {
    createUser(payload: $payload) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const UserWithVehiclesDocument = gql`
  query userWithVehicles($userId: ID!) {
    user(id: $userId) {
      ...UserWithVehicles
    }
  }
  ${UserWithVehiclesFragmentDoc}
`;

/**
 * __useUserWithVehiclesQuery__
 *
 * To run a query within a React component, call `useUserWithVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithVehiclesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserWithVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserWithVehiclesQuery,
    UserWithVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserWithVehiclesQuery, UserWithVehiclesQueryVariables>(
    UserWithVehiclesDocument,
    options,
  );
}
export function useUserWithVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserWithVehiclesQuery,
    UserWithVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserWithVehiclesQuery,
    UserWithVehiclesQueryVariables
  >(UserWithVehiclesDocument, options);
}
export type UserWithVehiclesQueryHookResult = ReturnType<
  typeof useUserWithVehiclesQuery
>;
export type UserWithVehiclesLazyQueryHookResult = ReturnType<
  typeof useUserWithVehiclesLazyQuery
>;
export type UserWithVehiclesQueryResult = Apollo.QueryResult<
  UserWithVehiclesQuery,
  UserWithVehiclesQueryVariables
>;
export const UserDocument = gql`
  query user($userId: ID!) {
    user(id: $userId) {
      permissions
      role
      settings
      cpf
      email
      modules
      name
      id
      organization {
        id
        id
        name
      }
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UpdateUserDocument = gql`
  mutation updateUser($payload: UpdateUserInput!) {
    updateUser(payload: $payload) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UsersWithVehiclesDocument = gql`
  query usersWithVehicles {
    users {
      ...UserWithVehicles
    }
  }
  ${UserWithVehiclesFragmentDoc}
`;

/**
 * __useUsersWithVehiclesQuery__
 *
 * To run a query within a React component, call `useUsersWithVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersWithVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersWithVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersWithVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UsersWithVehiclesQuery,
    UsersWithVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UsersWithVehiclesQuery,
    UsersWithVehiclesQueryVariables
  >(UsersWithVehiclesDocument, options);
}
export function useUsersWithVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersWithVehiclesQuery,
    UsersWithVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UsersWithVehiclesQuery,
    UsersWithVehiclesQueryVariables
  >(UsersWithVehiclesDocument, options);
}
export type UsersWithVehiclesQueryHookResult = ReturnType<
  typeof useUsersWithVehiclesQuery
>;
export type UsersWithVehiclesLazyQueryHookResult = ReturnType<
  typeof useUsersWithVehiclesLazyQuery
>;
export type UsersWithVehiclesQueryResult = Apollo.QueryResult<
  UsersWithVehiclesQuery,
  UsersWithVehiclesQueryVariables
>;
export const UsersDocument = gql`
  query users($organization: ID) {
    users(organization: $organization) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const UpdateVehicleDocument = gql`
  mutation updateVehicle($payload: UpdateVehicleInput!) {
    updateVehicle(payload: $payload) {
      ...Vehicle
    }
  }
  ${VehicleFragmentDoc}
`;
export type UpdateVehicleMutationFn = Apollo.MutationFunction<
  UpdateVehicleMutation,
  UpdateVehicleMutationVariables
>;

/**
 * __useUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMutation, { data, loading, error }] = useUpdateVehicleMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVehicleMutation,
    UpdateVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVehicleMutation,
    UpdateVehicleMutationVariables
  >(UpdateVehicleDocument, options);
}
export type UpdateVehicleMutationHookResult = ReturnType<
  typeof useUpdateVehicleMutation
>;
export type UpdateVehicleMutationResult =
  Apollo.MutationResult<UpdateVehicleMutation>;
export type UpdateVehicleMutationOptions = Apollo.BaseMutationOptions<
  UpdateVehicleMutation,
  UpdateVehicleMutationVariables
>;
export const VehiclesDocument = gql`
  query vehicles {
    vehicles {
      ...VehicleWithDriver
    }
  }
  ${VehicleWithDriverFragmentDoc}
`;

/**
 * __useVehiclesQuery__
 *
 * To run a query within a React component, call `useVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<VehiclesQuery, VehiclesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VehiclesQuery, VehiclesQueryVariables>(
    VehiclesDocument,
    options,
  );
}
export function useVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehiclesQuery,
    VehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VehiclesQuery, VehiclesQueryVariables>(
    VehiclesDocument,
    options,
  );
}
export type VehiclesQueryHookResult = ReturnType<typeof useVehiclesQuery>;
export type VehiclesLazyQueryHookResult = ReturnType<
  typeof useVehiclesLazyQuery
>;
export type VehiclesQueryResult = Apollo.QueryResult<
  VehiclesQuery,
  VehiclesQueryVariables
>;
