/* eslint-disable react/prop-types */
import {
  Form,
  Footer,
  Inputs,
  PurpleButton,
  Row,
} from './UsersDrawerContent.style';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUsersDrawerContext } from '../../contexts/UsersDrawerContext';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import { useUsersCRUDContext } from '../../contexts/UsersCRUDContext';
import { SelectDataType as SelectOptions } from '../../../../components/SelectSearch/SelectSearch';
import OrganizationsSelector from '../../../../components/SelectOrganizations/SelectOrganizations';
import { useCurrentUserContext } from '../../../../contexts/CurrentUserContextProvider';
import { SelectDataType } from '../../../../components/MultipleSelectSearch/MultipleSelectSearch';
import { getOrganizationsNameAndId } from '../../../../helpers/get-organization-text';
import { useToast } from '../../../../hooks/useToast';
import { useMemo } from 'react';
import { UserRole } from '../../../../generated/graphqlV2';
import { cpfMask } from '../../../../helpers/cpf-mask';

interface FormValues {
  name: string;
  cpf: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  role: SelectOptions;
  organizations: SelectDataType[];
  modules: string[];
}

const UsersDrawerContent = () => {
  const { isEdit, selectedUser, setIsOpen } = useUsersDrawerContext();
  const { createUserIsLoading, editUserIsLoading, createNewUser, editUser } =
    useUsersCRUDContext();
  const loading = createUserIsLoading || editUserIsLoading;
  const { user } = useCurrentUserContext();
  const { addToast } = useToast();

  const schema = useMemo(() => {
    if (isEdit) {
      return yup.object().shape({
        name: yup.string().typeError('Insira o nome do usuário').required(),
        email: yup.string().typeError('Insira o e-mail').required(),
        organizations: yup
          .array()
          .min(1, 'Escolha pelo menos uma unidade')
          .typeError('Escolha pelo menos uma unidade'),
      });
    }
    return yup.object().shape({
      name: yup.string().typeError('Insira o nome do usuário').required(),
      email: yup.string().typeError('Insira o e-mail').required(),
      organizations: yup
        .array()
        .min(1, 'Escolha pelo menos uma unidade')
        .typeError('Escolha pelo menos uma unidade'),
      password: yup.string().typeError('Digite a senha').required(),
    });
  }, [isEdit]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: selectedUser?.name || undefined,
      cpf: selectedUser?.cpf || undefined,
      email: selectedUser?.email || undefined,
      organizations: getOrganizationsNameAndId(selectedUser?.organizations),
    },
  });
  const onSubmit = async (data: FormValues) => {
    if (selectedUser) {
      try {
        const updatePayload = {
          id: selectedUser.id,
          name: data.name,
          cpf: data.cpf,
          organizations: data.organizations.map(
            (organization) => organization.id,
          ),
          email: data.email,
        };
        await editUser(updatePayload);
        addToast({
          title: 'Usuário editado com sucesso',
          type: 'success',
          description: 'Confira na listagem o gestor editado',
        });
      } catch (error) {
        addToast({ title: 'Erro na criação da unidade.', type: 'error' });
        console.log('Erro: ', error);
      } finally {
        setIsOpen(false);
      }
    }
    if (!selectedUser) {
      try {
        const createPayload = {
          name: data.name,
          cpf: data.cpf,
          role: UserRole.Manager,
          organizations: data.organizations.map(
            (organization) => organization.id,
          ),
          email: data.email,
          password: data.password,
          modules: [
            'fuellings',
            'providers',
            'indicators',
            'integrations',
            'maintenances',
            'monitoring',
            'drivers',
            'vehicles',
            'reports',
          ],
        };
        await createNewUser(createPayload);
        addToast({ title: 'Usuário criado com sucesso!', type: 'success' });
      } catch (error) {
        addToast({ title: 'Erro na criação do usuário', type: 'error' });
      } finally {
        setIsOpen(false);
      }
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Inputs>
        <Row>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                label={'Nome completo'}
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                type="text"
                width="550px"
                disabled={loading}
                loading={loading}
                error={errors.name?.message && 'Insira o nome do usuário'}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="cpf"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <Input
                label={'CPF'}
                onChange={props.field.onChange}
                name={props.field.name}
                value={cpfMask(props.field.value)}
                type="text"
                width="550px"
                loading={loading}
                disabled={loading}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                label={'E-mail'}
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                type="email"
                width="550px"
                disabled={loading}
                error={errors.email?.message}
                loading={loading}
              />
            )}
          />
        </Row>
        {!isEdit && (
          <>
            <Row>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Input
                    label={'Senha'}
                    onChange={props.field.onChange}
                    name={props.field.name}
                    value={props.field.value}
                    type="password"
                    width="550px"
                    disabled={loading}
                    error={errors.password?.message}
                    loading={loading}
                  />
                )}
              />
            </Row>
          </>
        )}
        <Row>
          {!(selectedUser?.role === 'director') && (
            <Controller
              name="organizations"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <OrganizationsSelector
                  currentUser={user}
                  onChange={props.field.onChange}
                  name={props.field.name}
                  value={props.field.value}
                  error={errors.organizations?.message}
                />
              )}
            />
          )}
        </Row>
      </Inputs>
      <Footer>
        <PurpleButton>
          {isEdit ? 'Editar usuário' : 'Adicionar usuário'}
        </PurpleButton>
      </Footer>
    </Form>
  );
};

export default UsersDrawerContent;
