import { ReactNode, useRef } from 'react';
import { useClickAway } from 'react-use';
import { useModalContext } from '../../contexts/ModalContext';
import Skeleton from 'react-loading-skeleton';
import {
  Content,
  ModalContainer,
  ModalDescription,
  ModalTitle,
  Screen,
} from './Modal.styles';
import AlertCircleIcon from '../icons/AlertCircleIcon/AlertCircleIcon';

interface ModalProps {
  title: string;
  description?: string[];
  children?: ReactNode;
  loading?: boolean;
}

const Modal = (props: ModalProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { setOpenModal } = useModalContext();
  const { description, title, loading, children } = props;
  useClickAway(ref, () => {
    setTimeout(() => setOpenModal(false), 100);
  });
  return (
    <Screen>
      <ModalContainer ref={ref}>
        {loading ? (
          <Skeleton width={750} height={130} />
        ) : (
          <Content>
            <AlertCircleIcon />
            <ModalTitle>{title}</ModalTitle>
            {description?.map((text, index) => (
              <ModalDescription key={index}>{text}</ModalDescription>
            ))}
            {children}
          </Content>
        )}
      </ModalContainer>
    </Screen>
  );
};

export default Modal;
