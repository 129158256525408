import styled from 'styled-components';
import {
  OrganizationWithUsersFragment,
  UserFragment,
} from '../../generated/graphqlV2';
import ActionButtonIcon from '../icons/ActionButtonIcon/ActionButtonIcon';
/* import ExternalLinkIcon from '../icons/ExternalLinkIcon/ExternalLinkIcon'; */
import UserIconGray from '../icons/UserIcon/UserIconGray';
/* import VehicleSmallIcon from '../icons/VehicleSmallIcon/VehicleSmallIcon'; */
import Selector from '../Selector/Selector';
import {
  CardHeader,
  Container,
  DriversInfo,
  ManagerIcon,
  ManagersDisplay,
  ManagersText,
  NoManagersText,
  OrganizationAddress,
  OrganizationInfo,
  OrganizationLogo,
  OrganizationManagers,
  OrganizationName,
  StyledLink,
  StyledTooltip,
  /* VehiclesInfo, */
} from './OrganizationCard.style';

interface OrganizationCardProps {
  name: string;
  address: string | undefined | null;
  /* vehicles: string[];
  drivers: string[]; */
  managers: UserFragment[];
  primaryOrganization:
    | {
        __typename?: 'Organization' | undefined;
        id: string;
        name: string;
        cnpj?: string | null | undefined;
      }
    | null
    | undefined
    | null
    | undefined;
  handleEditClick: (
    selectedOrganization: OrganizationWithUsersFragment,
  ) => void;
  handleLoginClick?: (
    selectedOrganization: OrganizationWithUsersFragment,
  ) => void;
  usersQuantity?: number;
  handleViewClick: (
    selectedOrganization: OrganizationWithUsersFragment,
  ) => void;
  organization: OrganizationWithUsersFragment;
}

const BiggerActionIcon = styled(ActionButtonIcon)`
  padding: 10px;
`;

const OrganizationCard = (props: OrganizationCardProps) => {
  const {
    name,
    address,
    managers,
    primaryOrganization,
    handleEditClick,
    handleViewClick,
    /* handleLoginClick, */
    usersQuantity,
    organization,
  } = props;
  const actualManagers = managers.filter(
    (manager) => manager.role === 'manager',
  );

  return (
    <Container
      data-testid="OrganizationCard"
      onClick={() => (usersQuantity ? handleViewClick(organization) : '')}
      usersQuantity={usersQuantity}
    >
      <CardHeader>
        <OrganizationLogo>
          <span>{primaryOrganization ? <b>F</b> : <b>M</b>}</span>
        </OrganizationLogo>
        <OrganizationName>{name}</OrganizationName>
        <OrganizationAddress>{address}</OrganizationAddress>
        <Selector
          position="left"
          width="170px"
          icon={<BiggerActionIcon />}
          rowsArray={[
            /* {
              text: 'Visualizar',
              icon: <ExternalLinkIcon />,
              onClickWithData: handleLoginClick,
            }, */
            {
              text: 'Editar',
              onClickWithData: (event) => {
                event.stopPropagation();
                handleEditClick(organization);
              },
            },
          ]}
        />
      </CardHeader>
      <OrganizationInfo>
        {/* <VehiclesInfo>
          <VehicleSmallIcon width={16} height={16} />
          {`${vehicleQuantity} Veículo(s)`}
        </VehiclesInfo> */}
        <DriversInfo>
          <UserIconGray width={16} height={16} />
          {`${usersQuantity} gestor${(usersQuantity || 0) > 1 ? 'es' : ''}`}
        </DriversInfo>
      </OrganizationInfo>
      <ManagersDisplay>
        <ManagersText>Gestores</ManagersText>
        <OrganizationManagers>
          {actualManagers.length === 0 ? (
            <>
              <NoManagersText>
                Associe um gestor a esta filial pela aba de {''}
                <StyledLink to={'/users'}>gestores</StyledLink>
              </NoManagersText>
            </>
          ) : (
            actualManagers.map((actualManager, index) => {
              if (index < 4) {
                return (
                  <ManagerIcon number={index} key={index}>
                    <StyledTooltip
                      rowList={[actualManager.name || '']}
                      key={index}
                      number={index}
                    >
                      {actualManager.name
                        ? actualManager.name[0] + actualManager.name[1]
                        : index}
                    </StyledTooltip>
                  </ManagerIcon>
                );
              }
              if (index === 4) {
                return (
                  <ManagerIcon key={index} number={index} className="extra">
                    +{actualManagers.length - 4}
                  </ManagerIcon>
                );
              } else return '';
            })
          )}
        </OrganizationManagers>
      </ManagersDisplay>
    </Container>
  );
};

export default OrganizationCard;
