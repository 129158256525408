import { createContext, useContext, useMemo, useState } from 'react';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import { UserFragment } from '../../../generated/graphqlV2';
import UsersDrawerContent from '../components/UsersDrawerContent/UsersDrawerContent';

interface Drawer {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isEdit: boolean;
  setIsEdit: (isEdit: boolean) => void;
  selectedUser: UserFragment | undefined;
  setSelectedUser: (selectedUser: UserFragment | undefined) => void;
  drawerHeader: JSX.Element;
  setdrawerHeader: (drawerHeader: JSX.Element) => void;
}

interface UsersDrawerContextProps {
  children: React.ReactNode;
}

const emptyObject = {};

const UsersDrawerContext = createContext<Drawer>(emptyObject as Drawer);

function UsersDrawerContextProvider(props: UsersDrawerContextProps) {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserFragment>();
  const [isEdit, setIsEdit] = useState(false);
  const [drawerHeader, setdrawerHeader] = useState(<>Editar usuário</>);

  const value = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      isEdit,
      setIsEdit,
      selectedUser,
      setSelectedUser,
      drawerHeader,
      setdrawerHeader,
    }),
    [
      isOpen,
      setIsOpen,
      isEdit,
      setIsEdit,
      selectedUser,
      setSelectedUser,
      drawerHeader,
      setdrawerHeader,
    ],
  );
  return (
    <UsersDrawerContext.Provider value={value}>
      <CustomDrawer
        closeDrawer={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        header={drawerHeader}
        content={<UsersDrawerContent />}
        zIndex={11}
      />
      {children}
    </UsersDrawerContext.Provider>
  );
}

export function useUsersDrawerContext() {
  const context = useContext(UsersDrawerContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useUsersDrawerContext must be used within a UsersDrawerContextProvider',
    );
  }
  return context;
}

export default UsersDrawerContextProvider;
