import React, { useEffect, useState } from 'react';
import {
  CircularProgressContainer,
  Container,
  Progress,
} from './CircularProgress.styles';

interface CircularProgressProps {
  speed?: number;
  end?: number;
  size?: number;
  infinite?: boolean;
}

function CircularProgress(props: CircularProgressProps) {
  const { speed = 10, end = 100, size, infinite } = props;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress === end) {
        if (infinite) {
          setProgress(0);
        }

        clearInterval(timer);
        return;
      }

      setProgress((progress) => progress + 1);
    }, speed);

    return () => clearInterval(timer);
  }, [end, infinite, progress, speed]);

  return (
    <Container>
      <CircularProgressContainer $progress={progress} $size={size}>
        {infinite ? null : <Progress>{progress}%</Progress>}
      </CircularProgressContainer>
    </Container>
  );
}

export default CircularProgress;
