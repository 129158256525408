import { usePagination } from '../../hooks/usePagination';
import DoubleLeftArrowIcon from '../icons/DoubleLeftArrowIcon/DoubleLeftArrowIcon';
import DoubleRightArrowIcon from '../icons/DoubleRightArrowIcon/DoubleRightArrowIcon';
import Tooltip from '../Tooltip/Tooltip';
import {
  Container,
  DotPlace,
  ItemsShowed,
  MaxItemsContainer,
  MaxItemsSelector,
  PageList,
  PaginationLi,
} from './Pagination.style';

interface PaginationProps {
  currentPage: number;
  totalItems: number;
  itemLimit: number;
  siblingCount?: number;
  setCurrentPage: (page: number) => void;
  maxItensRendered: number;
  setMaxItensRendered: (maxItemsRendered: number) => void;
}

const Pagination = (props: PaginationProps) => {
  const {
    currentPage,
    itemLimit,
    totalItems,
    setCurrentPage,
    siblingCount = 1,
    maxItensRendered,
    setMaxItensRendered,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalItems,
    itemLimit,
    siblingCount,
  });

  if (currentPage === 0 || paginationRange.length === 0) return null;

  const lastPage = paginationRange[paginationRange.length - 1];

  const onNext = () => {
    if (currentPage === lastPage) return null;
    setCurrentPage(currentPage + 1);
  };
  const onPrevious = () => {
    if (currentPage === 1) return null;
    setCurrentPage(currentPage - 1);
  };
  const on5Next = () => {
    if (currentPage + 5 >= lastPage) return setCurrentPage(lastPage);
    else return setCurrentPage(currentPage + 5);
  };
  const on5Previous = () => {
    if (currentPage - 5 <= 1) return setCurrentPage(1);
    else return setCurrentPage(currentPage - 5);
  };
  const handleDisplayedItems = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMaxItensRendered(parseFloat(e.target.value));
    setCurrentPage(1);
  };

  return (
    <Container>
      <MaxItemsContainer>
        <MaxItemsSelector>
          <span>Itens por página: </span>
          <select onChange={handleDisplayedItems} value={maxItensRendered}>
            <option>6</option>
            <option>10</option>
            <option>20</option>
            <option>50</option>
            <option>100</option>
          </select>
        </MaxItemsSelector>
        <span>|</span>
        <ItemsShowed>
          {maxItensRendered >= totalItems
            ? 'Mostrando todos os itens'
            : `Mostrando ${maxItensRendered} de ${totalItems} itens`}
        </ItemsShowed>
      </MaxItemsContainer>
      <PageList>
        <span
          onClick={onPrevious}
          className={currentPage === 1 ? 'disabled' : ''}
        >
          Anterior
        </span>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === 654321) {
            return (
              <Tooltip rowList={['Retornar cinco páginas']} key={pageNumber}>
                <DotPlace onClick={on5Previous}>
                  <li className="dots">...</li>
                  <li className="arrow">
                    <DoubleLeftArrowIcon />
                  </li>
                </DotPlace>
              </Tooltip>
            );
          }
          if (pageNumber === 123456) {
            return (
              <Tooltip rowList={['Avançar cinco páginas']} key={pageNumber}>
                <DotPlace onClick={on5Next}>
                  <li className="dots">...</li>
                  <li className="arrow">
                    <DoubleRightArrowIcon />
                  </li>
                </DotPlace>
              </Tooltip>
            );
          }
          const className = pageNumber === currentPage ? 'active' : '';
          return (
            <PaginationLi
              className={className}
              onClick={() => setCurrentPage(pageNumber)}
              key={pageNumber}
            >
              {pageNumber}
            </PaginationLi>
          );
        })}
        <span
          onClick={onNext}
          className={currentPage === lastPage ? 'disabled' : ''}
        >
          Próxima
        </span>
      </PageList>
    </Container>
  );
};

export default Pagination;
