import styled, { css } from 'styled-components';

interface IconProps {
  $color?: string;
  $size?: string;
  $isOpen?: boolean;
}

const Icon = styled.span<IconProps>`
  display: inline-block;
  ${({ $color }) =>
    $color
      ? css`
          color: ${$color};
        `
      : null}
  ${({ $size }) =>
    $size
      ? css`
          font-size: ${$size};
        `
      : null}
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
    `};

  transition: transform 0.2s ease-in-out;

  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export default Icon;
