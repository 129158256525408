import styled, { css } from 'styled-components';
import theme from '../../theme';

interface InputProps {
  $hasIcon?: boolean;
  $hasLabel?: boolean;
  $width?: string;
  $labelActive?: boolean;
}

export const Container = styled.div<InputProps>`
  border-radius: 5px;
  height: 100%;
  min-height: 82px;
  width: ${({ $width }) => $width || '100%'};
  border-radius: 8px;
  position: relative;
  background-color: ${theme.colors.white};
`;

export const LoadingContainer = styled.div`
  display: block;
  width: 100%;
`;

export const TextAreaContainer = styled.div<InputProps>`
  position: relative;
  width: 100%;
  height: 100%;

  label {
    position: absolute;
    top: 16px;
    left: 0.8rem;
    cursor: text;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #667085;
    padding: 0 0.1rem;

    transition: all 200ms ease-in;
  }

  textarea {
    border: 0;
    background: transparent;
    border-radius: 8px;
    padding: 8px 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    width: 100%;
    resize: none;

    &:focus {
      outline-color: ${theme.colors.darkBlue};
    }

    &:focus ~ label,
    :not(:placeholder-shown):not(:focus) ~ label {
      font-size: 12px;
      top: -0.4rem;
      background: ${theme.colors.white};
      font-weight: 500;
      transform: translateY(0);
    }

    ${(props) =>
      props.$hasIcon &&
      css`
        padding-left: 2rem;
      `}
  }

  ${({ $labelActive }) =>
    $labelActive &&
    css`
      label {
        font-size: 12px;
        top: -0.4rem;
        background: ${theme.colors.white};
        font-weight: 500;
        transform: translateY(0);
      }
    `}
`;
