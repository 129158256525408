import {
  Container,
  PaginationRow,
  SearchContainer,
  RowsContainer,
  TotalUsers,
} from './UsersTable.style';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import { useUsersTableContext } from '../../contexts/UsersTableContext';
import Pagination from '../../../../components/Pagination/Pagination';
import { useMemo } from 'react';
import ReportTable from '../../../../components/Table/Table';
import formatUsersTableData from '../../helpers/format-users-table';
import { useUsersCRUDContext } from '../../contexts/UsersCRUDContext';
import CircularProgress from '../../../../components/CircularProgress/CircularProgress';
import { LoadingContainer } from '../../../../components/ManagersList/ManagersList.styles';

const UsersTable = () => {
  const {
    setQuery,
    sortBy,
    sortConfig,
    tableData,
    currentPage,
    numberOfItems,
    setCurrentPage,
    maxItensRendered,
    setMaxItensRendered,
    loading,
  } = useUsersTableContext();

  const { createUserIsLoading, deleteUserIsLoading, editUserIsLoading } =
    useUsersCRUDContext();

  const isLoading =
    loading || createUserIsLoading || deleteUserIsLoading || editUserIsLoading;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * maxItensRendered;
    const lastPageIndex = firstPageIndex + maxItensRendered;
    return tableData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, maxItensRendered, tableData]);

  const table = useMemo(() => {
    if (currentTableData)
      return formatUsersTableData({
        sortBy,
        sortConfig,
        users: currentTableData,
        currentPage,
        maxItensRendered,
      });
    return {
      header: [],
      rows: [],
    };
  }, [currentTableData]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setCurrentPage(1);
  };
  return (
    <Container data-testid="UsersTable">
      <SearchContainer>
        <SearchInput placeHolder="Pesquisar" onChange={handleSearch} />
      </SearchContainer>
      <RowsContainer>
        {isLoading ? (
          <LoadingContainer>
            <CircularProgress infinite={true} size={100} />
          </LoadingContainer>
        ) : (
          <ReportTable data={table} />
        )}
      </RowsContainer>
      <TotalUsers>
        <span>Total</span>
        <span>{currentTableData?.length || '0'}</span>
      </TotalUsers>
      <PaginationRow>
        <Pagination
          currentPage={currentPage}
          itemLimit={maxItensRendered}
          totalItems={numberOfItems || 0}
          setCurrentPage={setCurrentPage}
          maxItensRendered={maxItensRendered}
          setMaxItensRendered={setMaxItensRendered}
        />
      </PaginationRow>
    </Container>
  );
};

export default UsersTable;
