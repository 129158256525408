import { VehiclesQuery } from '../../../generated/graphqlV2';

export default function getUsefulVehicleData(data: VehiclesQuery | undefined) {
  return data?.vehicles.map((vehicle) => {
    return {
      ...vehicle,
      measure:
        vehicle.type === 'equipment'
          ? { type: 'horimeter', value: vehicle.odometer }
          : { type: 'odometer', value: vehicle.horimeter || 0 },
    };
  });
}
