import { Table as Info } from './TableTypes';
import {
  TableBody,
  TableData,
  TableHeader,
  TableRow,
  TableWrapper,
} from './styles';

interface TableProps<Data> {
  data: Info<Data>;
  hasBackground?: boolean;
}

function Table<Data = unknown>(props: TableProps<Data>) {
  const { data } = props;

  return (
    <TableWrapper>
      <thead>
        <TableRow>
          {data.header.map((cell, index) => {
            return <TableHeader key={index}>{cell.render()}</TableHeader>;
          })}
        </TableRow>
      </thead>
      <TableBody>
        {data.rows.map((row, rowIndex) => {
          return (
            <TableRow key={rowIndex}>
              {row.cells.map((cell, cellIndex) => {
                return (
                  <TableData key={cellIndex}>
                    {cell.render(row.data, rowIndex)}
                  </TableData>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </TableWrapper>
  );
}

export default Table;
