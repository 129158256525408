import { createContext, useContext, useMemo } from 'react';
import {
  OrganizationWithUsersFragment,
  useOrganizationWithUsersQuery,
} from '../../../generated/graphqlV2';

interface OrganizationData {
  organizationsWithUsers: OrganizationWithUsersFragment[] | undefined;
  headquarterOrganization: OrganizationWithUsersFragment | undefined;
  loading: boolean;
}

interface OrganizationDataContextProps {
  children: React.ReactNode;
}

const emptyObject = {};

const OrganizationDataContext = createContext<OrganizationData>(
  emptyObject as OrganizationData,
);

function OrganizationDataContextProvider(props: OrganizationDataContextProps) {
  const { children } = props;
  const { data, loading } = useOrganizationWithUsersQuery({
    fetchPolicy: 'cache-and-network',
  });

  const headquarterOrganization = data?.organizations.find(
    (organization) => organization.primaryOrganization === null,
  );

  const organizationsWithUsers = useMemo(() => {
    if (!data?.organizations) return [];
    return data?.organizations.filter(
      (organization) => organization.primaryOrganization !== null,
    );
  }, [data]);

  const value = useMemo(
    () => ({
      headquarterOrganization,
      organizationsWithUsers,
      loading,
    }),
    [organizationsWithUsers, loading],
  );

  return (
    <OrganizationDataContext.Provider value={value}>
      {children}
    </OrganizationDataContext.Provider>
  );
}

export function useOrganizationDataContext() {
  const context = useContext(OrganizationDataContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useOrganizationDataContext must be used within a OrganizationDataContextProvider',
    );
  }
  return context;
}

export default OrganizationDataContextProvider;
