import { useMemo } from 'react';
import ReportTable from '../../../../components/Table/Table';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import Pagination from '../../../../components/Pagination/Pagination';
import { useVehiclesTableContext } from '../../contexts/VehiclesTableContext';
import formatVehicleTableData from '../../helpers/format-vehicle-table';
import {
  Container,
  PaginationRow,
  RowsContainer,
  SearchContainer,
  TotalVehicles,
} from './VehiclesTable.style';
import { useVehiclesCRUDContext } from '../../contexts/VehiclesCRUDContext';
import { LoadingContainer } from '../../../../components/ManagersList/ManagersList.styles';
import CircularProgress from '../../../../components/CircularProgress/CircularProgress';

const VehiclesTable = () => {
  const {
    setQuery,
    sortBy,
    sortConfig,
    tableData,
    currentPage,
    setCurrentPage,
    numberOfItems,
    maxItensRendered,
    setMaxItensRendered,
    loading,
  } = useVehiclesTableContext();

  const { isSubmitting } = useVehiclesCRUDContext();

  const isLoading = isSubmitting || loading;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * maxItensRendered;
    const lastPageIndex = firstPageIndex + maxItensRendered;
    return tableData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, maxItensRendered, tableData]);

  const table = useMemo(() => {
    if (currentTableData)
      return formatVehicleTableData({
        sortBy,
        sortConfig,
        vehicles: currentTableData,
        currentPage,
        maxItensRendered,
      });
    return {
      header: [],
      rows: [],
    };
  }, [currentTableData]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setCurrentPage(1);
  };
  return (
    <Container data-testid="VehiclesTable">
      <SearchContainer>
        <SearchInput
          placeHolder="Pesquisar por placa"
          onChange={handleSearch}
        />
      </SearchContainer>
      <RowsContainer>
        {isLoading ? (
          <LoadingContainer>
            <CircularProgress infinite={true} size={100} />
          </LoadingContainer>
        ) : (
          <ReportTable data={table} />
        )}
      </RowsContainer>
      <TotalVehicles>
        <span>Total</span>
        <span>{numberOfItems}</span>
      </TotalVehicles>
      <PaginationRow>
        <Pagination
          currentPage={currentPage}
          itemLimit={maxItensRendered}
          totalItems={numberOfItems || 0}
          setCurrentPage={setCurrentPage}
          maxItensRendered={maxItensRendered}
          setMaxItensRendered={setMaxItensRendered}
        />
      </PaginationRow>
    </Container>
  );
};

export default VehiclesTable;
