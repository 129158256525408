import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 330px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 9px 12px;
  background-color: white;
  border-radius: 4px;
  margin: 10px 8px;
`;
export const Input = styled.input`
  border: none;
  width: 100%;
  outline: none;
  font-size: 14px;
  line-height: 24px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.65);
  }
`;
