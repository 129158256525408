import { ReactNode } from 'react';
import BigLeftArrrowIcon from '../icons/BigLeftArrowIcon.svg/BigLeftArrowIcon';
import { Container, Drawer, DrawerHeader } from './CustomDrawer.styles';

interface CustomDrawerProps {
  children?: ReactNode;
  isOpen: boolean;
  closeDrawer: () => void;
  header: ReactNode;
  content?: ReactNode;
  zIndex?: number;
}

const CustomDrawer = (props: CustomDrawerProps) => {
  const { isOpen, closeDrawer, header, content, zIndex } = props;
  return (
    <Container
      className={isOpen ? 'active' : ''}
      onClick={closeDrawer}
      zIndex={zIndex}
    >
      <Drawer
        className={isOpen ? 'active' : ''}
        onClick={(e) => e.stopPropagation()}
      >
        {isOpen && (
          <>
            <DrawerHeader>
              <BigLeftArrrowIcon cursor="pointer" onClick={closeDrawer} />
              {header}
            </DrawerHeader>
            {content}
          </>
        )}
      </Drawer>
    </Container>
  );
};

export default CustomDrawer;
