import { Container, Icon, Title } from './DrawerHeader.styles';

interface DrawerHeaderProps {
  title?: string;
  hasIcon?: boolean;
}

const DrawerHeader = (props: DrawerHeaderProps) => {
  const { title = 'Drawer title', hasIcon = false } = props;
  return (
    <Container>
      {hasIcon && <Icon>{title[0] + title[1]}</Icon>}
      <Title hasIcon={hasIcon}>{title}</Title>
    </Container>
  );
};

export default DrawerHeader;
