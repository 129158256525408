import VehiclesCRUDContextProvider from './contexts/VehiclesCRUDContext';
import DrawerContextProvider from './contexts/VehiclesDrawerContext';
import VehiclesTableContextProvider from './contexts/VehiclesTableContext';
import VehiclesPageContent from './VehiclesPageContent';

const VehiclesPage = () => {
  return (
    <VehiclesCRUDContextProvider>
      <VehiclesTableContextProvider>
        <DrawerContextProvider>
          <VehiclesPageContent />
        </DrawerContextProvider>
      </VehiclesTableContextProvider>
    </VehiclesCRUDContextProvider>
  );
};

export default VehiclesPage;
