import DisplayIcon from '../../../components/icons/DisplayIcon/DisplayIcon';
import ExitIcon from '../../../components/icons/ExitIcon/ExitIcon';
import InfleetBigLogoIcon from '../../../components/icons/InfleetBigLogoIcon/InfleetBigLogoIcon';
import { loggedUser } from './loggedUser';
import { pagesOptions } from './pagesOptions';
import {
  Container,
  Divider,
  ExpandIconContainer,
  Footer,
  Header,
  Options,
  PageList,
  PageListSpan,
  TestDiv,
  UserBox,
  UserInfo,
  UserName,
  UserPhoto,
  UserRole,
} from './Sidebar.style';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback, useState } from 'react';
import InfleetLogoIcon from '../../../components/icons/InfleetLogoIcon/InfleetLogoIcon';
import ExpandIcon from '../../../components/icons/ExpandIcon/ExpandIcon';
import { useAuth } from '../../../auth/contexts/AuthContextProvider';
import { useCurrentUserContext } from '../../../contexts/CurrentUserContextProvider';
import { USER_ROLES } from '../../../constants/roles';
import AsyncSelector from '../../../components/AsyncSelector/AsyncSelector';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const { user } = useCurrentUserContext();
  const { logout } = useAuth();
  const handleLogout = useCallback(async () => await logout(), [logout]);
  return (
    <Container data-testid="Sidebar" className={open ? '' : 'closed'}>
      <Header>
        <TestDiv className={open ? 'big' : 'small'}>
          <DisplayIcon cursor="pointer" onClick={() => setOpen(!open)} />
          <InfleetBigLogoIcon display={open ? 'inline' : 'none'} />
        </TestDiv>
        <InfleetLogoIcon display={open ? 'none' : 'inline'} />
        <ExpandIconContainer
          className={open ? 'hidden' : ''}
          onClick={() => setOpen(!open)}
        >
          <ExpandIcon />
        </ExpandIconContainer>
      </Header>
      <Options>
        {pagesOptions.map((Page) => {
          return (
            <PageList
              key={Page.title}
              data-testid={Page.testId}
              onClick={() => navigate(Page.route)}
              className={location.pathname === Page.route ? 'selected' : ''}
              isOpen={open}
            >
              {Page.icon}
              <PageListSpan className={open ? 'active' : ''}>
                {Page.title}
              </PageListSpan>
            </PageList>
          );
        })}
      </Options>
      <Footer>
        {/* <Settings><SettingsIcon color={theme.colors.lightGray}/>{open && <span>Configurações</span>}</Settings> */}
        <Divider />
        <UserBox>
          <UserInfo>
            {open ? (
              <UserPhoto
                src={loggedUser.image}
                alt="User profile photo"
                data-testid="userImage"
                className={open ? '' : 'closed'}
              />
            ) : (
              <AsyncSelector
                icon={
                  <UserPhoto src={loggedUser.image} alt="User profile photo" />
                }
                position="left"
                rowsArray={[{ text: 'Sair', onClick: handleLogout }]}
              />
            )}
            <UserName className={open ? '' : 'closed'}>{user?.name}</UserName>
            <UserRole className={open ? '' : 'closed'}>
              {user &&
                USER_ROLES.filter((role) => role.type === user.role)[0].label}
            </UserRole>
          </UserInfo>
          {open && (
            <AsyncSelector
              icon={<ExitIcon />}
              width="50px"
              position="left"
              rowsArray={[{ text: 'Sair', onClick: handleLogout }]}
            />
          )}
        </UserBox>
      </Footer>
    </Container>
  );
};

export default Sidebar;
