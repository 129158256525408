import styled from 'styled-components';
import theme from '../../theme';

interface AddButtonContainerProps {
  width?: string;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  hoverEffect?: { hoverTextColor: string; hoverBackgroundColor: string };
}

const AddButtonContainer = styled.button<AddButtonContainerProps>`
  grid-area: button;
  width: ${(props) => props.width};
  height: 2.5rem;
  padding: 8px 40px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : 'none'};
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.hoverEffect?.hoverTextColor};
    background-color: ${(props) => props.hoverEffect?.hoverBackgroundColor};
    transform: ${(props) => (props.hoverEffect ? 'scale(1.05)' : '')};
  }
`;

interface AddButtonProps extends AddButtonContainerProps {
  children: string;
  onClick?: () => void;
  disabled?: boolean;
  borderColor?: string;
  hoverEffect?: { hoverTextColor: string; hoverBackgroundColor: string };
}

const AddButton = (props: AddButtonProps) => {
  const {
    children,
    onClick,
    disabled,
    width = 'fit-content',
    backgroundColor = theme.colors.buttonBackgroundPurple,
    textColor = theme.colors.buttonTextPurple,
    borderColor,
    hoverEffect,
  } = props;
  return (
    <AddButtonContainer
      onClick={onClick}
      disabled={disabled}
      width={width}
      backgroundColor={backgroundColor}
      textColor={textColor}
      borderColor={borderColor}
      hoverEffect={hoverEffect}
    >
      {children}
    </AddButtonContainer>
  );
};

export default AddButton;
