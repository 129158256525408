import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  grid-area: page;
  background-color: ${theme.colors.backgroundColor};
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
`;
