import styled from 'styled-components';
import AddButton from '../../../../components/AddButton/AddButton';
import { ManagerListDataType } from '../../../../components/ManagersList/ManagersList';
import { useToast } from '../../../../hooks/useToast';
import theme from '../../../../theme';
import { useUsersCRUDContext } from '../../../users/contexts/UsersCRUDContext';

interface RemoveUserModalContentProps {
  closeModal: () => void;
  currentUser: ManagerListDataType;
  currentOrganizationId: string | undefined;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  width: 100%;
`;

const BlankSpace = styled.div`
  width: 200px;
`;

const RemoveUserModalContent = (props: RemoveUserModalContentProps) => {
  const { closeModal, currentOrganizationId, currentUser } = props;
  const { editUser, editUserIsLoading } = useUsersCRUDContext();
  const { addToast } = useToast();
  const handleRemove = async () => {
    try {
      const payload = {
        id: currentUser.id || '',
        organizations: currentUser.organizations.filter(
          (organizationId) => organizationId !== currentOrganizationId,
        ),
      };
      await editUser(payload);
      addToast({ title: 'Gestor removido com sucesso', type: 'success' });
    } catch (error) {
      console.log(error);
      addToast({ title: 'Erro ao deletar o usuário', type: 'error' });
    } finally {
      closeModal();
    }
  };

  return (
    <Container>
      <BlankSpace></BlankSpace>
      <AddButton
        onClick={closeModal}
        backgroundColor="transparent"
        textColor={theme.colors.darkGray}
        width="fit-content"
        borderColor={theme.colors.lightGray}
        hoverEffect={{
          hoverBackgroundColor: theme.colors.lightGray,
          hoverTextColor: 'white',
        }}
        disabled={editUserIsLoading}
      >
        Cancelar
      </AddButton>
      <AddButton
        backgroundColor="transparent"
        textColor={theme.colors.red}
        onClick={handleRemove}
        width="fit-content"
        borderColor={theme.colors.red}
        hoverEffect={{
          hoverBackgroundColor: theme.colors.red,
          hoverTextColor: 'white',
        }}
        disabled={editUserIsLoading}
      >
        Remover
      </AddButton>
    </Container>
  );
};

export default RemoveUserModalContent;
