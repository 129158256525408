import { OrganizationFragment } from '../generated/graphqlV2';

export const getOrganizationsNameAndId = (
  organizations: OrganizationFragment[] | undefined,
) => {
  if (organizations)
    return organizations.map((organization) => ({
      id: organization.id,
      text: organization.name,
    }));
};
