import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  padding-left: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  align-items: center;
  justify-content: space-between;
`;

export const MaxItemsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin-left: 40px;
`;
export const MaxItemsSelector = styled.div`
  select {
    border: 1px solid #677788;
    border-radius: 5px;
    outline: none;
  }
`;
export const ItemsShowed = styled.span``;

export const PageList = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 10px;
  margin-right: 40px;
  span {
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
  }
`;

export const PaginationLi = styled.li`
  padding: 6px;
  width: 36px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${theme.colors.white};
  font-size: 16px;
  font-weight: 400px;
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    border: 1px solid ${theme.colors.darkBlue};
  }
  &.active {
    background-color: ${theme.colors.darkBlue};
    color: ${theme.colors.white};
  }
`;

export const DotPlace = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${theme.colors.white};
  border-radius: 5px;
  transition: all 0.2s ease;
  &:hover {
    border: 1px solid ${theme.colors.darkBlue};
  }
  .dots {
    display: inline-block;
  }
  .arrow {
    display: none;
  }
  &:hover .dots {
    display: none;
  }
  &:hover .arrow {
    display: inline;
    justify-self: center;
  }
`;
