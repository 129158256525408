import { useMemo, useRef, useState } from 'react';
import {
  OrganizationWithUsersFragment,
  useUsersQuery,
} from '../../generated/graphqlV2';
import { SelectDataType } from '../SelectSearch/SelectSearch';
import {
  Container,
  List,
  ListData,
  Name,
  ManagerIcon,
  ListItem,
  InfoContainer,
  Search,
  SelectContainer,
  SelectIcon,
  SearchContainer,
  NoManagersText,
  LoadingContainer,
  Warning,
} from './ManagersList.styles';
import ChevronDownIcon from '../icons/ChevronDownIcon/ChevronDownIcon';
import Icon from '../Icon/Icon';
import Selector from '../Selector/Selector';
import ActionButtonIcon from '../icons/ActionButtonIcon/ActionButtonIcon';
import { useUsersDrawerContext } from '../../pages/users/contexts/UsersDrawerContext';
import DrawerHeader from '../CustomDrawer/DrawerHeader/DrawerHeader';
import { useUsersCRUDContext } from '../../pages/users/contexts/UsersCRUDContext';
import { useModalContext } from '../../contexts/ModalContext';
import RemoveUserModalContent from '../../pages/orgsManagement/components/RemoveUserModalContent.tsx/RemoveUserModalContent';
import { useOrgsManagementDrawerContext } from '../../pages/orgsManagement/contexts/OrgsManagementDrawerContext';
import { StyledLink } from '../OrganizationCard/OrganizationCard.style';
import CircularProgress from '../CircularProgress/CircularProgress';

export interface ManagerListDataType extends SelectDataType {
  organizations: string[];
}

interface ManagersListProps {
  organization?: OrganizationWithUsersFragment | undefined;
  value?: ManagerListDataType | null;
}

const ManagersList = (props: ManagersListProps) => {
  const { value } = props;
  const { selectedOrganization } = useOrgsManagementDrawerContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('');
  const [showOptions, setShowOptions] = useState(true);
  const [inputValue, setInputValue] = useState(value?.text || '');

  const { setIsOpen, setSelectedUser, setIsEdit, setdrawerHeader } =
    useUsersDrawerContext();
  const { editUserIsLoading } = useUsersCRUDContext();
  const { setOpenModal, setContent, setModalDescription, setModalTitle } =
    useModalContext();
  const { data: { users = [] } = {}, loading } = useUsersQuery({
    fetchPolicy: 'cache-and-network',
    variables: { organization: selectedOrganization?.id },
  });
  const isNotPrimaryOrganization = !!selectedOrganization?.primaryOrganization;
  const organizationUsers = useMemo(() => {
    if (!selectedOrganization && !users.length)
      return [{ id: '', text: '', organizations: [''] }];
    const usersData = users.filter((user) =>
      user.organizations.find(
        (organization) => organization.id === selectedOrganization?.id,
      ),
    );
    const parsedUsersData = usersData
      .filter((allUsers) => allUsers.role === 'manager')
      .map((manager) => {
        return {
          id: manager.id,
          text: manager.name || '',
          organizations: manager.organizations.map(
            (organization) => organization.id,
          ),
        };
      });
    return parsedUsersData;
  }, [selectedOrganization, editUserIsLoading, users]);

  const searchUserOptions = useMemo(() => {
    const filteredData = organizationUsers.filter((item) => {
      return item.text.toLowerCase().search(search.toLowerCase()) !== -1;
    });
    return filteredData;
  }, [search, organizationUsers]);

  function onInputTextChange(text: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(text.target.value);
    setSearch(text.target.value);
  }

  return (
    <Container data-testid="managers list container">
      {loading ? (
        <LoadingContainer>
          <CircularProgress infinite={true} size={100} speed={10} />
        </LoadingContainer>
      ) : (
        <SelectContainer data-testid="select container">
          <SearchContainer data-testid="search container">
            <Search
              type="search"
              ref={inputRef}
              value={inputValue}
              onChange={onInputTextChange}
              data-testid="search"
              placeholder={
                searchUserOptions.length < 1
                  ? 'Não há gestores associados a essa unidade'
                  : ''
              }
            />
            <SelectIcon $showOptions={showOptions}>
              <Icon onClick={() => setShowOptions(!showOptions)}>
                <ChevronDownIcon cursor="pointer" />
              </Icon>
            </SelectIcon>
          </SearchContainer>
          {showOptions &&
            (searchUserOptions.length < 1 ? (
              <NoManagersText>
                {isNotPrimaryOrganization ? (
                  <>
                    Associe um gestor a esta unidade pela aba de {''}
                    <StyledLink to={'/users'}>gestores</StyledLink>
                  </>
                ) : (
                  'Não há gestores registrados'
                )}
              </NoManagersText>
            ) : (
              <List data-testid="list">
                {searchUserOptions.map((user, index) => {
                  const selectorOptions =
                    user.organizations.length > 2
                      ? [
                          {
                            text: 'Editar gestor',
                            onClick: () => {
                              setIsOpen(true);
                              setSelectedUser(
                                users.find((anyUser) => anyUser.id === user.id),
                              );
                              setIsEdit(true);
                              setdrawerHeader(
                                <DrawerHeader title="Editar gestor" />,
                              );
                            },
                          },
                          {
                            text: 'Remover gestor',
                            onClickWithData: (
                              e: React.MouseEvent<HTMLDivElement, MouseEvent>,
                            ) => {
                              e.stopPropagation();
                              setOpenModal(true);
                              setContent(
                                <RemoveUserModalContent
                                  currentOrganizationId={
                                    selectedOrganization?.id
                                  }
                                  currentUser={user}
                                  closeModal={() => setOpenModal(false)}
                                />,
                              );
                              setModalDescription([
                                'Tem certeza que deseja remover este gestor da organização selecionada?',
                              ]);
                              setModalTitle('Confirmar remoção');
                            },
                          },
                        ]
                      : [
                          {
                            text: 'Editar gestor',
                            onClick: () => {
                              setIsOpen(true);
                              setSelectedUser(
                                users.find((anyUser) => anyUser.id === user.id),
                              );
                              setIsEdit(true);
                              setdrawerHeader(
                                <DrawerHeader title="Editar gestor" />,
                              );
                            },
                          },
                        ];
                  return (
                    <ListItem key={index}>
                      <ListData>
                        <InfoContainer>
                          <ManagerIcon>
                            {user.text[0] + user.text[1]}
                          </ManagerIcon>
                          <Name>{user.text}</Name>
                          {isNotPrimaryOrganization &&
                            user.organizations.length < 3 && (
                              <Warning>
                                Gestor associado apenas a esta unidade, para
                                remover, exclua o perfil.
                              </Warning>
                            )}
                        </InfoContainer>
                        {isNotPrimaryOrganization && (
                          <Selector
                            icon={<ActionButtonIcon />}
                            position="right"
                            rowsArray={selectorOptions}
                            width="120px"
                          ></Selector>
                        )}
                      </ListData>
                    </ListItem>
                  );
                })}
              </List>
            ))}
        </SelectContainer>
      )}
    </Container>
  );
};

export default ManagersList;
