import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  OrganizationFragment,
  UserFragment,
  UserRole,
  UsersQuery,
  useUsersQuery,
} from '../../../generated/graphqlV2';
import { sortUsersTable } from '../helpers/sort-users-table';

interface UsersTable {
  sortConfig: SortingConfiguration;
  setSortConfig: (sortConfig: SortingConfiguration) => void;
  query: string;
  setQuery: (query: string) => void;
  tableData: UserFragment[] | undefined;
  setTableData: (tabledata: UserFragment[] | undefined) => void;
  sortBy: (string: string) => void;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  numberOfItems: number | undefined;
  maxItensRendered: number;
  setMaxItensRendered: (maxItensRendered: number) => void;
  loading: boolean;
  users: UsersQuery | undefined;
}

export interface UsersUsefulInfo {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  cpf: string;
  organizations: OrganizationFragment[];
}

interface UsersTableContextProps {
  children: React.ReactNode;
}

export interface SortingConfiguration {
  propertyName: string | undefined;
  sortType: SortingType | undefined;
}

export enum SortingType {
  Ascending,
  Descending,
}

const emptyObject = {};

const UsersTableContext = createContext<UsersTable>(emptyObject as UsersTable);

function UsersTableContextProvider(props: UsersTableContextProps) {
  const { children } = props;
  const [sortConfig, setSortConfig] = useState<SortingConfiguration>({
    propertyName: undefined,
    sortType: undefined,
  });
  const [query, setQuery] = useState('');
  const [tableData, setTableData] = useState<UserFragment[] | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [maxItensRendered, setMaxItensRendered] = useState(6);
  const numberOfItems = tableData?.length;
  const { data, loading } = useUsersQuery();
  const users = useMemo(() => data, [data]);

  useMemo(() => {
    const usefulInfo = data?.users.filter((user) => user.role === 'manager');
    const filteredData = usefulInfo?.filter((item) =>
      item.name?.toLowerCase().includes(query),
    );
    const sortedFilteredData = sortUsersTable(filteredData, sortConfig);
    setTableData(sortedFilteredData);
  }, [data, query, sortConfig, loading]);

  const sortBy = useCallback(
    (propertyName: string | undefined) => {
      if (sortConfig.propertyName === propertyName) {
        if (sortConfig.sortType === SortingType.Descending) {
          setSortConfig({ propertyName, sortType: SortingType.Ascending });
        } else {
          setSortConfig({ propertyName: undefined, sortType: undefined });
        }
      } else {
        setSortConfig({ propertyName, sortType: SortingType.Descending });
      }
    },
    [sortConfig],
  );

  const value = useMemo(
    () => ({
      sortConfig,
      setSortConfig,
      query,
      setQuery,
      tableData,
      setTableData,
      currentPage,
      setCurrentPage,
      numberOfItems,
      maxItensRendered,
      sortBy,
      setMaxItensRendered,
      loading,
      users,
    }),
    [
      currentPage,
      maxItensRendered,
      numberOfItems,
      query,
      sortBy,
      sortConfig,
      tableData,
      users,
    ],
  );
  return (
    <UsersTableContext.Provider value={value}>
      {children}
    </UsersTableContext.Provider>
  );
}

export function useUsersTableContext() {
  const context = useContext(UsersTableContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useUsersTableContext must be used within a UsersTableContextProvider',
    );
  }
  return context;
}

export default UsersTableContextProvider;
