import ColorfulText from '../../../components/ColorfulText/ColorfulText';
import AscendingSortIcon from '../../../components/icons/AscendingSortIcon/AscendingSortIcon';
import DescendingSortIcon from '../../../components/icons/DescendingSortIcon/DescendingSortIcon';
import NoSortIcon from '../../../components/icons/NoSortIcon/NoSortIcon';
import { Table } from '../../../components/Table/TableTypes';
import { HeaderData } from '../../../components/Table/styles';
import formatHorimeter from '../../../helpers/format-horimeter';
import theme from '../../../theme';
import {
  SortingType,
  VehicleUsefulInfo,
} from '../contexts/VehiclesTableContext';
import Selector from '../../../components/Selector/Selector';
import ActionButtonIcon from '../../../components/icons/ActionButtonIcon/ActionButtonIcon';
import { useVehiclesDrawerContext } from '../contexts/VehiclesDrawerContext';
import formatKilometer from '../../../helpers/format-kilometer';

interface TableProps {
  vehicles: VehicleUsefulInfo[];
  sortBy: (sortOption: string) => void;
  sortConfig: {
    propertyName: string | undefined;
    sortType: SortingType | undefined;
  };
  currentPage: number;
  maxItensRendered: number;
}

export default function formatVehicleTableData(
  props: TableProps,
): Table<VehicleUsefulInfo> {
  const { sortBy, sortConfig, vehicles, currentPage, maxItensRendered } = props;
  const SortDirection = (property: string) => {
    if (sortConfig.propertyName === property) {
      if (sortConfig.sortType === SortingType.Descending) {
        return <DescendingSortIcon />;
      } else {
        return <AscendingSortIcon />;
      }
    }
    return <NoSortIcon />;
  };
  return {
    header: [
      {
        render: () => <HeaderData>#</HeaderData>,
      },
      {
        render: () => (
          <HeaderData onClick={() => sortBy('plate')} className="sortable">
            <span>Veículo</span>
            {SortDirection('plate')}
          </HeaderData>
        ),
      },
      {
        render: () => (
          <HeaderData onClick={() => sortBy('model')} className="sortable">
            <span>Modelo</span>
            {SortDirection('model')}
          </HeaderData>
        ),
      },
      {
        render: () => (
          <HeaderData onClick={() => sortBy('odometer')} className="sortable">
            <span>Medição</span>
            {SortDirection('odometer')}
          </HeaderData>
        ),
      },
      {
        render: () => (
          <HeaderData onClick={() => sortBy('driver')} className="sortable">
            <span>Condutor</span>
            {SortDirection('driver')}
          </HeaderData>
        ),
      },
      {
        render: () => <HeaderData>Unidade</HeaderData>,
      },
      {
        render: () => <HeaderData></HeaderData>,
      },
    ],
    rows: vehicles.map((vehicle, index) => ({
      data: vehicle,
      cells: [
        {
          render: () => (
            <span>
              {currentPage !== 0
                ? index + 1 + (currentPage - 1) * maxItensRendered
                : index + 1}
            </span>
          ),
        },
        {
          render: (vehicle) => <span>{vehicle.plate}</span>,
        },
        {
          render: (vehicle) => <span>{vehicle.model}</span>,
        },
        {
          render: (vehicle) => (
            <span>
              {vehicle.measure.type === 'odometer'
                ? formatKilometer(vehicle.measure.value)
                : formatHorimeter(vehicle.measure.value)}
            </span>
          ),
        },
        {
          render: (vehicle) => <span>{vehicle.driver?.name}</span>,
        },
        {
          render: (vehicle) => (
            <ColorfulText
              backgroundColor={theme.colors.darkBlue}
              textColor={theme.colors.white}
            >
              {vehicle.organization.name}
            </ColorfulText>
          ),
        },
        {
          render: (vehicle) => {
            const { isOpen, setIsOpen, setIsEdit, setSelectedVehicle } =
              useVehiclesDrawerContext();
            const handleEdit = () => {
              setIsOpen(!isOpen);
              setSelectedVehicle(vehicle);
              setIsEdit(true);
            };
            return (
              <Selector
                position={'right'}
                icon={<ActionButtonIcon />}
                rowsArray={[{ text: 'Editar', onClick: handleEdit }]}
                width="55px"
              />
            );
          },
        },
      ],
    })),
  };
}
