import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import { OrganizationWithUsersFragment } from '../../../generated/graphqlV2';
import OrgsManagementDrawerContentOnAdd from '../components/OrgsManagementDrawerContent/OrgsManagementDrawerContentOnAdd';
import OrgsManagementDrawerContentOnEdit from '../components/OrgsManagementDrawerContent/OrgsManagementDrawerContentOnEdit';
import OrgsManagementDrawerContentOnView from '../components/OrgsManagementDrawerContent/OrgsManagementDrawerContentOnView';

export enum OrgsDrawerTypes {
  Edit = 'edit',
  Add = 'add',
  View = 'view',
}

interface Drawer {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  drawerType: OrgsDrawerTypes;
  setdrawerType: (drawerType: OrgsDrawerTypes) => void;
  drawerHeader: ReactNode;
  setdrawerHeader: (drawerHeader: JSX.Element) => void;
  selectedOrganization: OrganizationWithUsersFragment | undefined;
  setSelectedOrganization: (
    selectedOrganization: OrganizationWithUsersFragment | undefined,
  ) => void;
}

interface OrgsManagementDrawerContextProps {
  children: React.ReactNode;
}

const emptyObject = {};

const OrgsManagementDrawerContext = createContext<Drawer>(
  emptyObject as Drawer,
);

function OrgsManagementDrawerContextProvider(
  props: OrgsManagementDrawerContextProps,
) {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [drawerType, setdrawerType] = useState(OrgsDrawerTypes.Edit);
  const [drawerHeader, setdrawerHeader] = useState(<p>Editar unidade</p>);
  const [selectedOrganization, setSelectedOrganization] =
    useState<OrganizationWithUsersFragment>();

  const value = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      drawerType,
      setdrawerType,
      drawerHeader,
      setdrawerHeader,
      selectedOrganization,
      setSelectedOrganization,
    }),
    [
      isOpen,
      setIsOpen,
      drawerType,
      setdrawerType,
      drawerHeader,
      setdrawerHeader,
      selectedOrganization,
      setSelectedOrganization,
    ],
  );
  return (
    <OrgsManagementDrawerContext.Provider value={value}>
      <CustomDrawer
        closeDrawer={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        header={drawerHeader}
        content={
          drawerType === 'edit' ? (
            <OrgsManagementDrawerContentOnEdit />
          ) : drawerType === 'add' ? (
            <OrgsManagementDrawerContentOnAdd />
          ) : (
            <OrgsManagementDrawerContentOnView />
          )
        }
      />
      {children}
    </OrgsManagementDrawerContext.Provider>
  );
}

export function useOrgsManagementDrawerContext() {
  const context = useContext(OrgsManagementDrawerContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useOrgsManagementDrawerContext must be used within a OrgsManagementDrawerContextProvider',
    );
  }
  return context;
}

export default OrgsManagementDrawerContextProvider;
