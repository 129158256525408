import React from 'react';
import UsersTableContextProvider from './contexts/UsersTableContext';
import UsersCRUDContextProvider from './contexts/UsersCRUDContext';
import UsersPageContent from './UsersPageContent';
import UsersDrawerContextProvider from './contexts/UsersDrawerContext';

const UsersPage = () => {
  return (
    <UsersCRUDContextProvider>
      <UsersDrawerContextProvider>
        <UsersTableContextProvider>
          <UsersPageContent />
        </UsersTableContextProvider>
      </UsersDrawerContextProvider>
    </UsersCRUDContextProvider>
  );
};

export default UsersPage;
