import { createContext, useContext, useMemo } from 'react';
import {
  UpdateVehicleInput,
  useUpdateVehicleMutation,
} from '../../../generated/graphqlV2';

interface CRUD {
  editVehicle: (payload: UpdateVehicleInput) => Promise<void>;
  isSubmitting: boolean;
}

interface VehiclesCRUDContextProps {
  children: React.ReactNode;
}

const emptyObject = {};

const VehiclesCRUDContext = createContext<CRUD>(emptyObject as CRUD);

function VehiclesCRUDContextProvider(props: VehiclesCRUDContextProps) {
  const { children } = props;
  const [updateVehicle, updateVehicleResult] = useUpdateVehicleMutation();
  const isSubmitting = updateVehicleResult.loading;

  const editVehicle = async (payload: UpdateVehicleInput) => {
    await updateVehicle({
      variables: { payload },
      refetchQueries: ['vehicles'],
    });
  };

  const value = useMemo(
    () => ({
      editVehicle,
      isSubmitting,
    }),
    [editVehicle, isSubmitting],
  );
  return (
    <VehiclesCRUDContext.Provider value={value}>
      {children}
    </VehiclesCRUDContext.Provider>
  );
}

export function useVehiclesCRUDContext() {
  const context = useContext(VehiclesCRUDContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useVehiclesCRUDContext must be used within a VehiclesCRUDContextProvider',
    );
  }
  return context;
}

export default VehiclesCRUDContextProvider;
