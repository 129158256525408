/* eslint-disable react/prop-types */
import { Controller, useForm } from 'react-hook-form';
import { useCurrentUserContext } from '../../../../contexts/CurrentUserContextProvider';
import { useVehiclesDrawerContext } from '../../contexts/VehiclesDrawerContext';
import {
  Footer,
  Form,
  Inputs,
  PurpleButton,
  Row,
} from './VehiclesDrawerContent.style';
import { useVehiclesCRUDContext } from '../../contexts/VehiclesCRUDContext';
import { VehicleUsefulInfo } from '../../contexts/VehiclesTableContext';
import { FuelTypeEnum } from '../../../../generated/graphqlV2';
import Input from '../../../../components/Input/Input';
import TextArea from '../../../../components/TextArea/TextArea';
import { formatFuelEnum } from '../../../../helpers/format-fuel-enum';
import SelectFuelType from '../../../../components/SelectFuelType/SelectFuelType';
import SelectOrganization from '../../../../components/SelectOrganization/SelectOrganization';
import { useToast } from '../../../../hooks/useToast';

interface VehiclesDrawerContentProps {
  selectedVehicle: VehicleUsefulInfo | undefined;
  isEdit?: boolean;
}

interface FormValues {
  plate: string;
  displayName?: string;
  brand?: string;
  model?: string;
  year?: string;
  fuelType?: {
    id: FuelTypeEnum | null;
    text: string;
  };
  document?: string;
  price?: number;
  observation?: string;
  organizationId: {
    id: string;
    text: string;
  };
}

const VehiclesDrawerContent = (props: VehiclesDrawerContentProps) => {
  const { selectedVehicle } = props;
  const { addToast } = useToast();
  const { user } = useCurrentUserContext();
  const { editVehicle, isSubmitting } = useVehiclesCRUDContext();
  const { setIsOpen } = useVehiclesDrawerContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      plate: selectedVehicle?.plate || undefined,
      displayName: selectedVehicle?.displayName || undefined,
      brand: selectedVehicle?.brand || undefined,
      model: selectedVehicle?.model || undefined,
      year: selectedVehicle?.year || undefined,
      fuelType:
        {
          id: selectedVehicle?.fuelType,
          text: formatFuelEnum(selectedVehicle?.fuelType),
        } || undefined,
      document: selectedVehicle?.document || undefined,
      price: selectedVehicle?.price || undefined,
      observation: selectedVehicle?.observation || undefined,
      organizationId: {
        id: selectedVehicle?.organization.id,
        text: selectedVehicle?.organization.name,
      },
    },
  });

  const onSubmit = async (data: FormValues) => {
    if (selectedVehicle) {
      try {
        const payload = {
          id: selectedVehicle.id,
          ...data,
          fuelType: data.fuelType?.id,
          organizationId: data.organizationId.id,
        };
        await editVehicle(payload);
        addToast({ title: 'Veículo editado com sucesso!', type: 'success' });
      } catch (error) {
        addToast({ title: 'Erro na criação do veículo.', type: 'error' });
        console.log('Erro: ', error);
      } finally {
        setIsOpen(false);
      }
    }
    return null;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Inputs>
        <Row>
          <Controller
            name="plate"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <Input
                label="Placa ou identificador"
                onChange={props.field.onChange}
                disabled
                name={props.field.name}
                value={selectedVehicle?.plate}
                type="text"
                width="265px"
                error={errors.plate?.message}
                loading={isSubmitting}
              />
            )}
          />
          <Controller
            name="displayName"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <Input
                label="Apelido"
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                type="text"
                width="265px"
                loading={isSubmitting}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="brand"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <Input
                label="Marca"
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                type="text"
                width="265px"
                loading={isSubmitting}
              />
            )}
          />
          <Controller
            name="model"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <Input
                label="Modelo"
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                type="text"
                width="265px"
                loading={isSubmitting}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="year"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <Input
                label="Ano"
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                type="text"
                width="265px"
                loading={isSubmitting}
              />
            )}
          />
          <Controller
            name="fuelType"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <SelectFuelType
                onChange={props.field.onChange}
                name={props.field.name}
                loading={isSubmitting}
                value={props.field.value}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="document"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <Input
                label="Renavam"
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                type="text"
                width="265px"
                loading={isSubmitting}
              />
            )}
          />
          <Controller
            name="price"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <Input
                label="Preço"
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value?.toString()}
                type="number"
                width="265px"
                loading={isSubmitting}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="observation"
            control={control}
            rules={{ required: false }}
            render={(props) => (
              <TextArea
                label="Observações"
                onChange={props.field.onChange}
                name={props.field.name}
                value={props.field.value}
                loading={isSubmitting}
                width="522px"
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name="organizationId"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <SelectOrganization
                user={user}
                onChange={props.field.onChange}
                name={props.field.name}
                loading={isSubmitting}
                value={props.field.value}
              />
            )}
          />
        </Row>
      </Inputs>
      <Footer>
        <PurpleButton disabled={isSubmitting}>Editar veículo</PurpleButton>
      </Footer>
    </Form>
  );
};

export default VehiclesDrawerContent;
