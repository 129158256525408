import { createContext, useContext, useMemo } from 'react';
import {
  CreateOrganizationInput,
  OrganizationFragment,
  OrganizationFragmentDoc,
  UpdateOrganizationInput,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
} from '../../../generated/graphqlV2';

interface OrganizationCRUD {
  createNewOrganization: (payload: CreateOrganizationInput) => Promise<void>;
  editOrganization: (payload: UpdateOrganizationInput) => Promise<void>;
  editOrganizationIsLoading: boolean;
  createOrganizationIsLoading: boolean;
}

interface OrganizationCRUDContextProps {
  children: React.ReactNode;
}

const emptyObject = {};

const OrganizationCRUDContext = createContext<OrganizationCRUD>(
  emptyObject as OrganizationCRUD,
);

function OrganizationCRUDContextProvider(props: OrganizationCRUDContextProps) {
  const { children } = props;

  const [createOrganization, createOrganizationResult] =
    useCreateOrganizationMutation({
      update(cache, { data }) {
        cache.modify({
          fields: {
            organizations(prev = []) {
              const newTodoRef = cache.writeFragment({
                data: data?.createOrganization,
                fragment: OrganizationFragmentDoc,
                fragmentName: data?.createOrganization.__typename,
              });
              return [newTodoRef, ...prev];
            },
          },
        });
      },
    });

  const [updateOrganization, updateOrganizationResult] =
    useUpdateOrganizationMutation({
      update(cache, { data }) {
        cache.modify({
          fields: {
            organizations(prev: OrganizationFragment[] = []) {
              const updatedItem = data?.updateOrganization;
              return prev.map((element) => {
                if (updatedItem?.id === element.id) {
                  return { ...element, ...updatedItem };
                }
                return element;
              });
            },
          },
        });
      },
    });

  const editOrganization = async (payload: UpdateOrganizationInput) => {
    await updateOrganization({ variables: { payload } });
  };

  const createNewOrganization = async (payload: CreateOrganizationInput) => {
    await createOrganization({ variables: { payload } });
  };

  const editOrganizationIsLoading = updateOrganizationResult.loading;
  const createOrganizationIsLoading = createOrganizationResult.loading;

  const value = useMemo(
    () => ({
      editOrganization,
      createNewOrganization,
      editOrganizationIsLoading,
      createOrganizationIsLoading,
    }),
    [
      editOrganization,
      createNewOrganization,
      editOrganizationIsLoading,
      createOrganizationIsLoading,
    ],
  );
  return (
    <OrganizationCRUDContext.Provider value={value}>
      {children}
    </OrganizationCRUDContext.Provider>
  );
}

export function useOrganizationCRUDContext() {
  const context = useContext(OrganizationCRUDContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useOrganizationCRUDContext must be used within a OrganizationCRUDContextProvider',
    );
  }
  return context;
}

export default OrganizationCRUDContextProvider;
