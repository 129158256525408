import { UserFragment } from '../../../generated/graphqlV2';
import { getRoleLabelFromType } from '../../../helpers/get-role-label-from-type';
import {
  SortingConfiguration,
  SortingType,
} from '../contexts/UsersTableContext';

export const sortUsersTable = (
  tableData: UserFragment[] | undefined,
  sortConfig: SortingConfiguration,
) => {
  if (tableData === undefined) return undefined;
  switch (sortConfig.propertyName) {
    case 'name':
      if (sortConfig.sortType === SortingType.Descending)
        return tableData.sort((a, b) =>
          (a.name ? a.name : '') > (b.name ? b.name : '') ? 1 : -1,
        );
      return tableData.sort((a, b) =>
        (a.name ? a.name : '') > (b.name ? b.name : '') ? -1 : 1,
      );
    case 'email':
      if (sortConfig.sortType === SortingType.Descending)
        return tableData.sort((a, b) =>
          (a.email ? a.email : '') > (b.email ? b.email : '') ? 1 : -1,
        );
      return tableData.sort((a, b) =>
        (a.email ? a.email : '') > (b.email ? b.email : '') ? -1 : 1,
      );
    case 'role':
      if (sortConfig.sortType === SortingType.Descending)
        return tableData.sort((a, b) =>
          getRoleLabelFromType(a.role) > getRoleLabelFromType(b.role) ? 1 : -1,
        );
      return tableData.sort((a, b) =>
        getRoleLabelFromType(a.role) > getRoleLabelFromType(b.role) ? -1 : 1,
      );
    case 'cpf':
      if (sortConfig.sortType === SortingType.Descending)
        return tableData.sort((a, b) =>
          (a.cpf ? a.cpf : '') > (b.cpf ? b.cpf : '') ? 1 : -1,
        );
      return tableData.sort((a, b) =>
        (a.cpf ? a.cpf : '') > (b.cpf ? b.cpf : '') ? -1 : 1,
      );
    default:
      return tableData;
  }
};
