const theme = {
  colors: {
    darkBlue: '#101729',
    darkestGray: '#1D2939',
    backgroundColor: '#FBFBFB',
    white: '#FFFFFF',
    headerBlack: '#212121',
    buttonBackgroundPurple: '#F2EFFA',
    buttonTextPurple: '#7758C7',
    darkGray: '#667085',
    middleGray: '#898989',
    lightGray: '#D0D5DD',
    textGray: '#98A2B3',
    sidebarHover: '#354054',
    sidebarDivider: '#475467',
    black: '#434343',
    lightYellow: '#FFF1BB',
    lightPurple: '#D5BBFF',
    darkYellow: '#CA9F00',
    darkPurple: '#4D00CA',
    tableGrayRow: '#F1F3F7',
    tableBorder: '#F0F0F0',
    infleetBlue: '#0E2D66',
    red: '#D83D0E',
    darkBlueTransparent: '#10172939',
  },
  sizes: {
    openSidebarWidth: '250px',
    closedSidebarWidth: '70px',
  },
};

export default theme;
