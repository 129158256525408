import styled from 'styled-components';
import theme from '../../theme';

export const TableWrapper = styled.table`
  border-collapse: collapse;
  font-size: 12px;
  min-width: 100%;
  position: relative;
`;

export const TableBody = styled.tbody`
  border: 1px solid ${theme.colors.tableBorder};
`;

export const TableHeader = styled.th`
  font-weight: normal;
  border: 1px solid ${theme.colors.tableBorder};
  position: sticky;
  width: fit-content;
  max-width: 300px;
  padding: 0px 8px;
`;

export const TableRow = styled.tr`
  background-color: ${theme.colors.white};
  text-align: left;
  &:nth-of-type(even) {
    background-color: ${theme.colors.tableGrayRow};
  }
  color: ${theme.colors.black};
`;

export const TableData = styled.td`
  padding: 26px 0px 26px 8px;
  border: 1px solid ${theme.colors.tableBorder};
  width: fit-content;
  max-width: 300px;
  &.action {
    position: relative;
  }
`;

export const HeaderData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 18px 0;
  &.sortable {
    cursor: pointer;
  }
`;
