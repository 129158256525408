import {
  SortingConfiguration,
  SortingType,
  VehicleUsefulInfo,
} from '../contexts/VehiclesTableContext';

export const sortVehiclesTable = (
  tableData: VehicleUsefulInfo[] | undefined,
  sortConfig: SortingConfiguration,
) => {
  if (tableData === undefined) return undefined;
  switch (sortConfig.propertyName) {
    case 'plate':
      if (sortConfig.sortType === SortingType.Descending)
        return tableData.sort((a, b) => (a.plate > b.plate ? 1 : -1));
      return tableData.sort((a, b) => (a.plate > b.plate ? -1 : 1));
    case 'model':
      if (sortConfig.sortType === SortingType.Descending)
        return tableData.sort((a, b) =>
          (a.model || '') > (b.model || '') ? 1 : -1,
        );
      return tableData.sort((a, b) =>
        (a.model || '') > (b.model || '') ? -1 : 1,
      );
    case 'odometer':
      if (sortConfig.sortType === SortingType.Descending)
        return tableData.sort((a, b) =>
          a.measure.value > b.measure.value ? 1 : -1,
        );
      return tableData.sort((a, b) =>
        a.measure.value > b.measure.value ? -1 : 1,
      );
    case 'driver':
      if (sortConfig.sortType === SortingType.Descending)
        return tableData.sort((a, b) =>
          (a.driver || '') > (b.driver || '') ? 1 : -1,
        );
      return tableData.sort((a, b) =>
        (a.driver || '') > (b.driver || '') ? -1 : 1,
      );
    case 'brand':
      if (sortConfig.sortType === SortingType.Descending)
        return tableData.sort((a, b) =>
          (a.brand || '') > (b.brand || '') ? 1 : -1,
        );
      return tableData.sort((a, b) =>
        (a.brand || '') > (b.brand || '') ? -1 : 1,
      );
    default:
      return tableData;
  }
};
