import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  useVehiclesQuery,
  VehicleWithDriverFragment,
} from '../../../generated/graphqlV2';
import { sortVehiclesTable } from '../helpers/sort-vehicles-table';
import getUsefulVehicleData from '../helpers/get-useful-vehicle-data';

interface VehiclesTable {
  sortConfig: SortingConfiguration;
  setSortConfig: (sortConfig: SortingConfiguration) => void;
  query: string;
  setQuery: (query: string) => void;
  tableData: VehicleUsefulInfo[] | undefined;
  setTableData: (tableData: VehicleUsefulInfo[] | undefined) => void;
  sortBy: (string: string) => void;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  numberOfItems: number | undefined;
  maxItensRendered: number;
  setMaxItensRendered: (maxItensRendered: number) => void;
  loading: boolean;
}

export interface VehicleUsefulInfo extends VehicleWithDriverFragment {
  measure: {
    value: number;
    type: string;
  };
}

interface VehiclesTableContextProps {
  children: React.ReactNode;
}

export interface SortingConfiguration {
  propertyName: string | undefined;
  sortType: SortingType | undefined;
}

export enum SortingType {
  Ascending,
  Descending,
}

const emptyObject = {};

const VehiclesTableContext = createContext<VehiclesTable>(
  emptyObject as VehiclesTable,
);

function VehiclesTableContextProvider(props: VehiclesTableContextProps) {
  const { children } = props;
  const [sortConfig, setSortConfig] = useState<SortingConfiguration>({
    propertyName: undefined,
    sortType: undefined,
  });
  const [query, setQuery] = useState('');
  const [tableData, setTableData] = useState<VehicleUsefulInfo[] | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [maxItensRendered, setMaxItensRendered] = useState(6);
  const numberOfItems = tableData?.length;
  const { data, loading } = useVehiclesQuery({
    fetchPolicy: 'cache-and-network',
  });

  useMemo(() => {
    const usefulInfo = getUsefulVehicleData(data);
    const filteredData = usefulInfo?.filter((item) =>
      item.plate.toLowerCase().includes(query),
    );
    const sortedFilteredData = sortVehiclesTable(filteredData, sortConfig);
    setTableData(sortedFilteredData);
  }, [data, query, sortConfig, loading]);

  const sortBy = useCallback(
    (propertyName: string | undefined) => {
      if (sortConfig.propertyName === propertyName) {
        if (sortConfig.sortType === SortingType.Descending) {
          setSortConfig({ propertyName, sortType: SortingType.Ascending });
        } else {
          setSortConfig({ propertyName: undefined, sortType: undefined });
        }
      } else {
        setSortConfig({ propertyName, sortType: SortingType.Descending });
      }
    },
    [sortConfig],
  );

  const value = useMemo(
    () => ({
      sortConfig,
      setSortConfig,
      query,
      setQuery,
      tableData,
      setTableData,
      sortBy,
      currentPage,
      setCurrentPage,
      numberOfItems,
      maxItensRendered,
      setMaxItensRendered,
      loading,
    }),
    [
      currentPage,
      maxItensRendered,
      numberOfItems,
      query,
      sortBy,
      sortConfig,
      tableData,
    ],
  );
  return (
    <VehiclesTableContext.Provider value={value}>
      {children}
    </VehiclesTableContext.Provider>
  );
}

export function useVehiclesTableContext() {
  const context = useContext(VehiclesTableContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useVehiclesTableContext must be used within a VehiclesTableContextProvider',
    );
  }
  return context;
}

export default VehiclesTableContextProvider;
